import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";

import { Card, Row, Col, Button, Modal, OverlayTrigger } from "react-bootstrap";
import EditBookPageForm from "./EditBookPageForm";
import CreateBookPageForm from "./CreateBookPageForm";
import config from "../../config.json";
import HMRGrid from "../childcomponents/Grid/HMRGrid";

const BookPage = () => {
  const [rowData, setRowData] = useState({ data: [] });
  const [Books, setBooks] = useState({ options: [] });
  const [bookItem, setBookItem] = useState({ data: {} });
  const [bookList, setBookList] = useState({ data: [] });
  const [selectedBookPage, setSelectedBookPage] = useState({ data: [] });
  const [isUpdated, setIsUpdated] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const token = localStorage.getItem("Token");
  const ServerPath = config.rapidServerPath;

  // const [selectedBook, setSelectedBook] = useState({
  //   label: "Select Book",
  //   value: "",
  // });

  // useEffect(() => {
  //   async function fetchData() {
  //     const response = await axios.get(
  //       ServerPath + "/api/books/getAllBookPages",
  //       {
  //         headers: {
  //           Authorization: token,
  //           Apikey: "XYZ",
  //         },
  //       }
  //     );
  //     setRowData({ data: response.data });
  //     setIsUpdated(false);
  //   }
  //   fetchData();
  // }, [isUpdated]);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(ServerPath + "/api/books/getBooks", {
        headers: {
          //Authorization: token,
          Apikey: "XYZ",
        },
      });
      //console.clear();
      setBookList({ data: response.data });

      //console.log("GETBOOKS RESP IS " + JSON.stringify(response.data));
      //console.log("SET BOOK LIST IS " + JSON.stringify(bookList.data));
      const options = [];
      //options.push({ name: "SELECT BOOK", value: '0' });
      response.data.map((book) => {
        options.push({ label: book.Name, value: book.BookID });
      });
      setBooks({ options: options });
      //   setIsUpdated(false);
    }
    fetchData();
  }, [isUpdated]);

  let selBookId = 0;
  //const selected = selectedBook;
  const handleSelectChange = async (optSelected) => {
    selBookId = optSelected.value;
    //setSelectedBook(selected);
    loadPageList(selBookId);
    setBookItem(bookList.data.find((book) => book.BookID == selBookId));
  };

  const loadPageList = async (bookId) => {
    const response = await axios.get(
      ServerPath + "/api/bookpage/getBookPagesOnBookID/" + bookId,
      {
        headers: {
          Authorization: token,
          Apikey: "XYZ",
        },
      }
    );
    setRowData({ data: response.data });
  };

  const gridOptions = {
    columnDefs: [
      {
        field: "Change field",
        cellRendererFramework: function (params) {
          return (
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleUpdateOpen(params.data)}
            >
              Edit
            </Button>
          );
        },
        width: 100,
        resizable: true,
      },
      {
        field: "BookPageID",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "BookID",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "PageName",
        width: 200,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "PageNumber",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "BookText",
        width: 200,
        sortable: true,
        filter: true,
        resizable: true,
      },
      ,
      {
        field: "PageImage",
        cellRendererFramework: function (params) {
          return (
            <OverlayTrigger
              trigger={["hover", "focus"]}
              key="left"
              placement="left"
              overlay={
                <Card className="text-center">
                  <Card.Body>
                    <img
                      src={
                        ServerPath +
                        "/api/file/assets/book-photos/" +
                        params.data.PageImage
                      }
                      style={{
                        height: 270,
                        width: 480,
                        border: "1px",
                        borderColor: "GRAY",
                      }}
                    />
                  </Card.Body>
                </Card>
              }
            >
              <div>{params.data.PageImage}</div>
            </OverlayTrigger>
          );
        },
        width: 350,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "isInteractive",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
    ],
    pagination: true,
    paginationPageSize: 10,
  };

  const handleUpdateClose = () => {
    setShowUpdate(false);
    // loadPageList(selBookId);
  };
  const handleUpdateOpen = (data) => {
    setSelectedBookPage({ data: data });
    setShowUpdate(true);
  };
  const handleCreateOpen = () => {
    //selBookId > 0
    //?
    setShowCreate(true);
    // : NotificationManager.error(
    //     "Select Book",
    //     "Please select a book first",
    //     3000
    //   );
  };

  const handleCreateClose = async () => {
    setShowCreate(false);
    // const response = await axios.get(
    //   ServerPath + "/api/bookpage/getBookPagesOnBookID/" + selBookId,
    //   {
    //     headers: {
    //       Authorization: token,
    //       Apikey: "XYZ",
    //     },
    //   }
    // );
    // setRowData({ data: response.data });
  };

  return (
    <Row>
      <Col md={12} lg={12} style={{ marginBottom: 20 }}>
        <Card style={{ height: "auto", width: "auto" }}>
          <div style={{ display: "flex" }}>
            <h5 className="heading">Book Page Details</h5>

            <div
              style={{
                width: "200px",
                marginLeft: "auto",
                marginRight: "3%",
                marginTop: 4,
              }}
            >
              <Select
                isSearchable={false}
                options={Books.options}
                defaultValue="0"
                //placeholder="Select Book"

                onChange={handleSelectChange}
                components={{
                  IndicatorSeparator: () => null,
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: ".25rem",
                  colors: {
                    ...theme.colors,
                    primary25: "#adb5bd",
                    primary: "#007bff",
                  },
                })}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>
            {/* {selBookId == 0 ? null : ( 
              <div
                
              >*/}
            <Button
              variant="primary"
              size="sm"
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
              onClick={handleCreateOpen}
            >
              Create Book Page
            </Button>
            {/*  </div>
             )} */}
            <Modal
              show={showCreate}
              onHide={handleCreateClose}
              backdrop="static"
              keyboard={false}
              supportedorientations={["portrait", "landscape"]}
            >
              <Modal.Header closeButton>
                <Modal.Title>Create Book Page(s)</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CreateBookPageForm
                  BookPage={selectedBookPage.data}
                  BookData={bookItem}
                  selectedBook={bookItem.BookID}
                  sendUpdate={setIsUpdated}
                  closeModel={handleCreateClose}
                  setBook={bookItem}
                  BookPages={rowData.data}
                />
              </Modal.Body>
            </Modal>
            <Modal
              show={showUpdate}
              onHide={handleUpdateClose}
              backdrop="static"
              keyboard={false}
              supportedorientations={["portrait", "landscape"]}
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Page Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <EditBookPageForm
                  BookPage={selectedBookPage.data}
                  BookData={bookItem}
                  selectedBook={bookItem.BookID}
                  sendUpdate={setIsUpdated}
                  closeModel={handleUpdateClose}
                  setBook={bookItem}
                />
              </Modal.Body>
            </Modal>
          </div>
          {/* <Dropzone
            Book={bookData  }
            Resolution="1920X1080"
          /> */}
        </Card>
        <HMRGrid rowData={rowData.data} gridOptions={gridOptions} />
      </Col>
    </Row>
  );
};

export default BookPage;
