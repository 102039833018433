import React from "react";
import classNames from "classnames";
import { Container } from "reactstrap";
import { Switch, Route } from "react-router-dom";
// import Footbar from "../childcomponents/HelperComponents/Footbar";
import EcomerseDashboard from "../childcomponents/EcomerseDashboard/EcomerseDashboard";
import Faq from "../childcomponents/FAQ/FaqApp";
import Topbar from "./Topbar";
import Signup from "../childcomponents/Authorization/Signup";
import Login from "../childcomponents/Authorization/Login";
import UserProfile from "../childcomponents/UserProfile/UserProfile";
import Tabs from "../childcomponents/Layout/Tabs/Tabs";
import Lists from "../childcomponents/Layout/Lists/Lists";
import Accordian from "../childcomponents/Layout/Accordian/Accordian";
import Stepper from "../childcomponents/Layout/Stepper/Stepper";
import IOT_Dashboard from "../childcomponents/IOT_Dashboard/IOT_Dashboard";
import Calendar from "../childcomponents/ExtraComponents/Calendar/Calendar";
import ProgressBar from "../childcomponents/ExtraComponents/ProgressBars/ProgressBar";
import Spinners from "../childcomponents/ExtraComponents/Spinners/Spinner";
import Alerts from "../childcomponents/ExtraComponents/Alerts/Alert";
import Notifications from "../childcomponents/Modal&Overlay/notifications/Notifications";
import Window from "../childcomponents/Modal&Overlay/Modals/Modal";
import Typography from "../childcomponents/UI/Typography";
import Popper from "../childcomponents/Modal&Overlay/Popover/Poper";
import LeafletMap from "../childcomponents/Maps/LeafletMap";
import Echarts from "../childcomponents/Echarts/Echarts";
import D3Charts from "../childcomponents/D3Charts/D3Charts";
import ApexChart from "../childcomponents/ApexChart/apexchart";
import FormInput from "../childcomponents/HelperComponents/FormInput/forminput";
import FormLayout from "../childcomponents/HelperComponents/FormLayout/Formlayout";
import GoogleApiWrapper from "../childcomponents/Maps/GoogleMaps";
import FormDates from "../childcomponents/FormDates/FormDates";
import FormButtons from "../childcomponents/FormButtons/FormButtons";
import HM_Users from "../HM_Users/HM_Users";
import HM_Books from "../HM_Books/HM_Books";
import HM_BookPage from "../HM_Books/HM_BookPage";
import HM_PageContent from "../HM_Books/HM_PageContent";
import HM_ConsumerHomePage from "../HM_Consumer/HM_Consumer";
import HM_ColorPages from "../HM_Consumer/HM_ColorPages";
import HM_BookPageImages from "../HM_Consumer/HM_BookPagesImages";
import HM_Animations from "../HM_Books/HM_Animations";
import Animation_Parameters from "../HM_Books/HM_Animation_Parameters";
// import Animations from "../HM_Books/Animation";

const Content = ({ sidebarIsOpen, toggleSidebar, handleClick }) => (
  <>
    <Container
      fluid
      className={classNames("content", { "is-open": sidebarIsOpen })}
    >
      <Topbar toggleSidebar={toggleSidebar} handleClick={handleClick} />
      <Switch>
        <Route exact path="/ngx/dashboardeco" component={EcomerseDashboard} />
        <Route exact path="/ngx/dashboardiot" component={IOT_Dashboard} />
        <Route exact path="/ngx/userprofile" component={UserProfile} />
        <Route exact path="/ngx/HM_Users" component={HM_Users} />
        <Route exact path="/ngx/HM_Books" component={HM_Books} />
        <Route exact path="/ngx/HM_BookPage" component={HM_BookPage} />
        <Route exact path="/ngx/HM_PageContent" component={HM_PageContent} />
        <Route exact path="/ngx/Home" component={HM_ConsumerHomePage} />
        <Route exact path="/ngx/HM_ColorPages" component={HM_ColorPages} />
        <Route exact path="/ngx/HM_Animations" component={HM_Animations} />
        <Route
          exact
          path="/ngx/Animation_Parameters"
          component={Animation_Parameters}
        />
        <Route
          exact
          path="/ngx/HM_BookPageImages"
          component={HM_BookPageImages}
        />
        <Route exact path="/ngx/faq" component={Faq} />
        <Route exact path="/ngx/Register" component={Signup} />
        <Route exact path="/ngx/Login" component={Login} />
        <Route exact path="/ngx/Tabs" component={Tabs} />
        <Route exact path="/ngx/Lists" component={Lists} />
        <Route exact path="/ngx/Accordian" component={Accordian} />
        <Route exact path="/ngx/Stepper" component={Stepper} />
        <Route exact path="/ngx/Calendar" component={Calendar} />
        <Route exact path="/ngx/Progress-bar" component={ProgressBar} />
        <Route exact path="/ngx/Spinners" component={Spinners} />
        <Route exact path="/ngx/alerts" component={Alerts} />
        <Route exact path="/ngx/Something1" component={() => "something1"} />
        <Route exact path="/ngx/notification" component={Notifications} />
        <Route exact path="/ngx/modal" component={Window} />
        <Route exact path="/ngx/typo" component={Typography} />
        <Route exact path="/ngx/pop" component={Popper} />
        <Route exact path="/ngx/leaflet" component={LeafletMap} />
        <Route exact path="/ngx/googlemaps" component={GoogleApiWrapper} />
        <Route exact path="/ngx/echarts" component={Echarts} />
        <Route exact path="/ngx/d3charts" component={D3Charts} />
        <Route exact path="/ngx/apexchart" component={ApexChart} />
        <Route exact path="/ngx/Form-input" component={FormInput} />
        <Route exact path="/ngx/Form-layout" component={FormLayout} />
        <Route exact path="/ngx/formdates" component={FormDates} />
        <Route exact path="/ngx/formbuttons" component={FormButtons} />
        <Route exact path="/ngx/page-2" component={() => "page-2"} />
        <Route exact path="/ngx/page-3" component={() => "page-3"} />
        <Route exact path="/ngx/page-4" component={() => "page-4"} />
      </Switch>
      {/* <Footbar /> */}
    </Container>
  </>
);

export default Content;
