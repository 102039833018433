import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Select from "react-select";
import SpLine from "../ApexChart/SpLineChart";
import GroupedBarChart from "../ApexChart/GroupedBarChart";
import "../../../ngx/ngx.scss";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography component={"div"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabPadding: {
    padding: "0px",
  },
}));

export default function SimpleTab() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [selectedOption, setSelectedOption] = useState({
    value: 1,
    label: "Week",
  });
  const [selectedOptionProfit, setselectedOptionProfit] = useState({
    value: 1,
    label: "Week",
  });

  const options = [
    { value: 1, label: "Week" },
    { value: 2, label: "Month" },
    { value: 3, label: "Year" },
  ];

  const selectedProfits = selectedOptionProfit;
  const handleSelectChangeProfits = (selectedProfits) => {
    setselectedOptionProfit(selectedProfits);
  };

  const selected = selectedOption;
  const handleSelectChangeOrders = (selected) => {
    setSelectedOption(selected);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="Ecom-order-profit-inside">
      <AppBar position="static" className="Ecom-order-profit">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="ORDERS" {...a11yProps(0)} />
          <Tab label="PROFITS" {...a11yProps(1)} />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div
          style={{
            width: "100px",
            marginLeft: "auto",
            marginRight: "3%",
            marginTop: 8,
          }}
        >
          <Select
            // className="select"
            isSearchable={false}
            options={options}
            value={selectedOption}
            autosize={true}
            onChange={handleSelectChangeOrders}
            components={{
              IndicatorSeparator: () => null,
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: ".25rem",
              colors: {
                ...theme.colors,
                primary25: "#adb5bd",
                primary: "#007bff",
              },
            })}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
        <div>
          {selected.value === 1 ? (
            <SpLine value={selected.value} />
          ) : selected.value === 2 ? (
            <SpLine value={selected.value} />
          ) : (
            <SpLine value={selected.value} />
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabPadding}>
        <div
          style={{
            width: "100px",
            marginLeft: "auto",
            marginRight: "3%",
            marginTop: 8,
          }}
        >
          <Select
            isSearchable={false}
            options={options}
            value={selectedOptionProfit}
            autosize={true}
            onChange={handleSelectChangeProfits}
            components={{
              IndicatorSeparator: () => null,
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: ".25rem",
              colors: {
                ...theme.colors,
                primary25: "#adb5bd",
                primary: "#007bff",
              },
            })}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
        {selectedProfits.value === 1 ? (
          <GroupedBarChart value={selectedProfits.value} />
        ) : selectedProfits.value === 2 ? (
          <GroupedBarChart value={selectedProfits.value} />
        ) : (
          <GroupedBarChart value={selectedProfits.value} />
        )}
      </TabPanel>
    </div>
  );
}
