export const data = [
  {
    id: "hack",
    label: "hack",
    value: 293,
    color: "hsl(262, 70%, 50%)",
  },
  {
    id: "elixir",
    label: "elixir",
    value: 167,
    color: "hsl(222, 70%, 50%)",
  },
  {
    id: "erlang",
    label: "erlang",
    value: 15,
    color: "hsl(347, 70%, 50%)",
  },
  {
    id: "java",
    label: "java",
    value: 53,
    color: "hsl(177, 70%, 50%)",
  },
  {
    id: "sass",
    label: "sass",
    value: 524,
    color: "hsl(325, 70%, 50%)",
  },
];
