import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import config from "../../config.json";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import "./BookForm.css";
import "../../global.css";

const EditAnimationForm = (props) => {
  const AnimationID = props.Animation.AnimationID;
  const AnimParamID = props.Animation.AnimParamID;

  //  debugger;
  const [animationType, setAnimationType] = useState(
    props.Animation.AnimationType
  );
  const [paramName, setparamName] = useState(props.Animation.ParamName);
  const [paramValue, setparamValue] = useState(props.Animation.ParamValue);
  const [imageName, setimageName] = useState(props.Animation.ImageName);
  const [isAudio, setIsAudio] = useState(Boolean(props.Animation.isAudio));
  const token = localStorage.getItem("Token");
  const ServerPath = config.rapidServerPath;

  const createNotificationHandler = (type) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "Details changed successfully",
          "Success",
          3000
        );
        break;
      case "error":
        NotificationManager.error("Something went wrong", "error", 3000);
        break;
    }
  };

  async function updateAnimation(e) {
    e.preventDefault();
    let AnimParamId = props.Animation.AnimParamID;
    let AnimId = props.Animation.AnimationID;
    handleUpdate(AnimParamId, AnimId);
  }

  const handleUpdate = async (AnimParamId, AnimId) => {
    console.log(AnimId);
    const response = await axios.post(
      ServerPath +
        "/api/pagecontent/updatePageAnimParams/" +
        AnimId +
        "/" +
        AnimParamId,
      {
        AnimationID: AnimationID,
        AnimParamID: AnimParamID,
        AnimationType: animationType,
        ParamName: paramName,
        ParamValue: paramValue,
        ImageName: imageName,
        isAudio: Number(isAudio),
      },
      {
        headers: {
          Authorization: token,
          Apikey: "XYZ",
        },
      }
    );
    //  props.setBook({ label: "Select Book", value: "" });
    props.sendUpdate(true);
    if (response.data.success === true) {
      createNotificationHandler("success");
    } else {
      createNotificationHandler("error");
    }
    props.closeModel();
  };

  return (
    <div className="animation-container">
      <div className="content">
        <form onSubmit={(e) => updateAnimation(e)}>
          <div className="input-group">
            <input
              placeholder="Animation Type"
              type="text"
              id="animtype"
              value={animationType}
              onChange={(e) => setAnimationType(e.target.value)}
            />
            <label htmlFor="animtype">Animation Type </label>
          </div>
          <div className="input-group">
            <input
              placeholder="Param Name"
              type="text"
              id="paramname"
              value={paramName}
              onChange={(e) => setparamName(e.target.value)}
            />
            <label htmlFor="paramname">Param Name </label>
          </div>
          <div className="input-group">
            <input
              placeholder="Param Value"
              type="text"
              id="paramValue"
              value={paramValue}
              onChange={(e) => setparamValue(e.target.value)}
            />
            <label htmlFor="paramValue">Param Value </label>
          </div>
          <div className="input-group">
            <input
              placeholder="Image Name"
              type="text"
              id="imageName"
              value={imageName}
              onChange={(e) => setimageName(e.target.value)}
            />
            <label htmlFor="imageName">Image Name </label>
          </div>
          <i style={{ display: "block" }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={isAudio}
                    onChange={(e) => setIsAudio(!Boolean(isAudio))}
                  />
                }
                label="isAudio?"
                labelPlacement="start"
              />
            </FormGroup>
          </i>
          <button className="button" type="submit">
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditAnimationForm;
