import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
// import FormControl from "@material-ui/core/FormControl";
import config from "../../config.json";
import "./BookForm.css";

const EditBookPageForm = (props) => {
  const BookPageID = props.BookPage.BookPageID;
  //const BookPage = props.BookPage;
  debugger;
  console.log("BOOOOOKS DATA" + JSON.stringify(props.BookData));
  console.log("BOOOOOKS PAGE" + JSON.stringify(props.BookPage));

  const [ImagePath] = useState(props.BookData.ImagePath);
  const [BookName] = useState(props.BookData.Name);
  const [PageCount] = useState(props.BookData.PageCount);
  const [PageName, setPageNm] = useState(props.BookPage.PageName);
  const [PageNumber, setPageNum] = useState(props.BookPage.PageNumber);
  const [BookText, setBookText] = useState(props.BookPage.BookText);
  const [PageImage, setPageImage] = useState(props.BookPage.PageImage);

  const [BookID, setBookID] = useState(props.BookPage.BookID);
  const [isInteractive, setIsInteractive] = useState(
    Boolean(props.BookPage.isInteractive)
  );
  const [image, setImage] = useState({ preview: "", raw: "" });
  const token = localStorage.getItem("Token");
  const ServerPath = config.rapidServerPath;

  const createNotificationHandler = (type) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "Details changed successfully",
          "Success",
          3000
        );
        break;
      case "error":
        NotificationManager.error("Something went wrong", "error", 3000);
        break;
    }
  };

  async function updateBookPage(e) {
    e.preventDefault();
    //let PageImage = PageImage;
    if (image.raw) {
      const form = new FormData();
      if (image.raw) {
        const form = new FormData();
        form.append("file", image.raw);
        form.append("uid", ImagePath);
        form.append("BookName", BookName);
        form.append("resolution", PageImage.split("/")[2]);
        form.append("folderName", "book-photos");
        const response = await axios.post(
          ServerPath + "/api/file/BulkUpload",
          form
        );
      }
    }
    savePageData();
  }

  const savePageData = async () => {
    const response = await axios.put(
      ServerPath + "/api/bookpage/updateBookPage/" + BookPageID,
      {
        //BookID: BookID,
        PageName: PageName,
        PageNumber: PageNumber,
        BookText: BookText,
        PageImage: PageImage,
        isInteractive: Number(isInteractive),
        PageImage: PageImage,
      },
      {
        headers: {
          Authorization: token,
          Apikey: "XYZ",
        },
      }
    );
    //props.setBook({ label: "Select Book", value: "" });
    props.sendUpdate(true);
    if (response.data.success === true) {
      createNotificationHandler("success");
    } else {
      createNotificationHandler("error");
    }
    props.closeModel();
  };

  const setBookPageImage = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  return (
    <div className="book-container">
      <div className="content">
        <form onSubmit={(e) => updateBookPage(e)}>
          <div className="input-group">
            <input
              id="bookname"
              placeholder="Book Name"
              value={BookName}
              readOnly
            />
            <label htmlFor="bookname">Book Name</label>
          </div>
          <div className="input-group">
            <input
              placeholder="Page Count"
              id="pagecount"
              value={PageCount}
              readOnly
            />
            <label htmlFor="pagecount">Page Count</label>
          </div>
          <div className="input-group">
            <input
              id="pagename"
              placeholder="Page Name"
              value={PageName}
              onChange={(e) => setPageNm(e.target.value)}
            />
            <label htmlFor="pagename">Page Name</label>
          </div>
          <div className="input-group">
            <input
              id="pagenumber"
              placeholder="Page Number"
              value={PageNumber}
              onChange={(e) => setPageNum(e.target.value)}
            />
            <label htmlFor="pagenumber">Page Number</label>
          </div>
          <div className="input-group">
            <input
              id="booktext"
              placeholder="Book Text"
              value={BookText}
              onChange={(e) => setBookText(e.target.value)}
            />
            <label htmlFor="booktext">Book Text</label>
          </div>
          <div className="input-group">
            <input
              placeholder="Page Image"
              value={PageImage}
              onChange={(e) => setPageImage(e.target.value)}
            />
            <label htmlFor="pageimage">Page Image</label>
          </div>
          <label htmlFor="upload-book-image">
            {image.preview ? (
              <img src={image.preview} className="form-image" />
            ) : (
              <img
                src={
                  ServerPath +
                  "/api/file/assets/book-photos/" +
                  props.BookPage.PageImage
                }
                className="form-image"
              />
            )}
          </label>
          <input
            id="upload-book-image"
            style={{ display: "none" }}
            type="file"
            onChange={(e) => setBookPageImage(e)}
          />
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={isInteractive}
                  onChange={(e) => setIsInteractive(!Boolean(isInteractive))}
                />
              }
              label="isPublished?"
              labelPlacement="start"
            />
          </FormGroup>
          <br />
          <button className="button" type="submit">
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditBookPageForm;
