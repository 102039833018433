import React, { Component } from "react";
import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";
class BarChart extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
      
        series: [{
          data: [44, 55, 41, 64, 22]
        }, {
          data: [53, 32, 33, 52, 13]
        }],
        options: {
          chart: {
            type: 'bar',
            height: 430
          },
          plotOptions: {
            bar: {
              Vertical: true,
              dataLabels: {
                position: 'top',
              },
            }
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: '12px',
              colors: ['#fff']
            }
          },
          stroke: {
            show: true,
            width: 1,
            colors: ['#fff']
          },
          xaxis: {
            categories: [2001, 2002, 2003, 2004, 2005],
          },
        },
      
      
      };
    }

  

    render() {
      return (
   <Card>
 <h5
class="heading"
style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}>Bar Chart 
</h5>
<hr style={{ marginTop: 0, marginBottom: 0 }} />       
 <div id="chart">
<Chart options={this.state.options}
 series={this.state.series} type="bar" height={450} />
</div>
</Card>
      );
    }
  }
export default BarChart;