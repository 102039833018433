import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
// import echarts from "echarts";
import { Card } from "react-bootstrap";

export default class LogAxis extends Component {
  state = {
    option: {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c}",
      },
      legend: {
        left: "left",
        data: ["Line 1", "Line 2", "Line 3"],
        textStyle: {
          color: "#8f9bb3",
        },
      },
      xAxis: {
        type: "category",
        name: "x",
        splitLine: { show: false },
        data: ["1", "2", "3", "4", "5", "6", "6", "7", "8"],
        axisLabel: {
          show: true,
          textStyle: {
            color: "#8f9bb3",
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#8f9bb3",
          },
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      yAxis: {
        type: "log",
        name: "y",
        minorTick: {
          show: false,
        },
        minorSplitLine: {
          show: false,
        },
        axisLabel: {
          show: true,
          textStyle: {
            color: "#8f9bb3",
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#8f9bb3",
          },
        },
      },
      series: [
        {
          name: "Line 1",
          type: "line",
          data: [1, 3, 9, 27, 81, 247, 741, 2223, 6669],
          color: ["#f93c6f"],
        },
        {
          name: "Line 2",
          type: "line",
          data: [1, 2, 4, 8, 16, 32, 64, 128, 256],
          color: ["#3162f2"],
        },
        {
          name: "Line 3",
          type: "line",
          data: [
            1 / 2,
            1 / 4,
            1 / 8,
            1 / 16,
            1 / 32,
            1 / 64,
            1 / 128,
            1 / 256,
            1 / 512,
          ],
          color: ["#0095ff"],
        },
      ],
    },
  };
  render() {
    return (
      <Card className="echart-card">
        <h5
          class="heading"
          style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
        >
          Line
        </h5>
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <ReactEcharts className="echarts" option={this.state.option} />
      </Card>
    );
  }
}
