import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import "./EditUserForm.css";
import axios from "axios";
import Select from "react-select";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
// import FormControl from "@material-ui/core/FormControl";
import config from "../../config.json";

const EditUserForm = (props) => {
  const UserID = props.user.UserID;
  const Email = props.user.Email;
  const [FullName, setFullName] = useState(props.user.FullName);
  const [Password, setPassword] = useState("");

  const [FamilyID, setFamilyID] = useState(props.user.FamilyID);
  const [UserSubscriptionID, setUserSubscriptionID] = useState(
    props.user.UserSubscriptionID
  );
  const [AvatarID, setAvatarID] = useState(props.user.AvatarID);
  const [isActive, setIsActive] = useState(Boolean(props.user.isActive));
  const [isReader, setIsReader] = useState(Boolean(props.user.isReader));
  const [isChildren, setIsChildren] = useState(Boolean(props.user.isChildren));
  const [Mobile, setMobile] = useState(props.user.Mobile);
  const [UserAge, setUserAge] = useState(props.user.UserAge);
  const [DOB, setDOB] = useState(props.user.DOB);
  //const [selectedUserType, setSelectedUserType] = useState({label: props.user.UserTypeID, value:""});
  const [newSelectedUserType, setNewSelectedUserType] = useState({
    label: "",
    value: props.user.UserTypeID,
  });
  const [userTypeOptions, setUserTypeOptions] = useState({ options: [] });
  const token = localStorage.getItem("Token");
  const ServerPath = config.rapidServerPath;

  const validatePass = (value) => {
    if (value) {
      let pass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&])[A-Za-z\d@#$!%*?&]{6,}$/;
      return pass.test(value);
    }
    return true;
  };

  const validateContact = (value) => {
    let no = /^[6-9]\d{9}$/;
    return no.test(value);
  };

  const validateAge = (age) => {
    if (age >= 1 && age <= 100) {
      return true;
    } else {
      return false;
    }
  };

  const createNotificationHandler = (type) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "Details changed successfully",
          "Success",
          3000
        );
        break;
      case "error":
        NotificationManager.error("Something went wrong", "error", 3000);
        break;
      case "passwordError":
        NotificationManager.info(
          "Must contain one number, one uppercase letter, one lowercase letter, one special character and at least 6 characters",
          "Password do not match the requirements",
          7000
        );
        break;
      case "age":
        NotificationManager.info("Age must be between 1 to 100", "info", 3000);
        break;
      case "contactError":
        NotificationManager.info("Enter Correct Contact Number", "Info", 3000);
        break;
    }
  };

  async function handleUpdate(e) {
    e.preventDefault();
    if (!validatePass(Password)) {
      createNotificationHandler("passwordError");
    } else if (!validateContact(Mobile)) {
      createNotificationHandler("contactError");
    } else if (!validateAge(UserAge)) {
      createNotificationHandler("age");
    } else {
      try {
        const response = await axios.put(
          ServerPath + "/api/users/update/" + UserID,
          {
            UserID: UserID,
            Password: Password,
            FullName: FullName,
            UserTypeID:
              newSelectedUserType === "" ? null : newSelectedUserType.value,
            FamilyID: FamilyID === "" ? null : FamilyID,
            UserSubscriptionID:
              UserSubscriptionID === "" ? null : UserSubscriptionID,
            AvatarID: AvatarID === "" ? null : AvatarID,
            isActive: Number(isActive),
            isReader: Number(isReader),
            isChildren: Number(isChildren),
            Mobile: Mobile,
            UserAge: Number(UserAge),
            DOB: DOB,
          },
          {
            headers: {
              Authorization: token,
              Apikey: "XYZ",
            },
          }
        );
        props.sendUpdate(true);
        if (response.data.success === true) {
          createNotificationHandler("success");
        } else {
          createNotificationHandler("error");
        }
        props.closeModel();
      } catch (error) {
        if (error.response) {
          NotificationManager.error(error.response.data.error, "Error", 3000);
          console.log(error);
        }
      }
    }
  }
  // const selected = selectedUserType;

  const handleUserTypeChange = (selected) => {
    setNewSelectedUserType(selected);
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          ServerPath + "/api/core/lookup/UserType",
          {
            headers: {
              apikey: "XYZ",
            },
          }
        );
        const options = [];
        response.data.map((usertype) => {
          options.push({ label: usertype.Name, value: usertype.Value });
        });
        options.forEach((element) => {
          console.log(newSelectedUserType);
          if (element.value == newSelectedUserType.value) {
            setNewSelectedUserType(element);
          }
        });
        setUserTypeOptions({ options: options });
      } catch (error) {
        if (error.response) {
          NotificationManager.error(error.response.data.error, "Error", 3000);
          console.log(error);
        }
      }
    }
    fetchData();
  }, []);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: "3.4375em",
      // minHeight: 60,
      borderRadius: 8,
      fontFamily: "inherit",
      fontSize: "18px",
      backgroundColor: "rgba(0, 0, 0, 0.02) ",
      // lineHeight: "50px",
      marginBottom: "0.7500em",
      width: "96%",
    }),
  };

  return (
    <div className="user-register-container">
      <div className="content">
        <form onSubmit={(e) => handleUpdate(e)}>
          <div className="input-group">
            <input id="email" type="email" value={Email} readOnly />
            <label htmlFor="email">E-mail</label>
          </div>
          <div className="input-group">
            <input
              id="password"
              type="password"
              placeholder="PASSWORD"
              // value={Password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label htmlFor="password">Password</label>
          </div>
          <div className="input-group">
            <input
              id="fullname"
              placeholder="FULL NAME"
              value={FullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
            <label htmlFor="fullname">Full Name</label>
          </div>

          {/* <div className="input-group">
              <input
                id="usertypeid"
                placeholder="USER TYPE ID"
                value={UserTypeID}
                onChange={(e) => setUserTypeID(e.target.value)}
              />
              <label htmlFor="usertypeid">User Type ID</label>
            </div> */}
          <Select
            isSearchable={false}
            options={userTypeOptions.options}
            value={newSelectedUserType}
            onChange={handleUserTypeChange}
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={customStyles}
            theme={(theme) => ({
              ...theme,

              borderRadius: ".25rem",
              colors: {
                ...theme.colors,
                primary25: "#adb5bd",
                primary: "#007bff",
              },
            })}
          />

          <div className="input-group">
            <input
              id="familyid"
              placeholder="FAMILY ID"
              value={FamilyID}
              onChange={(e) => setFamilyID(e.target.value)}
            />
            <label htmlFor="familyid">Family ID</label>
          </div>

          <div className="two-input-group">
            <div className="input-group">
              <input
                id="usersubid"
                placeholder="USER SUB ID"
                value={UserSubscriptionID}
                onChange={(e) => setUserSubscriptionID(e.target.value)}
              />
              <label htmlFor="usersubid">User Sub ID</label>
            </div>
            <div className="input-group">
              <input
                id="avatarid"
                placeholder="AVATAR ID"
                value={AvatarID}
                onChange={(e) => setAvatarID(e.target.value)}
              />
              <label htmlFor="avatarid">Avatar ID</label>
            </div>
          </div>
          <div className="input-group">
            <input
              id="mobile"
              placeholder="MOBILE"
              value={Mobile}
              onChange={(e) => setMobile(e.target.value)}
              required
            />
            <label htmlFor="mobile">Mobile</label>
          </div>
          {/* <input
            placeholder="USER AGE"
            value={UserAge}
            onChange={(e) => setUserAge(e.target.value)}
            required
          /> */}
          <div className="input-group">
            <input
              id="DOB"
              placeholder="DOB"
              value={DOB}
              onChange={(e) => setDOB(e.target.value)}
              required
            />
            <label htmlFor="DOB">Date of Birth</label>
          </div>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={isActive}
                  onChange={(e) => setIsActive(!Boolean(isActive))}
                />
              }
              label="isActive?"
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={isReader}
                  onChange={(e) => setIsReader(!Boolean(isReader))}
                />
              }
              label="isReader?"
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={isChildren}
                  onChange={(e) => setIsChildren(!Boolean(isChildren))}
                />
              }
              label="isChildren?"
              labelPlacement="start"
            />
          </FormGroup>
          <button className="button" type="submit">
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditUserForm;
