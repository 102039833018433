import React, { useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import { FiPower, FiTrash2 } from "react-icons/fi";
import Select from "react-select";

import {
  AiOutlineMail,
  AiOutlineSetting,
  AiOutlinePoweroff,
} from "react-icons/ai";
import {
  Navbar,
  Button,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import user from "../childcomponents/HelperComponents/assets/images/people/a5.jpg";
var presentTheme = "light";

const Topbar = ({ toggleSidebar, handleClick }) => {
  const history = useHistory();
  const [topbarIsOpen, setTopbarOpen] = useState(true);
  const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);
  const currentTheme = localStorage.getItem("theme-color");
  var currentLabel = "";
  if (currentTheme === "theme-black") {
    currentLabel = "Dark";
    presentTheme = "dark";
  } else {
    currentLabel = "Light";
    presentTheme = "light";
  }
  const [selectedTheme, setSelectedTheme] = useState({
    value: currentTheme,
    label: currentLabel,
  });
  const options = [
    { value: "theme-white", label: "Light" },
    { value: "theme-black", label: "Dark" },
  ];

  const selected = selectedTheme;
  const handleSelectChange = (selected) => {
    try {
      setSelectedTheme(selected);
      handleClick(selected.value);
    } catch (err) {
      console.log(err);
    }
  };

  const Logout = () => {
    localStorage.removeItem("Token");
    history.push("/login");
    history.goBack();
  };

  return (
    <Navbar className="topbar" light expand="md">
      <Button color="info" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={faAlignLeft} />
      </Button>

      <div style={{ width: "100px", marginLeft: "10px" }}>
        <Select
          isSearchable={false}
          options={options}
          value={selectedTheme}
          onChange={handleSelectChange}
          components={{
            IndicatorSeparator: () => null,
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: ".25rem",
            colors: {
              ...theme.colors,
              primary25: "#adb5bd",
              primary: "#007bff",
            },
          })}
          className="react-select-container"
          classNamePrefix="react-select"
        />
      </div>

      <NavbarToggler onClick={toggleTopbar} />
      <Collapse navbar>
        <Nav className="ml-auto" navbar>
          <nav className="navbar list">
            <form className="form-inline mt-3">
              <input
                style={{ width: "68%" }}
                className="form-control mr-sm-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button
                className="btn btn-outline-success my-2 my-sm-0"
                type="submit"
              >
                Search
              </button>
            </form>
          </nav>

          <div
            style={{
              borderLeft: " 0.5px solid #17223b",
              height: "70px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          ></div>

          <NavItem>
            <NavLink
              className="topbar-font"
              style={{
                display: "flex",
                marginTop: "3px",
                borderRadius: "5px",
                textAlign: "center",
                height: "50px",
              }}
              tag={Link}
              to={"/ngx/userprofile"}
            >
              {/* <AiOutlineMail size="2em" /> */}
              <p
                style={{
                  marginTop: "12px",
                  marginLeft: "10px",
                  fontSize: "18px",
                }}
              >
                {localStorage.getItem("UserName") != null
                  ? "Welcome " + localStorage.getItem("UserName")
                  : "Welcome "}
              </p>
              <img
                src={user}
                width="50"
                height="50"
                marginLeft="11px"
                alt="React Bootstrap logo"
                style={{
                  borderRadius: "50%",
                  marginTop: "0px",
                  marginLeft: "11px",
                }}
              />
            </NavLink>
          </NavItem>

          {/*           
          <div
            style={{
              borderLeft: " 0.5px solid #17223b",
              height: "70px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          ></div> */}
          <div
            style={{
              borderLeft: " 0.5px solid #17223b",
              height: "70px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          ></div>
          <NavItem>
            <NavLink tag={Link} to={"/ngx/setting"} className="topbar-font">
              <AiOutlineSetting size="2em" style={{ marginTop: "10px" }} />
            </NavLink>
          </NavItem>
          <div
            style={{
              borderLeft: " 0.5px solid #17223b",
              height: "70px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          ></div>
          <NavItem>
            <NavLink className="topbar-font">
              <AiOutlinePoweroff
                type="button"
                size="2em"
                style={{ marginTop: "10px" }}
                onClick={Logout}
              />
            </NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export { presentTheme };
export default Topbar;
