import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Select from "react-select";
import { Card } from "react-bootstrap";

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "red",
  },
  container: {
    maxHeight: 407,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("xyz", 455, 7.0, 55, 6.0),
  createData("xyz", 455, 7.0, 55, 6.0),
  createData("xyz", 455, 7.0, 55, 6.0),
  createData("xyz", 455, 7.0, 55, 6.0),
  createData("xyz", 455, 7.0, 55, 6.0),
  createData("xyz", 455, 7.0, 55, 6.0),
  createData("xyz", 455, 7.0, 55, 6.0),
];

export default function SimpleTable() {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState({
    value: 1,
    label: "Week",
  });

  const selected = selectedOption;
  const handleSelectChange = (selected) => {
    setSelectedOption(selected);
  };

  const options = [
    { value: 4, label: "Week" },
    { value: 5, label: "Month" },
    { value: 6, label: "Year" },
  ];

  return (
    <Card>
      <div style={{ display: "flex", marginBottom: "10px" }}>
        <h6 className="user-activity-header">User Activity</h6>
        <div style={{ width: "100px", marginLeft: "auto", marginRight: "5%" }}>
          <Select
            isSearchable={false}
            options={options}
            value={selectedOption}
            autosize={true}
            onChange={handleSelectChange}
            components={{
              IndicatorSeparator: () => null,
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: ".25rem",
              colors: {
                ...theme.colors,
                primary25: "#adb5bd",
                primary: "#007bff",
              },
            })}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
      </div>
      <Paper className="user-table-ecom">
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="user-table-ecom-cell"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align="right" className="user-table-ecom-cell">
                    {row.calories}
                  </TableCell>
                  <TableCell align="right" className="user-table-ecom-cell">
                    {row.fat}
                  </TableCell>
                  <TableCell align="right" className="user-table-ecom-cell">
                    {row.carbs}
                  </TableCell>
                  <TableCell align="right" className="user-table-ecom-cell">
                    {row.protein}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Card>
  );
}
