import React, { Component, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Overlay,
  Button,
  Tooltip,
  Popover,
  OverlayTrigger,
  Form,
  Card,
} from "react-bootstrap";

// import React, { Component, useState, useRef } from "react";
// import {
//   Container,
//   Row,
//   Col,
//   Overlay,
//   Button,
//   Tooltip,
//   Popover,
//   OverlayTrigger,
// } from "react-bootstrap";

function Popper() {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Container fluid>
      <Row style={{ justifyContent: "space-around" }}>
        <Col className="modal-card-left" md={5}>
          <>
            <p style={{ marginTop: "20px" }}>
              As with Tooltips, you can control the placement of the Popover.
            </p>
            <OverlayTrigger
              trigger="click"
              key="right"
              placement="right"
              overlay={
                <Popover id={`popover-positioned-`}>
                  <Popover.Title as="h3"></Popover.Title>
                  <Popover.Content>
                    <strong>Holy guacamole!</strong> Check this info.
                  </Popover.Content>
                </Popover>
              }
            >
              <Button
                style={{ marginLeft: "10px", marginTop: "10px" }}
                variant="primary"
              >
                Popover on right{" "}
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              trigger="click"
              key="right"
              placement="left"
              overlay={
                <Popover id={`popover-positioned-`}>
                  <Popover.Title as="h3"></Popover.Title>
                  <Popover.Content>
                    <strong>Holy guacamole!</strong> Check this info.
                  </Popover.Content>
                </Popover>
              }
            >
              <Button
                variant="primary"
                style={{ marginLeft: "10px", marginTop: "10px" }}
              >
                Popover on left{" "}
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              trigger="click"
              key="right"
              placement="top"
              overlay={
                <Popover id={`popover-positioned-`}>
                  <Popover.Title as="h3"></Popover.Title>
                  <Popover.Content>
                    <strong>Holy guacamole!</strong> Check this info.
                  </Popover.Content>
                </Popover>
              }
            >
              <Button
                variant="primary"
                style={{ marginLeft: "10px", marginTop: "10px" }}
              >
                Popover on top
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              trigger="click"
              key="right"
              placement="bottom"
              overlay={
                <Popover id={`popover-positioned-`}>
                  <Popover.Title as="h3"></Popover.Title>
                  <Popover.Content>
                    <strong>Holy guacamole!</strong> Check this info.
                  </Popover.Content>
                </Popover>
              }
            >
              <Button
                variant="primary"
                style={{ marginLeft: "10px", marginTop: "10px" }}
              >
                Popover on bottom
              </Button>
            </OverlayTrigger>
          </>
        </Col>
        <Col className="modal-card-right" md={5}>
          <>
            <h1>Tooltip with Form</h1>
            <hr />
            <p>
              You can pass a refference to `vnmurt-template` to be rendered.
            </p>
            <OverlayTrigger
              trigger="click"
              key="left"
              placement="left"
              overlay={
                <Popover id={`popover-positioned-`}>
                  <Popover.Title as="h3"></Popover.Title>
                  <Popover.Content>
                    <Form>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" />
                        <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text>
                      </Form.Group>

                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" />
                      </Form.Group>
                      <Button variant="primary" type="submit">
                        Submit
                      </Button>
                    </Form>
                  </Popover.Content>
                </Popover>
              }
            >
              <Button
                style={{ marginLeft: "10px", marginTop: "10px" }}
                variant="primary"
              >
                Popover with Form{" "}
              </Button>
            </OverlayTrigger>
          </>
        </Col>
      </Row>
      <Row style={{ justifyContent: "space-around" }}>
        <Col md={5} className="modal-card-next-right">
          <>
            <p style={{ marginTop: "20px" }}>
              As with Tooltips, you can control the placement of the Popover.
            </p>
            <OverlayTrigger
              trigger="hover"
              key="right"
              placement="right"
              overlay={
                <Popover id={`popover-positioned-`}>
                  <Popover.Title as="h3"></Popover.Title>
                  <Popover.Content>
                    <strong>Holy guacamole!</strong> Check this info.
                  </Popover.Content>
                </Popover>
              }
            >
              <Button
                style={{ marginLeft: "10px", marginTop: "10px" }}
                variant="primary"
              >
                Popover on right{" "}
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              trigger="hover"
              key="right"
              placement="left"
              overlay={
                <Popover id={`popover-positioned-`}>
                  <Popover.Title as="h3"></Popover.Title>
                  <Popover.Content>
                    <strong>Holy guacamole!</strong> Check this info.
                  </Popover.Content>
                </Popover>
              }
            >
              <Button
                variant="primary"
                style={{ marginLeft: "10px", marginTop: "10px" }}
              >
                Popover on left{" "}
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              trigger="hover"
              key="right"
              placement="top"
              overlay={
                <Popover id={`popover-positioned-`}>
                  <Popover.Title as="h3"></Popover.Title>
                  <Popover.Content>
                    <strong>Holy guacamole!</strong> Check this info.
                  </Popover.Content>
                </Popover>
              }
            >
              <Button
                variant="primary"
                style={{ marginLeft: "10px", marginTop: "10px" }}
              >
                Popover on top
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              trigger="hover"
              key="right"
              placement="bottom"
              overlay={
                <Popover id={`popover-positioned-`}>
                  <Popover.Title as="h3"></Popover.Title>
                  <Popover.Content>
                    <strong>Holy guacamole!</strong> Check this info.
                  </Popover.Content>
                </Popover>
              }
            >
              <Button
                variant="primary"
                style={{ marginLeft: "10px", marginTop: "10px" }}
              >
                Popover on bottom
              </Button>
            </OverlayTrigger>
          </>
        </Col>
        <Col md={5} className="modal-card-next-left">
          <>
            <h1>Tooltip with Card</h1>
            <hr />
            <p>
              You can pass a refference to `vnmurt-template` to be rendered.
            </p>
            <OverlayTrigger
              trigger="hover"
              key="left"
              placement="left"
              overlay={
                <Popover id={`popover-positioned-`}>
                  <Popover.Title as="h3"></Popover.Title>
                  <Popover.Content>
                    <Card className="text-center">
                      <Card.Header>Featured</Card.Header>
                      <Card.Body>
                        <Card.Title>Special title treatment</Card.Title>
                        <Card.Text>
                          With supporting text below as a natural lead-in to
                          additional content.
                        </Card.Text>
                        <Button variant="primary">Go somewhere</Button>
                      </Card.Body>
                      <Card.Footer className="text-muted">
                        2 days ago
                      </Card.Footer>
                    </Card>
                  </Popover.Content>
                </Popover>
              }
            >
              <Button
                style={{ marginLeft: "10px", marginTop: "10px" }}
                variant="primary"
              >
                Popover with Card{" "}
              </Button>
            </OverlayTrigger>
          </>
        </Col>
      </Row>
    </Container>
  );
}
export default Popper;
