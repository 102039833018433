import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
// import echarts from "echarts";
import { Card } from "react-bootstrap";

export default class GuageChart extends Component {
  state = {
    option1: {
      tooltip: {
        formatter: "{a} <br/>{b} : {c}%",
      },
      series: [
        {
          name: "Temperature",
          type: "gauge",
          //   detail: { formatter: "{value}%" },
          data: [{ value: 25, name: "Temperature" }],
        },
      ],
    },
    option2: {
      tooltip: {
        formatter: "{a} <br/>{b} : {c}%",
      },
      series: [
        {
          name: "Humidity",
          type: "gauge",
          //   detail: { formatter: "{value}%" },
          data: [{ value: 87, name: "Humidity" }],
        },
      ],
    },
  };
  render() {
    var chart = (
      <ReactEcharts className="iot-echarts" option={this.state.option1} />
    );
    if (this.props.value === 1) {
      chart = (
        <ReactEcharts className="iot-echarts" option={this.state.option2} />
      );
    }
    return <Card className="echart-card">{chart}</Card>;
  }
}
