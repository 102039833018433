import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
// import echarts from "echarts";
import { Card } from "react-bootstrap";

export default class PieChart extends Component {
  state = {
    option: {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: "left",
        data: ["Germany", "France", "USA", "Canada", "Russia"],
        textStyle: {
          color: "#8f9bb3",
        },
      },
      series: [
        {
          name: "Countries",
          type: "pie",
          data: [
            { value: 335, name: "Germany" },
            { value: 310, name: "France" },
            { value: 234, name: "USA" },
            { value: 135, name: "Canada" },
            { value: 1548, name: "Russia" },
          ],
          color: ["#ffc94d", "#42aaff", "#ff708d", "#2ce69b", "#598bff"],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    },
  };
  render() {
    return (
      <Card className="echart-card">
        <h5
          class="heading"
          style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
        >
          Pie
        </h5>
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <ReactEcharts className="echarts" option={this.state.option} />
      </Card>
    );
  }
}
