import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Col,
  InputGroup,
  Container,
  Row,
  Card,
  Image,
} from "react-bootstrap";
import api from "../../../services/api";
// import heroesImg from "../../../assets/heroes.png";
import { AiOutlinePhone } from "react-icons/ai";
import { FaAddressBook } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { AiFillInstagram, AiFillLinkedin, AiFillGithub } from "react-icons/ai";
import ProfileGithub from "../HelperComponents/ProfileGithub";
// import avatar from "../assets/img/faces/face-3.jpg";
import gravatar from "gravatar";
export default function UserProfile() {
  const [items, setItems] = useState([]);
  const [validated, setValidated] = useState(false);
  const Token = localStorage.getItem("Token");
  const Email = localStorage.getItem("Email");
  const avatar = gravatar.url(Email, {
    s: "250", // Size
    r: "pg", // Rating
    d: "mm", // Default
  });
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const GETintern = "https://www.work4skills.com/api/CORE/ReadUser";
  const getItem = () => {
    api
      .get(GETintern, {
        headers: {
          Authorization: Token,
          APIKEY: "XYZ",
        },
      })
      .then((response) => response.data)
      .then((items) => setItems(items))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getItem();
  }, []);
  return (
    <Container className="list-typo">
      <Row>
        <Col md={8}>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} md="10" controlId="validationCustom01">
                <Form.Label>First name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="First name"
                  defaultValue={items.firstName}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="10" controlId="validationCustom02">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Last name"
                  defaultValue={items.lastName}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="10" controlId="validationCustomUsername">
                <Form.Label>Email</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    placeholder="Username"
                    aria-describedby="inputGroupPrepend"
                    required
                    defaultValue={items.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please choose a email.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group as={Col} md="10" controlId="validationCustom02">
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Mobile"
                  defaultValue={items.mobile}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="10" controlId="validationCustom03">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="DOB"
                  required
                  defaultValue={items.dob}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid date of birth.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="10" controlId="validationCustom04">
                <Form.Label>Address 1 </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="State"
                  required
                  defaultValue={items.address1}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid Address.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="10" controlId="validationCustom04">
                <Form.Label>Company </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="State"
                  required
                  defaultValue={items.provider}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid company.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Button type="submit">Submit form</Button>
          </Form>
        </Col>
        <Col md={4}>
          <Card style={{ width: "100%" }} className="text-center">
            <Image
              src={avatar}
              roundedCircle
              style={{
                width: "180px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "10px",
              }}
            />
            <Card.Body>
              <Card.Title>
                {items.firstName} {items.lastName}
              </Card.Title>
              <p style={{ fontSize: "15px" }}>{items.email}</p>
              <p>
                <AiOutlinePhone size="24" /> Mobile : {items.mobile}
              </p>
              <p>
                <FaAddressBook size="24" /> Address : {items.address1}
              </p>
              <p>
                <MdDateRange size="24" /> Date of Birth : {items.dob}
              </p>
              <br />
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div>
                  <AiFillInstagram size="35" />
                </div>
                <div>
                  <AiFillLinkedin size="35" />
                </div>
                <div>
                  <AiFillGithub size="35" />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={12} style={{ marginTop: "25px" }}>
          <ProfileGithub />
        </Col>
      </Row>
    </Container>
  );
}
