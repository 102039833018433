import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import HorizontalBarChart from "../ApexChart/HorizontalBarChart";
import RadarChart from "../ApexChart/RadarChart";
import TimeLineChart from "../ApexChart/TimeLineChart";
import BarChart from "../ApexChart/BarChart";
import StackedChart from "./StackedChart";
import MixedChart from "../ApexChart/MixedChart";

export default class ApexChart extends Component {
  render() {
    return (
      <Container fluid>
        <Row>
          <Col lg={6} style={{ marginBottom: 35 }}>
            <HorizontalBarChart />
          </Col>
          <Col lg={6} style={{ marginBottom: 35 }}>
            <BarChart />
          </Col>
        </Row>
        <Row>
          <Col lg={6} style={{ marginBottom: 35 }}>
            <RadarChart />
          </Col>
          <Col lg={6} style={{ marginBottom: 35 }}>
            <MixedChart />
          </Col>
        </Row>
        <Row>
          <Col lg={6} style={{ marginBottom: 35 }}>
            <StackedChart />
          </Col>
          <Col lg={6} style={{ marginBottom: 35 }}>
            <TimeLineChart />
          </Col>
        </Row>
      </Container>
    );
  }
}
