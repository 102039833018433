import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Form, Col, Button, Row } from "react-bootstrap";
// import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function HorizontalForm() {
  const classes = useStyles();
  // const [dense, setDense] = React.useState(false);
  // const [secondary, setSecondary] = React.useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.demo}>
        <Card className="cards">
          <Form>
            <h6
              class="heading"
              style={{
                paddingTop: "1rem",
                marginLeft: "1.5rem",
                marginBottom: 15,
              }}
            >
              Horizontal Form
            </h6>
            <hr style={{ marginTop: 0, marginBottom: 20 }} />
            <Form.Group
              as={Row}
              controlId="formHorizontalEmail"
              style={{ marginLeft: 5, marginRight: 10 }}
            >
              <Form.Label column sm={3} className="form-text">
                Email
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  className="form-layout"
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              controlId="formHorizontalPassword"
              style={{ marginLeft: 5, marginRight: 10 }}
            >
              <Form.Label column sm={3} className="form-text">
                Password
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  className="form-layout"
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              controlId="formHorizontalPassword"
              style={{ marginLeft: 23, marginRight: 10, marginBottom: 10 }}
            >
              <Form.Label column sm={3}></Form.Label>
              <Col sm={9}>
                <Row className="my-1">
                  <FormControlLabel
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={<Checkbox />}
                    label="Remember Me"
                    className="check"
                  />
                </Row>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              controlId="formHorizontalPassword"
              style={{ marginLeft: 5, marginRight: 10 }}
            >
              <Form.Label column sm={3}></Form.Label>
              <Col sm={9}>
                <Button variant="warning">SIGN IN</Button>{" "}
              </Col>
            </Form.Group>
          </Form>
        </Card>
      </div>
    </div>
  );
}
