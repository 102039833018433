import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
// import echarts from "echarts";
import { Card } from "react-bootstrap";

var xAxisData = [];
var data1 = [];
var data2 = [];
for (var i = 0; i < 100; i++) {
  xAxisData.push("Category" + i);
  data1.push((Math.sin(i / 5) * (i / 5 - 10) + i / 6) * 5);
  data2.push((Math.cos(i / 5) * (i / 5 - 10) + i / 6) * 5);
}

export default class BarAnimationChart extends Component {
  state = {
    option: {
      legend: {
        data: ["bar", "bar2"],
        textStyle: {
          color: "#8f9bb3",
        },
      },
      xAxis: {
        data: xAxisData,
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: true,
          textStyle: {
            color: "#8f9bb3",
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#8f9bb3",
          },
        },
      },
      yAxis: {
        axisLabel: {
          show: true,
          textStyle: {
            color: "#8f9bb3",
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#8f9bb3",
          },
        },
      },
      series: [
        {
          name: "bar",
          type: "bar",
          data: data1,
          animationDelay: function (idx) {
            return idx * 10;
          },
          color: ["#598bff"],
        },
        {
          name: "bar2",
          type: "bar",
          data: data2,
          animationDelay: function (idx) {
            return idx * 10 + 100;
          },
          color: ["#48bbff"],
        },
      ],
      animationEasing: "elasticOut",
      animationDelayUpdate: function (idx) {
        return idx * 5;
      },
    },
  };
  render() {
    return (
      <Card className="echart-card">
        <h5
          class="heading"
          style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
        >
          Bar Animation
        </h5>
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <ReactEcharts className="echarts" option={this.state.option} />
      </Card>
    );
  }
}
