//import { Dimensions } from "react-native";
//import { PixelRatio } from "react-native";

export const WPX = window.innerWidth + "px";
export const HPX = window.innerHeight + "px";
export const W = window.innerWidth;
export const H = window.innerHeight;
export const em = W / 720;
export const hm = H / 720;

//export const SW = Dimensions.get("screen").width;
//export const SH = Dimensions.get("screen").height;
//export const PR = PixelRatio.get();
//export const RW = SW * PR;
//export const RH = SH * PR;
