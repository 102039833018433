import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
// import echarts from "echarts";
import { Card } from "react-bootstrap";

var data = {
  name: "flare",
  children: [
    {
      name: "flex",
      value: 4116,
      collapsed: null,
    },
    {
      name: "analytics",
      collapsed: true,
      children: [
        {
          name: "cluster",
          collapsed: true,
          value: 3,
        },
        {
          name: "graph",
          collapsed: true,
          value: 4,
        },
        {
          name: "optimization",
          collapsed: true,
          value: 5,
        },
      ],
    },
  ],
};

export default class TreeChart extends Component {
  state = {
    option: {
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
      },
      series: [
        {
          type: "tree",

          data: [data],

          top: "1%",
          left: "7%",
          bottom: "1%",
          right: "20%",

          symbolSize: 7,

          label: {
            position: "left",
            verticalAlign: "middle",
            align: "right",
            fontSize: 12,
            color: "#8f9bb3",
          },

          leaves: {
            label: {
              position: "right",
              verticalAlign: "middle",
              align: "left",
            },
          },

          expandAndCollapse: true,
          animationDuration: 550,
          animationDurationUpdate: 750,
        },
      ],
    },
  };
  render() {
    return (
      <Card className="echart-card">
        <h5
          class="heading"
          style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
        >
          Tree
        </h5>
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <ReactEcharts className="echarts" option={this.state.option} />
      </Card>
    );
  }
}
