import React, { Component } from "react";
import { Card, Button, Container } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { AiOutlineMenu } from "react-icons/ai";
import { AiOutlinePauseCircle } from "react-icons/ai";
import { BsBell } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { FiSettings } from "react-icons/fi";

export default class FormButtons extends Component {
  render() {
    return (
      <Container fluid>
        <Card className="button-card">
          <h5
            className="heading"
            style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
          >
            Normal Buttons
          </h5>
          <hr style={{ marginTop: 0, marginBottom: 0 }} />
          <div className="formbuttons">
            <Button variant="primary">Primary</Button>{" "}
            <Button variant="success">Success</Button>{" "}
            <Button variant="info">Info</Button>{" "}
            <Button variant="warning">Warning</Button>{" "}
            <Button variant="danger">Danger</Button>{" "}
            <Button disabled variant="secondary">
              Disabled
            </Button>{" "}
          </div>
        </Card>
        <Card className="button-card">
          <h5
            className="heading"
            style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
          >
            Outline Buttons
          </h5>
          <hr style={{ marginTop: 0, marginBottom: 0 }} />
          <div className="formbuttons">
            <Button variant="outline-primary">Primary</Button>{" "}
            <Button variant="outline-success">Success</Button>{" "}
            <Button variant="outline-warning">Warning</Button>{" "}
            <Button variant="outline-danger">Danger</Button>{" "}
            <Button variant="outline-info">Info</Button>{" "}
            <Button disabled variant="outline-secondary">
              Disabled
            </Button>{" "}
          </div>
        </Card>
        <Card className="button-card">
          <h5
            className="heading"
            style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
          >
            Ghost Buttons
          </h5>
          <hr style={{ marginTop: 0, marginBottom: 0 }} />
          <div className="formbuttons formbuttonsghost">
            <Button variant="outline-primary">Primary</Button>{" "}
            <Button variant="outline-success">Success</Button>{" "}
            <Button variant="outline-warning">Warning</Button>{" "}
            <Button variant="outline-danger">Danger</Button>{" "}
            <Button variant="outline-info">Info</Button>{" "}
            <Button disabled variant="outline-secondary">
              Disabled
            </Button>{" "}
          </div>
        </Card>
        <Card className="button-card">
          <h5
            className="heading"
            style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
          >
            Button Sizes
          </h5>
          <hr style={{ marginTop: 0, marginBottom: 0 }} />
          <div className="formbuttons formbuttonsghost">
            <Button style={{ fontSize: "0.75rem" }} variant="secondary">
              Tiny
            </Button>{" "}
            <Button style={{ fontSize: "1rem" }} variant="secondary">
              Small
            </Button>{" "}
            <Button style={{ fontSize: "1.25rem" }} variant="secondary">
              Medium
            </Button>{" "}
            <Button style={{ fontSize: "1.5rem" }} variant="secondary">
              Large
            </Button>{" "}
            <Button style={{ fontSize: "1.75rem" }} variant="secondary">
              Giant
            </Button>{" "}
          </div>
        </Card>
        <Card className="button-card">
          <h5
            className="heading"
            style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
          >
            Action Buttons
          </h5>
          <hr style={{ marginTop: 0, marginBottom: 0 }} />
          <div className="formbuttons" style={{ display: "flex" }}>
            <button disabled className="action-button">
              <AiOutlineMenu size={30} color="#8f9bb3" />
            </button>
            <div className="divline"></div>
            <button disabled className="action-button">
              <BsSearch size={30} color="#8f9bb3" />
            </button>
            <div className="divline"></div>
            <button disabled className="action-button">
              <FiMail size={30} color="#8f9bb3" />
            </button>
            <div className="divline"></div>
            <button disabled className="action-button">
              <BsBell size={30} color="#8f9bb3" />
            </button>
            <div className="divline"></div>
            <button disabled className="action-button">
              <FiSettings size={30} color="#8f9bb3" />
            </button>
          </div>
        </Card>
        <Card>
          <div className="formbuttons" style={{ display: "flex" }}>
            <button disabled className="action-button">
              <AiOutlinePauseCircle size={30} color="#8f9bb3" />{" "}
              <span>Pause</span>
            </button>
            <div className="divline2"></div>
            <button disabled className="action-button">
              <BsSearch size={30} color="#8f9bb3" /> <span>Search</span>
            </button>
            <div className="divline2"></div>
            <button disabled className="action-button">
              <AiOutlineMenu size={30} color="#8f9bb3" /> <span>Logs</span>
            </button>
            <div className="divline2"></div>
            <button disabled className="action-button">
              <FiSettings size={30} color="#8f9bb3" /> <span>Settings</span>
            </button>
          </div>
        </Card>
      </Container>
    );
  }
}
