import { Doughnut } from "react-chartjs-2";
import React, { Component } from "react";

class Doughnutchart extends Component {
  state = {
    labels: ["2015", "2016", "2017", "2018", "2019"],
    datasets: [
      {
        label: "Revenue",
        backgroundColor: [
          "#FF9416",
          "#FF402C",
          "#535AD7",
          "#4BD762",
          "#D42AE8",
        ],
        hoverBackgroundColor: [
          "#FFC266",
          "#FF7B65",
          "#8784DB",
          "#6EDB8F",
          "#D284BD",
        ],
        data: [65, 59, 80, 81, 56],
      },
    ],
  };

  render() {
    return (
      <div style={{ height: "80%", width: "70%", marginLeft: "10%" }}>
        <Doughnut
          height={150}
          width={340}
          data={this.state}
          options={{
            aspectRatio: 1,
            maintainAspectRatio: false,
            responsive: true,
            title: {
              display: true,
              text: "Revenue per Year",
              fontSize: 20,
            },
            legend: {
              display: true,
              position: "right",
            },
          }}
        />
      </div>
    );
  }
}

export default Doughnutchart;
