import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { Card } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function StandardAlert() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card>
        <h5
          className="heading"
          style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
        >
          Standard Alerts
        </h5>
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <div className="alerts">
          <Alert variant="standard" severity="error">
            You have been successfully authenticated!
          </Alert>
          <Alert variant="standard" severity="warning">
            You have been successfully authenticated!
          </Alert>
          <Alert variant="standard" severity="info">
            You have been successfully authenticated!
          </Alert>
          <Alert variant="standard" severity="success">
            You have been successfully authenticated!
          </Alert>
        </div>
      </Card>
    </div>
  );
}
