import React, { Component } from "react";
import { ResponsiveVoronoi } from "@nivo/voronoi";
import { data } from "./VoronoiData";
import { Popover, OverlayTrigger, Card } from "react-bootstrap";

export default class CalendarChart extends Component {
  render() {
    return (
      <Card style={{ height: "462px" }}>
        <OverlayTrigger
          trigger="hover"
          key="left"
          placement="left"
          overlay={
            <Popover id={`popover-positioned-`}>
              <Popover.Title as="h3"></Popover.Title>
              <Popover.Content>
                <Card className="text-center">
                  <Card.Header>Voronoi Chart</Card.Header>
                  <Card.Body>
                    <Card.Title>About</Card.Title>
                    <Card.Text>
                      The RED lines represent the cell. Each cell contains one
                      node. The ORANGE lines represent the links between the
                      nodes.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Popover.Content>
            </Popover>
          }
        >
          <h5
            className="heading"
            style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
          >
            Voronoi
          </h5>
        </OverlayTrigger>
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <ResponsiveVoronoi
          data={data}
          xDomain={[0, 100]}
          yDomain={[0, 100]}
          margin={{ top: 1, right: 1, bottom: 1, left: 1 }}
          enableLinks={true}
          linkLineColor="orange"
          cellLineColor="#c6432d"
          pointSize={6}
          pointColor="#c6432d"
        />
      </Card>
    );
  }
}
