import React from "react";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import { Card } from "react-bootstrap";

const options = [
  { value: 1, label: "Week" },
  { value: 2, label: "Month" },
  { value: 3, label: "Year" },
];

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: { value: 1, label: "Week" },
      series: [
        {
          name: "MB",
          data: [300, 520, 435, 530, 730, 620, 730, 850],
        },
      ],
      options: {
        chart: {
          background: "transparent",
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 3,
        },
        stroke: {
          width: 3,
          curve: "straight",
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: false,
        },
        xaxis: {
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
      },
    };
  }

  handleChange = (selected) => {
    this.setState({ selectedOption: selected });
  };

  render() {
    const selected = this.state.selectedOption;

    return (
      <Card>
        <div id="chart">
          <div style={{ display: "flex", marginBottom: "10px", marginTop: 10 }}>
            <h6 className="user-activity-header">Traffic Consumption</h6>
            <div
              style={{ width: "100px", marginLeft: "auto", marginRight: "5%" }}
            >
              <Select
                isSearchable={false}
                options={options}
                value={selected}
                autosize={true}
                onChange={this.handleChange}
                components={{
                  IndicatorSeparator: () => null,
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: ".25rem",
                  colors: {
                    ...theme.colors,
                    primary25: "#adb5bd",
                    primary: "#007bff",
                  },
                })}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>
          </div>
          <hr style={{ marginTop: 0, marginBottom: 0 }} />
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="area"
            height={287}
          />
        </div>
      </Card>
    );
  }
}

export default LineChart;
