import React from "react";
import ReactApexChart from "react-apexcharts";

class SpLine extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series1: [
        {
          name: "Payment",
          data: [31, 40, 28, 51, 42, 109, 100],
        },
        {
          name: "Canceled",
          data: [11, 32, 45, 32, 34, 52, 41],
        },
        {
          name: "All Orders",
          data: [89, 46, 13, 72, 17, 99, 43],
        },
      ],
      options1: {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "category",
          categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        tooltip: {
          enabled: true,
        },
      },
      series2: [
        {
          name: "Payment",
          data: [95, 80, 68, 71, 32, 19, 88, 61, 38, 98, 23, 55],
        },
        {
          name: "Canceled",
          data: [45, 14, 33, 56, 72, 66, 9, 33, 44, 17, 61, 28],
        },
        {
          name: "All Orders",
          data: [140, 94, 101, 127, 104, 85, 97, 82, 115, 84, 83, 100],
        },
      ],
      options2: {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "category",
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        tooltip: {
          enabled: true,
        },
      },
      series3: [
        {
          name: "Payment",
          data: [60, 75, 90, 34, 58, 100, 49],
        },
        {
          name: "Canceled",
          data: [45, 32, 50, 63, 27, 40, 55],
        },
        {
          name: "All Orders",
          data: [105, 110, 140, 97, 85, 140, 104],
        },
      ],
      options3: {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: ["2014", "2015", "2016", "2017", "2018", "2019", "2020"],
          labels: {
            datetimeFormatter: {
              year: "yyyy",
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
    };
  }

  render() {
    var chart = null;
    if (this.props.value === 1) {
      chart = (
        <div id="chart">
          <ReactApexChart
            options={this.state.options1}
            series={this.state.series1}
            type="area"
            height={350}
          />
        </div>
      );
    } else if (this.props.value === 2) {
      chart = (
        <div id="chart">
          <ReactApexChart
            options={this.state.options2}
            series={this.state.series2}
            type="area"
            height={350}
          />
        </div>
      );
    } else {
      chart = (
        <div id="chart">
          <ReactApexChart
            options={this.state.options3}
            series={this.state.series3}
            type="area"
            height={350}
          />
        </div>
      );
    }

    return <div>{chart}</div>;
  }
}

export default SpLine;
