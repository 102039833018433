import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CalendarDate from "./CalendarDate";
import CalendarRange from "./CalendarRange";

class Calendar extends Component {
  render() {
    return (
      <Container fluid>
        <Row>
          <Col lg={4}>
            <CalendarDate />
          </Col>
          <Col lg={4}>
            <CalendarRange />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Calendar;
