import React, { Component } from "react";
import ReactSpinner from "react-bootstrap-spinner";
import { Card, Container, Button } from "react-bootstrap";

class Spinners extends Component {
  render() {
    return (
      <Container fluid>
        <Card className="spinner-card">
          <div className="spinner">
            <h5 className="spinner-text">Some Card Content</h5>
            <ReactSpinner type="border" color="primary" size="1" />
          </div>
        </Card>
        <Card className="spinner-card">
          <div className="spinner">
            <h5 className="spinner-text">Some Card Content</h5>
            <ReactSpinner type="border" color="primary" size="1.25" />
          </div>
        </Card>
        <Card className="spinner-card">
          <div className="spinner">
            <h5 className="spinner-text">Some Card Content</h5>
            <ReactSpinner type="border" color="primary" size="1.5" />
          </div>
        </Card>
        <Card className="spinner-card">
          <div className="spinner">
            <h5 className="spinner-text">Some Card Content</h5>
            <ReactSpinner type="border" color="primary" size="1.75" />
          </div>
        </Card>
        <Card className="spinner-card">
          <div className="spinner">
            <h5 className="spinner-text">Some Card Content</h5>
            <ReactSpinner type="border" color="primary" size="2" />
          </div>
        </Card>
        <Card className="spinner-card">
          <div className="spinner">
            <h5 className="spinner-text">Some Card Content</h5>
            <ReactSpinner type="border" color="primary" size="1.5" />
          </div>
        </Card>
        <Card className="spinner-card">
          <div className="spinner">
            <h5 className="spinner-text">Some Card Content</h5>
            <ReactSpinner type="border" color="success" size="1.5" />
          </div>
        </Card>
        <Card className="spinner-card">
          <div className="spinner">
            <h5 className="spinner-text">Some Card Content</h5>
            <ReactSpinner type="border" color="info" size="1.5" />
          </div>
        </Card>
        <Card className="spinner-card">
          <div className="spinner">
            <h5 className="spinner-text">Some Card Content</h5>
            <ReactSpinner type="border" color="warning" size="1.5" />
          </div>
        </Card>
        <Card className="spinner-card">
          <div className="spinner">
            <h5 className="spinner-text">Some Card Content</h5>
            <ReactSpinner type="border" color="danger" size="1.5" />
          </div>
        </Card>

        <Card classname="spinner-card">
          <div classname="spinner">
            <h5
              className="heading"
              style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
            >
              Button With Spinner
            </h5>
            <hr style={{ marginTop: 0, marginBottom: 0 }} />
            <div className="button-spinner">
              <Button size="lg" variant="primary" disabled>
                <ReactSpinner type="border" color="light" size="1.5" />
              </Button>{" "}
              <Button
                style={{ marginRight: "21px" }}
                size="lg"
                variant="primary"
                disabled
              >
                <ReactSpinner type="grow" color="light" size="1.5" />
                Loading...
              </Button>
              <Button size="lg" variant="success" disabled>
                <ReactSpinner type="border" color="light" size="1.5" />
              </Button>{" "}
              <Button
                style={{ marginRight: "21px" }}
                size="lg"
                variant="success"
                disabled
              >
                <ReactSpinner type="grow" color="light" size="1.5" />
                Loading...
              </Button>
              <Button size="lg" variant="info" disabled>
                <ReactSpinner type="border" color="light" size="1.5" />
              </Button>{" "}
              <Button
                style={{ marginRight: "21px" }}
                size="lg"
                variant="info"
                disabled
              >
                <ReactSpinner type="grow" color="light" size="1.5" />
                Loading...
              </Button>
              <Button size="lg" variant="warning" disabled>
                <ReactSpinner type="border" color="light" size="1.5" />
              </Button>{" "}
              <Button
                style={{ marginRight: "21px" }}
                size="lg"
                variant="warning"
                disabled
              >
                <ReactSpinner type="grow" color="light" size="1.5" />
                Loading...
              </Button>
              <Button size="lg" variant="danger" disabled>
                <ReactSpinner type="border" color="light" size="1.5" />
              </Button>{" "}
              <Button
                style={{ marginRight: "21px" }}
                size="lg"
                variant="danger"
                disabled
              >
                <ReactSpinner type="grow" color="light" size="1.5" />
                Loading...
              </Button>
            </div>
          </div>
        </Card>
      </Container>
    );
  }
}

export default Spinners;
