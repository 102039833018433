import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import SideBar from "../sidebar/SideBar";
import ConsumerSideBar from "../sidebar/ConsumerSideBar";
import Content from "../content/Content";
import "./ngx.scss";
// import Topbar from "../content/Topbar";
import { ToastContainer } from "react-toastify";

const Admin = () => {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const [colorTheme, setColorTheme] = useState("theme-white");
  const UserTypeID = localStorage.getItem("UserTypeID");

  useEffect(() => {
    // check for selected theme
    const currentThemeColor = localStorage.getItem("theme-color");
    // if found set selected theme
    if (currentThemeColor) {
      setColorTheme(currentThemeColor);
    }
  }, []);

  console.log(UserTypeID);

  const handleClick = (theme) => {
    setColorTheme(theme);
    localStorage.setItem("theme-color", theme);
    console.log(colorTheme);
  };

  const CloseButton = ({ closeToast }) => (
    <i onClick={closeToast} className="la la-close notifications-close" />
  );

  return (
    <>
      <ToastContainer
        autoClose={5000}
        hideProgressBar
        closeButton={<CloseButton />}
      />
      <Router>
        <div className={`App ${colorTheme}`}>
          {Number(UserTypeID) === 1 ? (
            <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
          ) : (
            <ConsumerSideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
          )}
          <Content
            toggleSidebar={toggleSidebar}
            sidebarIsOpen={sidebarIsOpen}
            handleClick={handleClick}
          />
        </div>
      </Router>
    </>
  );
};

export default Admin;
