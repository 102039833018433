import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import config from "../../config.json";
import "./BookForm.css";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const CreateAnimationParamForm = (props) => {
  const [AnimationID, setAnimationID] = useState(props.Animation.value);
  const [isAudio, setisAudio] = useState(false);
  const [AnimationType, setAnimationType] = useState("");
  const [ParamName, setParamName] = useState("");
  const [ParamValue, setParamValue] = useState("");
  const [ImageName, setImageName] = useState("");
  const [ImageURL, setImageURL] = useState("");
  const [AudioFilePath, setAudioFilePath] = useState("");
  const token = localStorage.getItem("Token");
  const ServerPath = config.rapidServerPath;

  const createNotificationHandler = (type) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "Details changed successfully",
          "Success",
          3000
        );
        break;
      case "error":
        NotificationManager.error("Something went wrong", "error", 3000);
        break;
    }
  };

  async function createAnimationParam(e) {
    e.preventDefault();
    //  let AnimId = props.Animation.AnimationID;
    handleCreate();
  }

  const handleCreate = async () => {
    const response = await axios.post(
      ServerPath + "/api/pagecontent/createPageAnimParam",
      {
        AnimationID: AnimationID,
        AnimationType: AnimationType,
        ParamName: ParamName,
        ParamValue: ParamValue,
        ImageName: ImageName,
        isPublished: Number(isAudio),
      },
      {
        headers: {
          Authorization: token,
          Apikey: "XYZ",
        },
      }
    );

    props.sendUpdate(true);
    if (response.data.success === true) {
      createNotificationHandler("success");
    } else {
      createNotificationHandler("error");
    }
    props.closeModel();
  };

  return (
    <div className="animation-container">
      <div className="content">
        <form onSubmit={(e) => createAnimationParam(e)}>
          <div className="input-group">
            <input
              id="AnimationID"
              placeholder="AnimationID"
              value={AnimationID}
              onChange={(e) => setAnimationID(e.target.value)}
              readOnly
            />
            <label htmlFor="AnimationID">Animation ID</label>
          </div>

          <div className="input-group">
            <input
              id="AnimationType"
              placeholder="AnimationType"
              value={AnimationType}
              onChange={(e) => setAnimationType(e.target.value)}
            />
            <label htmlFor="AnimationType">Animation Type</label>
          </div>
          <div className="input-group">
            <input
              id="ParamName"
              placeholder="ParamName"
              value={ParamName}
              onChange={(e) => setParamName(e.target.value)}
            />
            <label htmlFor="ParamName">Parameter Name</label>
          </div>
          <div className="input-group">
            <input
              id="ParamValue"
              placeholder="ParamValue"
              value={ParamValue}
              onChange={(e) => setParamValue(e.target.value)}
            />
            <label htmlFor="ParamValue">Parameter Value</label>
          </div>
          <div className="input-group">
            <input
              id="imagename"
              placeholder="imagename"
              value={ImageName}
              onChange={(e) => setImageName(e.target.value)}
            />
            <label htmlFor="imagename">Image Name</label>
          </div>
          <div className="input-group">
            <input
              id="imageurl"
              placeholder="imageurl"
              value={ImageURL}
              onChange={(e) => setImageURL(e.target.value)}
            />
            <label htmlFor="ParamValue">Image URL</label>
          </div>
          <div className="input-group">
            <input
              id="AudioFilePath"
              placeholder="AudioFilepath"
              value={AudioFilePath}
              onChange={(e) => setAudioFilePath(e.target.value)}
            />
            <label htmlFor="ParamValue">Audio File Path</label>
          </div>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={isAudio}
                  onChange={(e) => setisAudio(!Boolean(isAudio))}
                />
              }
              label="isPublished?"
              labelPlacement="start"
            />
          </FormGroup>

          <button className="button" type="submit">
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateAnimationParamForm;
