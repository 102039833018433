import React, { useState } from "react";
import { FiLogIn } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import "./styles.css";
import LogoImg from "../../../assets/logo.png";
import HMRImg from "../../../assets/Logo_Small.png";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import config from "../../../config.json";

import "../../../global.css";
import usePasswordToggle from "../../childcomponents/hooks/usePasswordToggle";

export default function Login(props) {
  const [passwordInputType, ToggleIcon] = usePasswordToggle();
  const [credential, setCredential] = useState({ Email: "", Password: "" });
  const [verifyCode, setVerifyCode] = useState("");
  const [registerEmail] = useState(localStorage.getItem("email"));
  const [registerPassword] = useState(localStorage.getItem("password"));
  const [registerUserId] = useState(localStorage.getItem("userId"));
  const history = useHistory();
  const ServerPath = config.rapidServerPath;

  const createNotificationHandler = (type) => {
    switch (type) {
      case "success":
        NotificationManager.success("Successfully Logged in", "Success", 3000);
        break;
      case "error":
        NotificationManager.error("Invalid EMail/Password", "Error", 3000);
        break;
      case "isAuth":
        NotificationManager.error("You are not authorized", "Sorry", 3000);
        break;
      case "successverify":
        NotificationManager.success("Welcome Aboard", "Success", 3000);
        break;
      case "errorverify":
        NotificationManager.error(
          "Enter Latest Code from EMail",
          "Invalid Verification Code",
          3000
        );
        break;
      case "info":
        NotificationManager.info("Please Enter Some Values", "Info", 3000);
        break;
      case "codeResend":
        NotificationManager.info("Verification Code Resent", "Info", 3000);
        break;
    }
  };

  async function handleResendVerification(e) {
    e.preventDefault();
    createNotificationHandler("codeResend");
    const response = await axios.post(
      ServerPath + "/api/CORE/VERIFY/" + registerEmail,
      null,
      {
        headers: { apikey: "XYZ" },
      }
    );
  }

  async function handleVerification(e) {
    e.preventDefault();
    const response = await axios.post(
      ServerPath + "/api/CORE/AUTHTOKEN",
      {
        Email: registerEmail,
        Password: registerPassword,
        VerificationText: verifyCode,
      },
      { headers: { APIKey: "XYZ" } }
    );
    if (response.data.userId === -2) {
      createNotificationHandler("errorverify");
    } else {
      createNotificationHandler("successverify");
      history.push("/ngx/dashboardeco");
    }
  }

  async function handleLogin(e) {
    e.preventDefault();
    try {
      const response = await axios.post(
        ServerPath + "/api/AUTH/login",
        { email: credential.Email, password: credential.Password },
        { headers: { apikey: "XYZ" } }
      );
      if (response.data.success === true) {
        localStorage.setItem("UserTypeID", response.data.UserTypeID);
        localStorage.setItem("email", credential.Email);
        localStorage.setItem("UserName", response.data.UserName);
        createNotificationHandler("success");
        localStorage.setItem("Token", response.data.token);
        if (response.data.UserTypeID === "1") {
          history.push("/ngx/HM_Users");
        } else {
          history.push("/ngx/HM_BookPageImages");
        }
      }
    } catch (error) {
      if (error.response) {
        NotificationManager.error(error.response.data.error, "Error", 3000);
        console.log(error);
      }
    }
  }

  return (
    <div className="logon-container">
      <section className="form">
        <img src={LogoImg} height="200" alt="Be The Hero" />

        <form
          onSubmit={
            !((registerUserId !== "-1") ^ (registerUserId !== null))
              ? handleVerification
              : handleLogin
          }
        >
          <div className="input-group">
            <input
              id="email"
              type="email"
              placeholder="EMAIL"
              value={
                !((registerUserId !== "-1") ^ (registerUserId !== null))
                  ? registerEmail
                  : credential.Email
              }
              onChange={(e) =>
                setCredential({ ...credential, Email: e.target.value })
              }
              required
            />
            <label htmlFor="email">E-mail</label>
          </div>
          <div className="input-group">
            <input
              id="password"
              type={passwordInputType}
              placeholder="PASSWORD"
              value={
                !((registerUserId !== "-1") ^ (registerUserId !== null))
                  ? registerPassword
                  : credential.Password
              }
              onChange={(e) =>
                setCredential({ ...credential, Password: e.target.value })
              }
              required
            />
            <label htmlFor="password">Password</label>
            <i className="password-toggle-icon">{ToggleIcon}</i>
          </div>
          {!((registerUserId !== "-1") ^ (registerUserId !== null)) ? (
            <input
              placeholder="VERIFY CODE FROM EMAIL"
              value={verifyCode}
              onChange={(e) => setVerifyCode(e.target.value)}
              required
            />
          ) : null}

          {!((registerUserId !== "-1") ^ (registerUserId !== null)) ? (
            <div>
              <button className="button" type="submit">
                Verify Code
              </button>
              <button className="button" onClick={handleResendVerification}>
                Resend Verification
              </button>
            </div>
          ) : (
            <button className="button" style={{ width: "96%" }} type="submit">
              Login
            </button>
          )}
          <Link style={{ margin: 0 }} className="back-link" to="/register">
            <button
              className="button"
              style={{ width: "96%", background: "#53a318" }}
            >
              {/* <FiLogIn size={16} color="#E02041" /> */}I am new here
            </button>
          </Link>
        </form>
      </section>
      <img id="optionalstuff" src={HMRImg} alt="Heroes" />
    </div>
  );
}
