import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import React, { Component } from "react";
import { Card } from "react-bootstrap";

export class MapContainer extends Component {
  render() {
    return (
      <Card className="google-map-card">
        <h5
          className="heading"
          style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
        >
          Google Maps
        </h5>
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <div>
          <Map google={this.props.google} zoom={14}>
            <Marker onClick={this.onMarkerClick} name={"Current location"} />
          </Map>
        </div>
      </Card>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBrpnaW4kCfF7CbVwFiGy8UhKaq6mLYV4A",
})(MapContainer);
