import React, { Component } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { data } from "./BarData";
import { Card } from "react-bootstrap";

export default class CalendarChart extends Component {
  render() {
    return (
      <Card className="d3-charts" style={{ height: "462px" }}>
        <h5
          className="heading"
          style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
        >
          Bar
        </h5>
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <ResponsiveBar
          data={data}
          keys={["hot dog", "burger", "sandwich", "kebab", "fries", "donut"]}
          indexBy="country"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          colors={[
            "#ffc94d",
            "#42aaff",
            "#ff708d",
            "#2ce69b",
            "#FB8C00",
            "#598bff",
          ]}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "country",
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "food",
            legendPosition: "middle",
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </Card>
    );
  }
}
