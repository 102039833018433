import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SimpleAccordion from "./SimpleAccordian";
import ControlledAccordions from "./ControlledAccordian";
import CustomizedAccordions from "./CustomisedAccordian";
import ActionsInAccordionSummary from "./Additional";
// import Draggable from "react-draggable";
class Accordian extends React.Component {
  eventLogger = (e: MouseEvent, data: Object) => {
    console.log("Event: ", e);
    console.log("Data: ", data);
  };

  render() {
    return (
      <Container>
        <Row>
          <Col md={6} className="mt-3">
            <SimpleAccordion />
          </Col>
          <Col md={6} className="mt-3">
            <ControlledAccordions />
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mt-3">
            <CustomizedAccordions />
          </Col>
          <Col md={6} className="mt-3">
            <ActionsInAccordionSummary />
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Accordian;
