import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import { Button } from "react-bootstrap";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import WorkIcon from "@material-ui/icons/Work";
import Avatar from "@material-ui/core/Avatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { pink } from "@material-ui/core/colors";
import { Card } from "react-bootstrap";
import MusicNoteSharpIcon from "@material-ui/icons/MusicNoteSharp";

class Room5 extends React.Component {
  constructor() {
    super();
    this.state = {
      isLit: true,
      isOn: true,
    };
  }

  flipLight = () => {
    this.setState({
      isLit: !this.state.isLit,
    });
  };

  turnOn = () => {
    this.setState({
      isOn: this.state.isOn,
    });
  };

  turnOff = () => {
    this.setState({
      isOn: !this.state.isOn,
    });
  };

  render() {
    const bright = this.state.isLit ? "ON" : "OFF";
    console.log(this.state.isOn);

    return (
      <div className={`room ${bright}`}>
         <Card style={{height:90}}>
          <Grid container spacing={2}  onClick={this.flipLight}>
            <Button
              variant="outline-dark"
              onClick={this.flipLight}
              style={{
                marginTop: 14,
                marginLeft: 12,
                marginBottom: 10,
                width: 80,
                height: 80,
                backgroundColor: "#FF69B4",
              }}
            >
              <MusicNoteSharpIcon
                style={{ width: 60, height: 60, padding: 2 }}
              />
            </Button>
            
              <Grid>
                <Typography gutterBottom variant="subtitle1" className="card-text">
                  <h4 style={{ marginLeft: 20, marginTop: 20 }}>
                    Wireless Audio
                  </h4>
                </Typography>
                <Typography gutterBottom variant="subtitle1" className="card-text">
                  {this.state.isLit ? (
                    <h6 style={{ marginLeft: 20}}>ON</h6>
                  ) : (
                    <h6 style={{ marginLeft: 20}}>OFF</h6>
                  )}
                </Typography>
              </Grid>
            
          </Grid>
        </Card>
      </div>
    );
  }
}
export default Room5;
