import React, { Component } from "react";
import { ResponsivePie } from "@nivo/pie";
import { data } from "./PieData";
import { Card } from "react-bootstrap";

export default class PieChart extends Component {
  render() {
    return (
      <Card className="d3-charts" style={{ height: "462px" }}>
        <h5
          className="heading"
          style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
        >
          Pie
        </h5>
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <ResponsivePie
          data={data}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          padAngle={0.7}
          cornerRadius={3}
          colors={["#ffc94d", "#42aaff", "#ff708d", "#2ce69b", "#598bff"]}
          borderWidth={1}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          radialLabelsSkipAngle={10}
          radialLabelsTextXOffset={6}
          radialLabelsTextColor="#333333"
          radialLabelsLinkOffset={0}
          radialLabelsLinkDiagonalLength={16}
          radialLabelsLinkHorizontalLength={24}
          radialLabelsLinkStrokeWidth={1}
          radialLabelsLinkColor={{ from: "color" }}
          slicesLabelsSkipAngle={10}
          slicesLabelsTextColor="#333333"
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              translateY: 56,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#999",
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
        />
      </Card>
    );
  }
}
