import React, { useState } from "react";
import DatePicker from "react-date-picker";
import { Card } from "react-bootstrap";

export default function FormDatePicker() {
  const [value, onChange] = useState(new Date());

  return (
    <Card>
      <h5
        className="heading"
        style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
      >
        Common Datepicker
      </h5>
      <hr style={{ marginTop: 0, marginBottom: 0 }} />
      <DatePicker
        calendarClassName="list"
        clearIcon={null}
        calendarIcon={null}
        className="formpicker"
        onChange={onChange}
        value={value}
      />
    </Card>
  );
}
