import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { Card, Row, Col, Button, Modal } from "react-bootstrap";
import config from "../../config.json";
import EditAnimationParamForm from "./EditAnimationParamForm";
import CreateAnimationParamForm from "./CreateAnimationParamForm";
import HMRGrid from "../childcomponents/Grid/HMRGrid";
const Animations = () => {
  const [rowData, setRowData] = useState({ data: [] });
  const [Books, setBooks] = useState({ options: [] });
  const [BookPage, setBookPage] = useState({ options: [] });
  const [Animation, setAnimation] = useState({ options: [] });
  const [showCreate, setShowCreate] = useState(false);

  const [selectedBook, setSelectedBook] = useState({
    label: "Select Book",
    value: "",
  });
  const [selectedBookPage, setSelectedBookPage] = useState({
    label: "Select Book Page",
    value: "",
  });
  const [selectedAnim, setSelectedAnim] = useState({
    label: "Select Animation",
    value: "",
  });
  const [isUpdated, setIsUpdated] = useState(false);
  const ServerPath = config.rapidServerPath;
  const token = localStorage.getItem("Token");

  const [showUpdate, setShowUpdate] = useState(false);
  const [selectedAnimation, setSelectedAnimation] = useState({ data: [] });

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(ServerPath + "/api/books/getBooks", {
        headers: {
          Authorization: token,
          Apikey: "XYZ",
        },
      });
      let options = [];
      response.data.map((book) => {
        options.push({ label: book.Name, value: book.BookID });
      });
      setBooks({ options: options });

      setIsUpdated(false);
    }
    fetchData();
  }, [isUpdated]);

  // const refreshoptions= async ()=>{
  //  //TODO refresh the book pages to "select book page" on selecting the book 2nd time
  //  const options=[]
  // }
  const handleBookSelectChange = async (selected) => {
    setSelectedBook(selected);
    // console.log(selected);

    const response = await axios.get(
      ServerPath + "/api/bookpage/getBookPagesOnBookID/" + selected.value,
      {
        headers: {
          Authorization: token,
          Apikey: "XYZ",
        },
      }
    );
    const options = [];

    response.data.map((selectedBook) => {
      options.push({
        label: selectedBook.PageName,
        value: selectedBook.BookPageID,
      });
    });
    setBookPage({ options: options });
    // console.log(options);
  };

  // const selectedPage = selectedBookPage;
  // console.log(selectedPage);
  // const handleAnimationChange = async (selectedPage) => {
  //   setSelectedBookPage(selectedPage);
  //   //loadAnimation(selectedPage.value);
  // };

  const handlePageSelectChange = async (selected) => {
    setSelectedBookPage(selected);
    // console.log(selectedBookPage)
    // console.log(selected);
    const response = await axios.get(
      ServerPath + "/api/pagecontent/getPageAnimations/" + selected.value,
      {
        headers: {
          Authorization: token,
          Apikey: "XYZ",
        },
      }
    );
    const options = [];

    response.data.map((selectedBook) => {
      options.push({
        label: selectedBook.Name,
        value: selectedBook.AnimationID,
      });
    });
    setAnimation({ options: options });
    // console.log(options);
  };

  const handleAnimationSelectChange = async (selected) => {
    // console.log(selected);
    setSelectedAnim(selected);
    loadTable(selected.value);
  };

  const loadTable = async (AnimationID) => {
    const response = await axios.get(
      ServerPath + "/api/pagecontent/getAnimationParm/" + AnimationID,
      {
        headers: {
          //  Authorization: token,
          Apikey: "XYZ",
        },
      }
    );
    setRowData({ data: response.data });
  };

  const gridOptions = {
    columnDefs: [
      {
        field: "Change field",
        cellRendererFramework: function (params) {
          return (
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleUpdateOpen(params.data)}
            >
              Edit
            </Button>
          );
        },
        width: 100,
        resizable: true,
      },
      {
        field: "AnimParamID",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "AnimationID",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "AnimationType",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "ParamName",
        width: 200,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "ParamValue",
        width: 200,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "ImageName",
        width: 200,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "ImageURL",
        width: 200,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "isAudio",
        width: 200,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "AudioFilePath",
        width: 200,
        sortable: true,
        filter: true,
        resizable: true,
      },
    ],
  };

  const handleUpdateOpen = (data) => {
    // console.log("selected Animation--  ", data);
    setSelectedAnimation({ data: data });
    setShowUpdate(true);
  };

  const handleUpdateClose = () => {
    setShowUpdate(false);
    loadTable(selectedAnim.value);
  };

  const handleCreateOpen = () => {
    setShowCreate(true);
    // console.log(selectedAnim);
  };
  const handleCreateClose = () => {
    setShowCreate(false);
    loadTable(selectedAnim.value);
  };

  return (
    <Row>
      <Col md={12} lg={12} style={{ marginBottom: 20 }}>
        <Card style={{ height: "auto", width: "auto" }}>
          <div style={{ display: "flex" }}>
            <h5 className="heading">Animation Parameters Details</h5>
            <>
              <div
                style={{
                  width: "200px",
                  marginLeft: "auto",
                  marginRight: "3%",
                  marginTop: 4,
                }}
              >
                <Select
                  styles={styles}
                  isSearchable={false}
                  options={Books.options}
                  value={selectedBook}
                  onChange={handleBookSelectChange}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: ".25rem",
                    colors: {
                      ...theme.colors,
                      primary25: "#adb5bd",
                      primary: "#007bff",
                    },
                  })}
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </div>

              <div
                style={{
                  width: "200px",
                  marginRight: "3%",
                  marginTop: 4,
                }}
              >
                <Select
                  styles={styles}
                  isSearchable={false}
                  options={BookPage.options}
                  value={selectedBookPage}
                  onChange={handlePageSelectChange}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: ".25rem",
                    colors: {
                      ...theme.colors,
                      primary25: "#adb5bd",
                      primary: "#007bff",
                    },
                  })}
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </div>
              <div
                style={{
                  width: "200px",
                  marginLeft: "auto",
                  marginRight: "3%",
                  marginTop: 4,
                }}
              >
                <Select
                  styles={styles}
                  isSearchable={false}
                  options={Animation.options}
                  value={selectedAnim}
                  onChange={handleAnimationSelectChange}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: ".25rem",
                    colors: {
                      ...theme.colors,
                      primary25: "#adb5bd",
                      primary: "#007bff",
                    },
                  })}
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </div>
              <div
                style={{
                  marginRight: "3%",
                  marginTop: 10,
                }}
              >
                {selectedAnim.value === "" ? null : (
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={handleCreateOpen}
                  >
                    Create Animation Parameters
                  </Button>
                )}
              </div>
              <Modal
                show={showCreate}
                onHide={handleCreateClose}
                backdrop="static"
                keyboard={false}
                supportedorientations={["portrait", "landscape"]}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Create Animation Param</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <CreateAnimationParamForm
                    Animation={selectedAnim}
                    sendUpdate={setIsUpdated}
                    closeModel={handleCreateClose}
                  />
                </Modal.Body>
              </Modal>
            </>
          </div>
          <Modal
            show={showUpdate}
            onHide={handleUpdateClose}
            backdrop="static"
            keyboard={false}
            supportedorientations={["portrait", "landscape"]}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Animation Params</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EditAnimationParamForm
                Animation={selectedAnimation.data}
                sendUpdate={setIsUpdated}
                closeModel={handleUpdateClose}
              />
            </Modal.Body>
          </Modal>
        </Card>
        <HMRGrid rowData={rowData.data} gridOptions={gridOptions} />
      </Col>
    </Row>
  );
};

export default Animations;
const styles = {
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "15px",
    },
  }),
};
