import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SimpleList from "./SimpleList";
import NestedList from "./NestedList";
import FolderList from "./FolderList";
import InteractiveList from "./InteractiveList";
import AlignItemsList from "./AlignItemsList";
export default function Lists() {
  return (
    <Container>
      <Row style={{ marginTop: "20px" }}>
        <Col md={4} style={{ marginTop: "20px" }}>
          <SimpleList />
        </Col>
        <Col md={4} style={{ marginTop: "20px" }}>
          <NestedList />
        </Col>
        <Col md={4} style={{ marginTop: "20px" }}>
          <FolderList />
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col md={8}>
          <InteractiveList />
        </Col>
        <Col md={4} style={{ marginTop: "20px" }}>
          <AlignItemsList />
        </Col>
      </Row>
    </Container>
  );
}
