import React, { Component } from "react";
import { ResponsiveNetwork } from "@nivo/network";
import { data } from "./NetworkData";
import { Popover, OverlayTrigger, Card } from "react-bootstrap";

export default class NetworkChart extends Component {
  render() {
    return (
      <Card style={{ height: "462px" }}>
        <OverlayTrigger
          trigger="hover"
          key="left"
          placement="left"
          overlay={
            <Popover id={`popover-positioned-`}>
              <Popover.Title as="h3"></Popover.Title>
              <Popover.Content>
                <Card className="text-center">
                  <Card.Header>Network Chart</Card.Header>
                  <Card.Body>
                    <Card.Title>About</Card.Title>
                    <Card.Text>
                      There is a central node to which child nodes are connected
                      wo which end nodes are connected. At each level the size
                      of the nodes become smaller
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Popover.Content>
            </Popover>
          }
        >
          <h5
            className="heading"
            style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
          >
            Network
          </h5>
        </OverlayTrigger>
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <ResponsiveNetwork
          data={data}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          repulsivity={6}
          iterations={60}
          nodes={data.nodes}
          nodeColor={function (t) {
            return t.color;
          }}
          links={data.links}
          nodeBorderWidth={1}
          nodeBorderColor={{ from: "color", modifiers: [["darker", 0.8]] }}
          linkThickness={function (t) {
            return 2 * (2 - t.source.depth);
          }}
          motionStiffness={160}
          motionDamping={12}
        />
      </Card>
    );
  }
}
