import React, { Component, useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";

function Window() {
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Container fluid>
      <Row style={{ justifyContent: "space-around" }}>
        <Col className="modal-card-left" md={5}>
          <>
            <p className="modal-para">
              A modal with header, body, and set of actions in the footer. Use
              which you can use to build the Modal content.
            </p>
            <Button
              className="modal-button"
              variant="primary"
              onClick={handleShow}
            >
              Launch demo modal
            </Button>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Woohoo, you're reading this text in a modal!
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        </Col>
        <Col className="modal-card-right" md={5}>
          <>
            <p className="modal-para">
              When backdrop is set to static, the modal will not close when
              clicking outside it. Click the button below to try it.
            </p>

            <Button
              className="modal-button"
              variant="primary"
              onClick={handleShow}
            >
              Launch static backdrop modal
            </Button>

            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Modal title</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                I will not close if you click outside me. Don't even try to
                press escape key.
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary">Understood</Button>
              </Modal.Footer>
            </Modal>
          </>
        </Col>
      </Row>
      <Row style={{ justifyContent: "space-around" }}>
        <Col md={5} className="modal-card-next-right">
          <>
            <p className="modal-para">
              You can specify a bootstrap large or small modal by using the
              "size" prop..
            </p>
            <Button className="modal-button" onClick={() => setSmShow(true)}>
              Small modal
            </Button>{" "}
            <Button className="modal-button" onClick={() => setLgShow(true)}>
              Large modal
            </Button>
            <Modal
              size="sm"
              show={smShow}
              onHide={() => setSmShow(false)}
              aria-labelledby="example-modal-sizes-title-sm"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">
                  Small Modal
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>...</Modal.Body>
            </Modal>
            <Modal
              size="lg"
              show={lgShow}
              onHide={() => setLgShow(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  Large Modal
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>...</Modal.Body>
            </Modal>
          </>
        </Col>
        <Col md={5} className="modal-card-next-left">
          <>
            <p>
              You can apply custom css to the modal dialog div using the
              "dialogClassName" prop. Example is using a custom css class{" "}
            </p>
            <Button
              className="modal-button"
              variant="primary"
              onClick={() => setShow(true)}
            >
              Custom Width Modal
            </Button>

            <Modal
              show={show}
              onHide={() => setShow(false)}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Custom Modal Styling
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Ipsum molestiae natus adipisci modi eligendi? Debitis amet
                  quae unde commodi aspernatur enim, consectetur. Cumque
                  deleniti temporibus ipsam atque a dolores quisquam quisquam
                  adipisci possimus laboriosam. Quibusdam facilis doloribus
                  debitis! Sit quasi quod
                </p>
              </Modal.Body>
            </Modal>
          </>
        </Col>
      </Row>
    </Container>
  );
}
export default Window;
