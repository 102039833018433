export default class Singleton {
  static myInstance = null;
  UserTypes = [];
  LoggedInUserTypeID = 0;

  static getInstance() {
    if (Singleton.myInstance == null) {
      Singleton.myInstance = new Singleton();
    }
    return this.myInstance;
  }

  getLoggedInUserTypeID() {
    return this.LoggedInUserTypeID;
  }

  setLoggedInUserTypeID(id) {
    this.LoggedInUserTypeID = id;
  }

  getUserTypes() {
    return this.UserTypes;
  }

  setUserTypes(data) {
    this.UserTypes = data;
  }
}
