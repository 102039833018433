import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
// import echarts from "echarts";
import { Card } from "react-bootstrap";

export default class SankeyChart extends Component {
  state = {
    option: {
      backgroundColor: "#FFFFFF",
      series: [
        {
          type: "sankey",
          left: 50.0,
          top: 20.0,
          right: 150.0,
          bottom: 25.0,
          data: [
            {
              name: "Werne",
              itemStyle: {
                normal: {
                  color: "#f18bbf",
                  borderColor: "#f18bbf",
                },
              },
            },
            {
              name: "Duesseldorf",
              itemStyle: {
                normal: {
                  color: "#0078D7",
                  borderColor: "#0078D7",
                },
              },
            },
            {
              name: "Cambridge",
              itemStyle: {
                normal: {
                  color: "#3891A7",
                  borderColor: "#3891A7",
                },
              },
            },
            {
              name: "Colma",
              itemStyle: {
                normal: {
                  color: "#0037DA",
                  borderColor: "#0037DA",
                },
              },
            },
            {
              name: "W. York",
              itemStyle: {
                normal: {
                  color: "#C0BEAF",
                  borderColor: "#C0BEAF",
                },
              },
            },
            {
              name: "Frankfurt am Main",
              itemStyle: {
                normal: {
                  color: "#EA005E",
                  borderColor: "#EA005E",
                },
              },
            },
            {
              name: "Metz",
              itemStyle: {
                normal: {
                  color: "#D13438",
                  borderColor: "#D13438",
                },
              },
            },
            {
              name: "Orleans",
              itemStyle: {
                normal: {
                  color: "#567C73",
                  borderColor: "#567C73",
                },
              },
            },
            {
              name: "Saint-Denis",
              itemStyle: {
                normal: {
                  color: "#9ed566",
                  borderColor: "#9ed566",
                },
              },
            },
            {
              name: "Hof",
              itemStyle: {
                normal: {
                  color: "#2BCC7F",
                  borderColor: "#2BCC7F",
                },
              },
            },
            {
              name: "Cliffside",
              itemStyle: {
                normal: {
                  color: "#809B48",
                  borderColor: "#809B48",
                },
              },
            },
            {
              name: "Leeds",
              itemStyle: {
                normal: {
                  color: "#9B2D1F",
                  borderColor: "#9B2D1F",
                },
              },
            },
            {
              name: "Victoria",
              itemStyle: {
                normal: {
                  color: "#604878",
                  borderColor: "#604878",
                },
              },
            },
            {
              name: "Erlangen",
              itemStyle: {
                normal: {
                  color: "#A5644E",
                  borderColor: "#A5644E",
                },
              },
            },
            {
              name: "Saint Germain en Laye",
              itemStyle: {
                normal: {
                  color: "#2D3F3A",
                  borderColor: "#2D3F3A",
                },
              },
            },
            {
              name: "Roissy en Brie",
              itemStyle: {
                normal: {
                  color: "#761721",
                  borderColor: "#761721",
                },
              },
            },
            {
              name: "Wokingham",
              itemStyle: {
                normal: {
                  color: "#B1BADD",
                  borderColor: "#B1BADD",
                },
              },
            },
            {
              name: "Runcorn",
              itemStyle: {
                normal: {
                  color: "#B0CCB0",
                  borderColor: "#B0CCB0",
                },
              },
            },
            {
              name: "Newton",
              itemStyle: {
                normal: {
                  color: "#8164A3",
                  borderColor: "#8164A3",
                },
              },
            },
            {
              name: "Morangis",
              itemStyle: {
                normal: {
                  color: "#8E562E",
                  borderColor: "#8E562E",
                },
              },
            },
            {
              name: "Metchosin",
              itemStyle: {
                normal: {
                  color: "#C1504D",
                  borderColor: "#C1504D",
                },
              },
            },
            {
              name: "Kirkby",
              itemStyle: {
                normal: {
                  color: "#CCAF0A",
                  borderColor: "#CCAF0A",
                },
              },
            },
            {
              name: "London",
              itemStyle: {
                normal: {
                  color: "#956251",
                  borderColor: "#956251",
                },
              },
            },
            {
              name: "Offenbach",
              itemStyle: {
                normal: {
                  color: "#C17529",
                  borderColor: "#C17529",
                },
              },
            },
            {
              name: "Warrington",
              itemStyle: {
                normal: {
                  color: "#CEC597",
                  borderColor: "#CEC597",
                },
              },
            },
            {
              name: "Vancouver",
              itemStyle: {
                normal: {
                  color: "#9F2936",
                  borderColor: "#9F2936",
                },
              },
            },
            {
              name: "SuperiorCard",
              itemStyle: {
                normal: {
                  color: "rgba(128,155,72,255)",
                  borderColor: "rgba(128,155,72,255)",
                },
              },
            },
            {
              name: "Lille",
              itemStyle: {
                normal: {
                  color: "#ac7430",
                  borderColor: "#ac7430",
                },
              },
            },
            {
              name: "Hamburg",
              itemStyle: {
                normal: {
                  color: "#00BCF2",
                  borderColor: "#00BCF2",
                },
              },
            },
            {
              name: "Langley",
              itemStyle: {
                normal: {
                  color: "#CD7B38",
                  borderColor: "#CD7B38",
                },
              },
            },
            {
              name: "Les Ulis",
              itemStyle: {
                normal: {
                  color: "#424242",
                  borderColor: "#424242",
                },
              },
            },
            {
              name: "Saarbrücken",
              itemStyle: {
                normal: {
                  color: "#f63185",
                  borderColor: "#f63185",
                },
              },
            },
            {
              name: "N. Vancouver",
              itemStyle: {
                normal: {
                  color: "#9CBC59",
                  borderColor: "#9CBC59",
                },
              },
            },
            {
              name: "Chalk Riber",
              itemStyle: {
                normal: {
                  color: "#4F4BD9",
                  borderColor: "#4F4BD9",
                },
              },
            },
            {
              name: "Esher-Molesey",
              itemStyle: {
                normal: {
                  color: "#3EC562",
                  borderColor: "#3EC562",
                },
              },
            },
            {
              name: "Chatou",
              itemStyle: {
                normal: {
                  color: "#F06F2E",
                  borderColor: "#F06F2E",
                },
              },
            },
            {
              name: "Hannover",
              itemStyle: {
                normal: {
                  color: "#C3986D",
                  borderColor: "#C3986D",
                },
              },
            },
            {
              name: "Roncq",
              itemStyle: {
                normal: {
                  color: "#4D291C",
                  borderColor: "#4D291C",
                },
              },
            },
            {
              name: "Ingolstadt",
              itemStyle: {
                normal: {
                  color: "#009c7a",
                  borderColor: "#009c7a",
                },
              },
            },
            {
              name: "Drancy",
              itemStyle: {
                normal: {
                  color: "#986F0B",
                  borderColor: "#986F0B",
                },
              },
            },
            {
              name: "Langford",
              itemStyle: {
                normal: {
                  color: "#3C8EA4",
                  borderColor: "#3C8EA4",
                },
              },
            },
            {
              name: "Lebanon",
              itemStyle: {
                normal: {
                  color: "#4F82BE",
                  borderColor: "#4F82BE",
                },
              },
            },
            {
              name: "Maidenhead",
              itemStyle: {
                normal: {
                  color: "#D38017",
                  borderColor: "#D38017",
                },
              },
            },
            {
              name: "Stoke-on-Trent",
              itemStyle: {
                normal: {
                  color: "#A8CDD7",
                  borderColor: "#A8CDD7",
                },
              },
            },
            {
              name: "Peterborough",
              itemStyle: {
                normal: {
                  color: "#7A072D",
                  borderColor: "#7A072D",
                },
              },
            },
            {
              name: "Suresnes",
              itemStyle: {
                normal: {
                  color: "#859599",
                  borderColor: "#859599",
                },
              },
            },
            {
              name: "Versailles",
              itemStyle: {
                normal: {
                  color: "#84AA33",
                  borderColor: "#84AA33",
                },
              },
            },
            {
              name: "Neunkirchen",
              itemStyle: {
                normal: {
                  color: "#ff8b67",
                  borderColor: "#ff8b67",
                },
              },
            },
            {
              name: "Vista",
              itemStyle: {
                normal: {
                  color: "rgba(106,82,134,255)",
                  borderColor: "rgba(106,82,134,255)",
                },
              },
            },
            {
              name: "Westminster",
              itemStyle: {
                normal: {
                  color: "#1B587C",
                  borderColor: "#1B587C",
                },
              },
            },
            {
              name: "Kiel",
              itemStyle: {
                normal: {
                  color: "#A19574",
                  borderColor: "#A19574",
                },
              },
            },
            {
              name: "Newcastle upon Tyne",
              itemStyle: {
                normal: {
                  color: "#918485",
                  borderColor: "#918485",
                },
              },
            },
            {
              name: "Oxon",
              itemStyle: {
                normal: {
                  color: "#FFA98C",
                  borderColor: "#FFA98C",
                },
              },
            },
            {
              name: "West Sussex",
              itemStyle: {
                normal: {
                  color: "#B0E3C0",
                  borderColor: "#B0E3C0",
                },
              },
            },
            {
              name: "Oak Bay",
              itemStyle: {
                normal: {
                  color: "#4BADC7",
                  borderColor: "#4BADC7",
                },
              },
            },
            {
              name: "Milton Keynes",
              itemStyle: {
                normal: {
                  color: "#BA144C",
                  borderColor: "#BA144C",
                },
              },
            },
            {
              name: "Eilenburg",
              itemStyle: {
                normal: {
                  color: "#F0A22E",
                  borderColor: "#F0A22E",
                },
              },
            },
            {
              name: "ColonialVoice",
              itemStyle: {
                normal: {
                  color: "rgba(64,105,157,255)",
                  borderColor: "rgba(64,105,157,255)",
                },
              },
            },
            {
              name: "Liverpool",
              itemStyle: {
                normal: {
                  color: "#A28E6A",
                  borderColor: "#A28E6A",
                },
              },
            },
            {
              name: "Calgary",
              itemStyle: {
                normal: {
                  color: "#9F413E",
                  borderColor: "#9F413E",
                },
              },
            },
            {
              name: "CAD",
              itemStyle: {
                normal: {
                  color: "#40699D",
                  borderColor: "#40699D",
                },
              },
            },
            {
              name: "Paris La Defense",
              itemStyle: {
                normal: {
                  color: "#989391",
                  borderColor: "#989391",
                },
              },
            },
            {
              name: "Villeneuve-d'Ascq",
              itemStyle: {
                normal: {
                  color: "#886CE4",
                  borderColor: "#886CE4",
                },
              },
            },
            {
              name: "Gloucestershire",
              itemStyle: {
                normal: {
                  color: "#964305",
                  borderColor: "#964305",
                },
              },
            },
            {
              name: "Gateshead",
              itemStyle: {
                normal: {
                  color: "#485FB5",
                  borderColor: "#485FB5",
                },
              },
            },
            {
              name: "Salzgitter",
              itemStyle: {
                normal: {
                  color: "#87a0c7",
                  borderColor: "#87a0c7",
                },
              },
            },
            {
              name: "Woolston",
              itemStyle: {
                normal: {
                  color: "#FFE2C5",
                  borderColor: "#FFE2C5",
                },
              },
            },
            {
              name: "Frankfurt",
              itemStyle: {
                normal: {
                  color: "#40699D",
                  borderColor: "#40699D",
                },
              },
            },
            {
              name: "Münster",
              itemStyle: {
                normal: {
                  color: "#7e7eb2",
                  borderColor: "#7e7eb2",
                },
              },
            },
            {
              name: "York",
              itemStyle: {
                normal: {
                  color: "#587C7D",
                  borderColor: "#587C7D",
                },
              },
            },
            {
              name: "High Wycombe",
              itemStyle: {
                normal: {
                  color: "#F07F09",
                  borderColor: "#F07F09",
                },
              },
            },
            {
              name: "Stuttgart",
              itemStyle: {
                normal: {
                  color: "#E3008C",
                  borderColor: "#E3008C",
                },
              },
            },
            {
              name: "Sooke",
              itemStyle: {
                normal: {
                  color: "#4E8542",
                  borderColor: "#4E8542",
                },
              },
            },
            {
              name: "Essen",
              itemStyle: {
                normal: {
                  color: "#B58B80",
                  borderColor: "#B58B80",
                },
              },
            },
            {
              name: "München",
              itemStyle: {
                normal: {
                  color: "#4dc0a6",
                  borderColor: "#4dc0a6",
                },
              },
            },
            {
              name: "Haney",
              itemStyle: {
                normal: {
                  color: "#6A5286",
                  borderColor: "#6A5286",
                },
              },
            },
            {
              name: "Port Hammond",
              itemStyle: {
                normal: {
                  color: "#F89746",
                  borderColor: "#F89746",
                },
              },
            },
            {
              name: "Saint Ouen",
              itemStyle: {
                normal: {
                  color: "#744DA9",
                  borderColor: "#744DA9",
                },
              },
            },
            {
              name: "Watford",
              itemStyle: {
                normal: {
                  color: "#E8B7B7",
                  borderColor: "#E8B7B7",
                },
              },
            },
            {
              name: "GBP",
              itemStyle: {
                normal: {
                  color: "#C32D2E",
                  borderColor: "#C32D2E",
                },
              },
            },
            {
              name: "Paderborn",
              itemStyle: {
                normal: {
                  color: "#F0C42E",
                  borderColor: "#F0C42E",
                },
              },
            },
            {
              name: "Dunkerque",
              itemStyle: {
                normal: {
                  color: "#881798",
                  borderColor: "#881798",
                },
              },
            },
            {
              name: "Colomiers",
              itemStyle: {
                normal: {
                  color: "#efa835",
                  borderColor: "#efa835",
                },
              },
            },
            {
              name: "Oxford",
              itemStyle: {
                normal: {
                  color: "#D8B25C",
                  borderColor: "#D8B25C",
                },
              },
            },
            {
              name: "Bury",
              itemStyle: {
                normal: {
                  color: "#FEB80A",
                  borderColor: "#FEB80A",
                },
              },
            },
            {
              name: "Royal Oak",
              itemStyle: {
                normal: {
                  color: "#009DD9",
                  borderColor: "#009DD9",
                },
              },
            },
            {
              name: "Shawnee",
              itemStyle: {
                normal: {
                  color: "#F07F09",
                  borderColor: "#F07F09",
                },
              },
            },
            {
              name: "Lancaster",
              itemStyle: {
                normal: {
                  color: "#D34817",
                  borderColor: "#D34817",
                },
              },
            },
            {
              name: "DEM",
              itemStyle: {
                normal: {
                  color: "#4E342E",
                  borderColor: "#4E342E",
                },
              },
            },
            {
              name: "Grevenbroich",
              itemStyle: {
                normal: {
                  color: "#FFA836",
                  borderColor: "#FFA836",
                },
              },
            },
            {
              name: "Distinguish",
              itemStyle: {
                normal: {
                  color: "rgba(159,65,62,255)",
                  borderColor: "rgba(159,65,62,255)",
                },
              },
            },
            {
              name: "Cheltenham",
              itemStyle: {
                normal: {
                  color: "#FF6551",
                  borderColor: "#FF6551",
                },
              },
            },
            {
              name: "Reading",
              itemStyle: {
                normal: {
                  color: "#72A376",
                  borderColor: "#72A376",
                },
              },
            },
            {
              name: "Pantin",
              itemStyle: {
                normal: {
                  color: "#69797E",
                  borderColor: "#69797E",
                },
              },
            },
            {
              name: "Kassel",
              itemStyle: {
                normal: {
                  color: "#e65e20",
                  borderColor: "#e65e20",
                },
              },
            },
            {
              name: "Orly",
              itemStyle: {
                normal: {
                  color: "#6E6A68",
                  borderColor: "#6E6A68",
                },
              },
            },
            {
              name: "FRF",
              itemStyle: {
                normal: {
                  color: "#5ba33b",
                  borderColor: "#5ba33b",
                },
              },
            },
            {
              name: "Cergy",
              itemStyle: {
                normal: {
                  color: "#B4009E",
                  borderColor: "#B4009E",
                },
              },
            },
            {
              name: "Paris",
              itemStyle: {
                normal: {
                  color: "#666666",
                  borderColor: "#666666",
                },
              },
            },
          ],
          links: [
            {
              source: "FRF",
              target: "Colomiers",
              value: 357.8399963378906,
            },
            {
              source: "SuperiorCard",
              target: "FRF",
              value: 894.5999908447266,
            },
            {
              source: "DEM",
              target: "München",
              value: 178.9199981689453,
            },
            {
              source: "GBP",
              target: "Reading",
              value: 188.52999836206436,
            },
            {
              source: "CAD",
              target: "Shawnee",
              value: 2346.919983509928,
            },
            {
              source: "GBP",
              target: "Kirkby",
              value: 753.4000000059605,
            },
            {
              source: "FRF",
              target: "Roncq",
              value: 178.9199981689453,
            },
            {
              source: "GBP",
              target: "Peterborough",
              value: 999.159998036921,
            },
            {
              source: "DEM",
              target: "Frankfurt am Main",
              value: 536.7599945068359,
            },
            {
              source: "GBP",
              target: "Oxford",
              value: 1831.3799968883395,
            },
            {
              source: "Vista",
              target: "FRF",
              value: 1789.1999816894531,
            },
            {
              source: "CAD",
              target: "Langley",
              value: 1274.8199949413538,
            },
            {
              source: "DEM",
              target: "Offenbach",
              value: 357.8399963378906,
            },
            {
              source: "FRF",
              target: "Villeneuve-d'Ascq",
              value: 178.9199981689453,
            },
            {
              source: "FRF",
              target: "Dunkerque",
              value: 357.8399963378906,
            },
            {
              source: "DEM",
              target: "Eilenburg",
              value: 178.9199981689453,
            },
            {
              source: "FRF",
              target: "Paris",
              value: 1073.5199890136719,
            },
            {
              source: "GBP",
              target: "Maidenhead",
              value: 549.8400026857853,
            },
            {
              source: "CAD",
              target: "Sooke",
              value: 1764.499989286065,
            },
            {
              source: "CAD",
              target: "Vancouver",
              value: 1528.580000281334,
            },
            {
              source: "DEM",
              target: "Hamburg",
              value: 357.8399963378906,
            },
            {
              source: "GBP",
              target: "London",
              value: 8619.309983983636,
            },
            {
              source: "CAD",
              target: "Oak Bay",
              value: 1565.109990529716,
            },
            {
              source: "Distinguish",
              target: "FRF",
              value: 2683.7999725341797,
            },
            {
              source: "DEM",
              target: "Neunkirchen",
              value: 178.9199981689453,
            },
            {
              source: "FRF",
              target: "Cergy",
              value: 178.9199981689453,
            },
            {
              source: "DEM",
              target: "Hof",
              value: 357.8399963378906,
            },
            {
              source: "FRF",
              target: "Paris La Defense",
              value: 178.9199981689453,
            },
            {
              source: "CAD",
              target: "Westminster",
              value: 1149.7999994903803,
            },
            {
              source: "DEM",
              target: "Ingolstadt",
              value: 536.7599945068359,
            },
            {
              source: "GBP",
              target: "Saint Ouen",
              value: 0.5899999737739563,
            },
            {
              source: "FRF",
              target: "Lille",
              value: 357.8399963378906,
            },
            {
              source: "GBP",
              target: "Leeds",
              value: 1356.6899970173836,
            },
            {
              source: "FRF",
              target: "Morangis",
              value: 357.8399963378906,
            },
            {
              source: "GBP",
              target: "Orly",
              value: 0.5899999737739563,
            },
            {
              source: "SuperiorCard",
              target: "DEM",
              value: 1431.3599853515625,
            },
            {
              source: "Vista",
              target: "CAD",
              value: 5369.929964579642,
            },
            {
              source: "GBP",
              target: "Paris",
              value: 0.6399999856948853,
            },
            {
              source: "GBP",
              target: "Liverpool",
              value: 857.1999968588352,
            },
            {
              source: "GBP",
              target: "Stoke-on-Trent",
              value: 1131.7099939212203,
            },
            {
              source: "Distinguish",
              target: "DEM",
              value: 2504.8799743652344,
            },
            {
              source: "CAD",
              target: "Langford",
              value: 2343.4599857851863,
            },
            {
              source: "DEM",
              target: "Kassel",
              value: 536.7599945068359,
            },
            {
              source: "GBP",
              target: "High Wycombe",
              value: 216.83999809622765,
            },
            {
              source: "CAD",
              target: "Port Hammond",
              value: 1711.1399984136224,
            },
            {
              source: "DEM",
              target: "Duesseldorf",
              value: 178.9199981689453,
            },
            {
              source: "GBP",
              target: "Gloucestershire",
              value: 422.28999888151884,
            },
            {
              source: "Distinguish",
              target: "GBP",
              value: 10384.949975416064,
            },
            {
              source: "FRF",
              target: "Roissy en Brie",
              value: 178.9199981689453,
            },
            {
              source: "GBP",
              target: "West Sussex",
              value: 592.1700052022934,
            },
            {
              source: "CAD",
              target: "Cliffside",
              value: 2906.2699892893434,
            },
            {
              source: "GBP",
              target: "Newcastle upon Tyne",
              value: 1448.2899911925197,
            },
            {
              source: "GBP",
              target: "Runcorn",
              value: 1120.4800013303757,
            },
            {
              source: "GBP",
              target: "W. York",
              value: 612.1199932694435,
            },
            {
              source: "DEM",
              target: "Kiel",
              value: 178.9199981689453,
            },
            {
              source: "GBP",
              target: "Woolston",
              value: 833.3199937939644,
            },
            {
              source: "Distinguish",
              target: "CAD",
              value: 6950.059956334531,
            },
            {
              source: "DEM",
              target: "Frankfurt",
              value: 715.6799926757812,
            },
            {
              source: "CAD",
              target: "Colma",
              value: 0.2199999988079071,
            },
            {
              source: "DEM",
              target: "Essen",
              value: 178.9199981689453,
            },
            {
              source: "FRF",
              target: "Chatou",
              value: 178.9199981689453,
            },
            {
              source: "GBP",
              target: "Cheltenham",
              value: 573.0499979257584,
            },
            {
              source: "SuperiorCard",
              target: "GBP",
              value: 8228.39999615401,
            },
            {
              source: "CAD",
              target: "Haney",
              value: 2310.4499812424183,
            },
            {
              source: "FRF",
              target: "Saint Ouen",
              value: 178.9199981689453,
            },
            {
              source: "CAD",
              target: "Chalk Riber",
              value: 0.9200000166893005,
            },
            {
              source: "DEM",
              target: "Salzgitter",
              value: 178.9199981689453,
            },
            {
              source: "ColonialVoice",
              target: "FRF",
              value: 1610.2799835205078,
            },
            {
              source: "DEM",
              target: "Stuttgart",
              value: 357.8399963378906,
            },
            {
              source: "FRF",
              target: "Saint-Denis",
              value: 178.9199981689453,
            },
            {
              source: "CAD",
              target: "Royal Oak",
              value: 2128.459992274642,
            },
            {
              source: "FRF",
              target: "Les Ulis",
              value: 715.6799926757812,
            },
            {
              source: "FRF",
              target: "Drancy",
              value: 178.9199981689453,
            },
            {
              source: "GBP",
              target: "Esher-Molesey",
              value: 911.4700058028102,
            },
            {
              source: "SuperiorCard",
              target: "CAD",
              value: 7388.099954992533,
            },
            {
              source: "GBP",
              target: "Bury",
              value: 903.9400005489588,
            },
            {
              source: "GBP",
              target: "Watford",
              value: 1326.5300009772182,
            },
            {
              source: "CAD",
              target: "Victoria",
              value: 827.3899968340993,
            },
            {
              source: "DEM",
              target: "Saarbrücken",
              value: 178.9199981689453,
            },
            {
              source: "GBP",
              target: "Lancaster",
              value: 685.6899967193604,
            },
            {
              source: "FRF",
              target: "Pantin",
              value: 178.9199981689453,
            },
            {
              source: "CAD",
              target: "Newton",
              value: 1781.909985654056,
            },
            {
              source: "GBP",
              target: "Oxon",
              value: 493.6499986946583,
            },
            {
              source: "CAD",
              target: "Calgary",
              value: 361.3899962902069,
            },
            {
              source: "DEM",
              target: "Münster",
              value: 715.6799926757812,
            },
            {
              source: "DEM",
              target: "Grevenbroich",
              value: 536.7599945068359,
            },
            {
              source: "DEM",
              target: "Paderborn",
              value: 357.8399963378906,
            },
            {
              source: "GBP",
              target: "York",
              value: 3172.9999914616346,
            },
            {
              source: "CAD",
              target: "Metchosin",
              value: 1750.7899813987315,
            },
            {
              source: "FRF",
              target: "Suresnes",
              value: 357.8399963378906,
            },
            {
              source: "FRF",
              target: "Versailles",
              value: 894.5999908447266,
            },
            {
              source: "DEM",
              target: "Erlangen",
              value: 536.7599945068359,
            },
            {
              source: "CAD",
              target: "Lebanon",
              value: 0.8700000047683716,
            },
            {
              source: "GBP",
              target: "Wokingham",
              value: 812.6600027084351,
            },
            {
              source: "GBP",
              target: "Cambridge",
              value: 500.36999743431807,
            },
            {
              source: "ColonialVoice",
              target: "GBP",
              value: 8040.7799860313535,
            },
            {
              source: "FRF",
              target: "Saint Germain en Laye",
              value: 178.9199981689453,
            },
            {
              source: "FRF",
              target: "Metz",
              value: 178.9199981689453,
            },
            {
              source: "FRF",
              target: "Orleans",
              value: 357.8399963378906,
            },
            {
              source: "GBP",
              target: "Milton Keynes",
              value: 1648.2200061008334,
            },
            {
              source: "GBP",
              target: "Warrington",
              value: 2162.8300000429153,
            },
            {
              source: "CAD",
              target: "N. Vancouver",
              value: 1862.4599857926369,
            },
            {
              source: "DEM",
              target: "Hannover",
              value: 178.9199981689453,
            },
            {
              source: "Vista",
              target: "GBP",
              value: 9497.539981640875,
            },
            {
              source: "DEM",
              target: "Werne",
              value: 178.9199981689453,
            },
            {
              source: "ColonialVoice",
              target: "DEM",
              value: 1789.1999816894531,
            },
            {
              source: "ColonialVoice",
              target: "CAD",
              value: 7907.36997512728,
            },
            {
              source: "GBP",
              target: "Gateshead",
              value: 1425.7099913656712,
            },
            {
              source: "Vista",
              target: "DEM",
              value: 1968.1199798583984,
            },
          ],
          lineStyle: {
            color: "source",
            curveness: 0.5,
          },
          itemStyle: {
            color: "#1f77b4",
            borderColor: "#1f77b4",
          },
          label: {
            color: "rgba(0,0,0,0.7)",
            fontFamily: "Arial",
            fontSize: 10,
          },
        },
      ],
      tooltip: {
        trigger: "item",
      },
    },
  };
  render() {
    return (
      <Card className="echart-card">
        <h5
          class="heading"
          style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
        >
          Sankey
        </h5>
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <ReactEcharts className="echarts" option={this.state.option} />
      </Card>
    );
  }
}
