import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
//import FormControl from "@material-ui/core/FormControl";
import "./BookForm.css";
import config from "../../config.json";
import QRCode from "qrcode";
import { useEffect } from "react";

const EditBookForm = (props) => {
  const BookID = props.book.BookID;
  const [Author, setAuthor] = useState(props.book.Author);
  const [Name, setName] = useState(props.book.Name);
  const [ReadCount, setReadCount] = useState(props.book.ReadCount);
  const [PageCount, setPageCount] = useState(props.book.PageCount);
  const [Category, setCategory] = useState(props.book.Category);
  const [AgeGroup, setAgeGroup] = useState(props.book.AgeGroup);
  const [Tags, setTags] = useState(props.book.Tags);
  const [StoryLine, setStoryLine] = useState(props.book.StoryLine);
  const [isPublished, setIsPublished] = useState(
    Boolean(props.book.isPublished)
  );
  const [Language, setLanguage] = useState(props.book.Language);
  const [DemoPageID, setDemoPageID] = useState(props.book.DemoPageID);
  const [ColorPageCount, setColorPageCount] = useState(
    props.book.ColorPageCount
  );
  const [bookImage, setBookImage] = useState({ preview: "", raw: "" });
  var [QRImage, setQRImage] = useState({ preview: "", raw: "" });
  const token = localStorage.getItem("Token");
  const ServerPath = config.rapidServerPath;
  var [QRText, setQRText] = useState(props.book.QRCode);
  var [QRImageUrl, setQRImageUrl] = useState("");

  const createNotificationHandler = (type) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "Details changed successfully",
          "Success",
          3000
        );
        break;
      case "error":
        NotificationManager.error("Something went wrong", "error", 3000);
        break;
      case "ColorPageCount":
        NotificationManager.info(
          "Number of Color Pages should be less than or equal to the Page Count",
          "Info",
          3000
        );
        break;
      default:
        console.log("default");
    }
  };

  const validateColorPageCount = () => {
    if (Number(ColorPageCount) <= Number(PageCount)) {
      return true;
    } else {
      return false;
    }
  };

  async function updateBook(e) {
    e.preventDefault();
    if (!validateColorPageCount()) {
      createNotificationHandler("ColorPageCount");
    } else {
      let bookImagePath = props.book.ImagePath;
      let bookQRCode = props.book.QRCode;
      if (bookImage.raw || QRImage.raw) {
        const form = new FormData();
        if (bookImage.raw) {
          form.append("file", bookImage.raw);
          form.append("imageName", "COVERFRONT.jpg");
          form.append("folderName", "book-photos");
          form.append("uid", props.book.ImagePath);
          await axios
            .post(ServerPath + "/api/file/updateImage", form, {
              headers: { Authorization: token, Apikey: "XYZ" },
            })
            .then((respImage) => {
              bookImagePath = respImage.data.uid;
            });
        }

        if (QRImage.raw) {
          form.append("file", QRImage.raw);
          form.append("imageName", "QRCode.jpg");
          form.append("folderName", "book-photos");
          //form.append("subFolderName", "QRCode");
          form.append("uid", props.book.ImagePath + "/QRCode");
          await axios
            .post(ServerPath + "/api/file/updateImage", form, {
              headers: { Authorization: token, Apikey: "XYZ" },
            })
            .then((responseQR) => {
              bookQRCode = responseQR.data.uid;
            });
        }
      }
      handleUpdate(bookImagePath, bookQRCode);
    }
  }

  async function handleUpdate(bkImagePath, bkQRCode) {
    // console.log("NEW FILES - " + bkImagePath + " and " + bkQRCode);
    try {
      console.log("BEFORE UPDATE IMAGE URL -" + QRImageUrl);

      const response = await axios.put(
        ServerPath + "/api/books/updateBook/" + BookID,
        {
          ImagePath: bkImagePath,
          QRCode: QRText,
          Name: Name,
          PageCount: PageCount,
          Category: Category,
          AgeGroup: AgeGroup,
          Tags: Tags,
          StoryLine: StoryLine,
          DemoPageID: DemoPageID.toUpperCase(),
          isPublished: Number(isPublished),
          Language: Language,
          ColorPageCount: ColorPageCount,
          Author: Author,
          ReadCount: ReadCount,
        },
        {
          headers: {
            Authorization: token,
            Apikey: "XYZ",
          },
        }
      );
      props.sendUpdate(true);
      if (response.data.success === true) {
        createNotificationHandler("success");
      } else {
        createNotificationHandler("error");
      }
      props.closeModel();
    } catch (error) {
      if (error.response) {
        NotificationManager.error(error.response.data.error, "Error", 3000);
        console.log(error);
      }
    }
  }
  const generateQRImage = (val) => {
    if (val.length === 0) {
      setQRText("");
      setQRImageUrl(0);
    } else {
      //console.log("BOX VAL IS - " +val);
      QRCode.toDataURL(val).then((data) => {
        setQRImageUrl(data);
        //  console.log("THIS IS THE QR CODE GENERATED -" + JSON.stringify(data) );
        setQRImage({
          preview: data, //URL.createObjectURL(data),
          raw: data,
        });
        setQRText(val);
        ///console.log("QRTextVAL is - " + QRText);
      });
    }
    return;
  };

  // if (e.target.files) {
  //   setQRImage({
  //     preview: URL.createObjectURL(e.target.files[0]),
  //     raw: e.target.files[0],
  //   });
  // }
  // <input type="file" id="upload-QR-image" style={{ display: "none" }} />

  useEffect(() => {
    generateQRImage(QRText);
  }, []);

  const AssignBookImage = (e) => {
    if (e.target.files.length) {
      setBookImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  return (
    <div className="book-container">
      <div className="content">
        <form onSubmit={(e) => updateBook(e)}>
          <div className="input-group">
            <input
              id="name"
              placeholder="Book Name"
              value={Name}
              onChange={(e) => setName(e.target.value)}
            />
            <label htmlFor="name">Book Name</label>
          </div>

          <label htmlFor="upload-book-image" id="image">
            {bookImage.preview ? (
              <img
                src={bookImage.preview}
                alt="Book preview Here"
                className="form-image"
              />
            ) : (
              <img
                alt="Book Preview Here"
                src={
                  ServerPath +
                  "/api/file/assets/book-photos/" +
                  props.book.ImagePath
                }
                className="form-image"
              />
            )}
          </label>

          <input
            id="upload-book-image"
            style={{ display: "none" }}
            type="file"
            onChange={(e) => AssignBookImage(e)}
          />

          <div className="two-input-group">
            <div className="input-group">
              <input
                placeholder="Page Count"
                id="page-count"
                value={PageCount}
                onChange={(e) => setPageCount(e.target.value)}
              />
              <label htmlFor="page-count">Page Count</label>
            </div>

            <div className="input-group">
              <input
                placeholder="Category"
                id="Category"
                value={Category}
                onChange={(e) => setCategory(e.target.value)}
              />
              <label htmlFor="Category">Category</label>
            </div>
          </div>

          {/* adds the author and read count */}
          <div className="two-input-group">
            <div className="input-group">
              <input
                placeholder="Author"
                id="Author"
                value={Author}
                onChange={(e) => setAuthor(e.target.value)}
              />
              <label htmlFor="Author">Author</label>
            </div>
            <div className="input-group">
              <input
                placeholder="Read Count"
                value={ReadCount}
                id="readcount"
                onChange={(e) => setReadCount(e.target.value)}
              />
              <label htmlFor="readcount">Read Count</label>
            </div>
          </div>
          <div className="two-input-group">
            <div className="input-group">
              <input
                placeholder="Tags"
                id="tags"
                value={Tags}
                onChange={(e) => setTags(e.target.value)}
              />
              <label htmlFor="tags">Tags</label>
            </div>
            <div className="input-group">
              <input
                placeholder="Age Group"
                id="agegroup"
                value={AgeGroup}
                onChange={(e) => setAgeGroup(e.target.value)}
              />
              <label htmlFor="agegroup">Age Group</label>
            </div>
          </div>
          <div className="textarea-group">
            <textarea
              id="storyline"
              placeholder="Story Line"
              value={StoryLine}
              onChange={(e) => setStoryLine(e.target.value)}
            />
            <label htmlFor="storyline">Story Line</label>
          </div>
          <div className="two-input-group">
            <div className="input-group">
              <input
                placeholder="Language"
                id="Language"
                value={Language}
                onChange={(e) => setLanguage(e.target.value)}
              />
              <label htmlFor="Language">Language</label>
            </div>
            <div className="input-group">
              <input
                placeholder="PageID"
                id="pageid"
                value={DemoPageID}
                onChange={(e) => setDemoPageID(e.target.value)}
              />
              <label htmlFor="pageid">Demo Pages ID</label>
            </div>
          </div>
          <div className="input-group">
            <input
              placeholder="Color Page Count"
              id="colorpagecount"
              value={ColorPageCount}
              onChange={(e) => setColorPageCount(e.target.value)}
            />
            <label htmlFor="colorpagecount">Coloring Pages Count</label>
          </div>
          <div className="input-group">
            <input
              placeholder="Enter QR Url"
              value={QRText}
              id="upload-QR-image QRURL"
              onChange={(e) => generateQRImage(e.target.value)}
            />
            <label htmlFor="QRURL">QR Code URL</label>
          </div>
          <i style={{ display: "block", marginBottom: "16px" }}>
            {QRImageUrl == 0 ? (
              <img alt="QR Here"></img>
            ) : (
              <img src={QRImageUrl} alt="img" />
            )}
          </i>

          {/* <label htmlFor="upload-QR-image">
            {QRImage.preview ? (
              <img src={QRImage.preview} className="form-image" />
            ) : (
              <img
                src={
                  ServerPath +
                  "/api/file/assets/book-QRcode/" +
                  props.book.QRCode
                }
                className="form-image"
              />
            )}
          </label> */}
          {/* <input type="file" id="upload-QR-image" style={{ display: "none" }} /> */}
          <i style={{ display: "block" }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={isPublished}
                    onChange={(e) => setIsPublished(!Boolean(isPublished))}
                  />
                }
                label="isPublished?"
                labelPlacement="start"
              />
            </FormGroup>
          </i>
          <button className="button" type="submit">
            Save Book
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditBookForm;
