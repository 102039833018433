import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card,Form,Col,Row,Button} from "react-bootstrap";
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from "@material-ui/core/FormControlLabel";


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      maxWidth: 750,
         maxHeight:500,
    },
    demo: {
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
  }));
  
  export default function UsingTheGrid() {
    const classes = useStyles();
    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);
  
    return (
      <div className={classes.root} >
        <div className={classes.demo}>
            <Card  className="cards" >
        <Form>
            <h6
class="heading"
style={{ paddingTop: "1rem", marginLeft: "1.5rem" ,marginBottom:15}}>Using The Grid
</h6>
<hr style={{ marginTop: 0, marginBottom: 15 }} /> 
<Form.Group as={Row} controlId="formHorizontalEmail" style={{marginLeft:5,marginRight:10}}>
    <Form.Label column sm={3} className='form-text'>
      Email
    </Form.Label>
    <Col sm={9} >
      <Form.Control type="email" placeholder="Email" className='form-layout' />
    </Col>
  </Form.Group>

  <Form.Group as={Row} controlId="formHorizontalPassword" style={{marginLeft:5,marginRight:10}}>
    <Form.Label column sm={3} className='form-text'>
      Password
    </Form.Label>
    <Col sm={9} >
      <Form.Control type="password" placeholder="Password" className='form-layout'/>
    </Col>
  </Form.Group>
 <Form.Group as={Row} controlId="formHorizontalPassword" style={{marginLeft:5,marginRight:20}}>
    <Form.Label column sm={3} className='form-text'>
      Radios
    </Form.Label>
    <Col sm={9} >
    <Row >
    <FormControl component="fieldset"  className={classes.formControl}>
    <FormLabel component="legend"></FormLabel>
    <RadioGroup aria-label name="quiz"  className='form-text' style={{marginLeft:3}}>
      <FormControlLabel value="best" control={<Radio />} label="Option 1" />
      <FormControlLabel value="worst" control={<Radio />} label="Option 2"/>
      <FormControlLabel value="disabled" disabled control={<Radio />} label="Disabled"/>
    </RadioGroup> 
  </FormControl>
      </Row>
    </Col>
  </Form.Group>
  <Form.Group as={Row} controlId="formHorizontalPassword" style={{marginLeft:5,marginRight:10}}>
    <Form.Label column sm={3} >
    </Form.Label>
    <Col sm={9}  >
    <Button variant="primary">SIGN IN</Button>{' '}
    </Col>
  </Form.Group>
</Form>
</Card>

        </div>
      </div>
    );
  }
  