import React, { useState, useEffect } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import Singleton from "../../services/SingletonService";
import config from "../../config.json";
import { useAsyncDebounce } from "react-table";
import Select from "react-select";

const UserType = (props) => {
  const [availableUserTypes, setAvailableUserTypes] = useState({ options: [] });
  const [presentUserType, setPresentUserType] = useState(props.type);
  const token = localStorage.getItem("Token");
  let commonData = Singleton.getInstance();
  const userTypes = commonData.getUserTypes();
  const [userTypesOptions, setUserTypesOptions] = useState({ options: [] });
  const ServerPath = config.rapidServerPath;
  useEffect(() => {
    const options = [];
    commonData.UserTypes.map((usertype) => {
      options.push({ label: usertype.Name, value: usertype.Value });
    });
    setAvailableUserTypes({ options: options });
  }, []);
  availableUserTypes.options.forEach((element) => {
    if (presentUserType === element.value) {
      setPresentUserType(element.label);
    } else if (presentUserType == 1) {
      setPresentUserType("Admin");
    }
  });
  return <div>{presentUserType}</div>;
};

export default UserType;
