import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
// import FormControl from "@material-ui/core/FormControl";
import { BsImage } from "react-icons/bs";
// import { RiQrCodeLine } from "react-icons/ri";
import "./BookForm.css";
import config from "../../config.json";
// import QRCode from "react-qr-code";
import QRCode from "qrcode";
import { useEffect } from "react";
const CreateBookForm = (props) => {
  const [bookName, setBookName] = useState("");
  const [PageCount, setPageCount] = useState("");
  const [Category, setCategory] = useState("");
  const [AgeGroup, setAgeGroup] = useState("");
  const [Tags, setTags] = useState("");
  const [StoryLine, setStoryLine] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [Language, setLanguage] = useState("English");
  const [DemoPageID, setDemoPageID] = useState("TITLE, P1, P2, P3, COVERBACK");
  const [ColorPageCount, setColorPageCount] = useState("");
  //const [QR_URL, setQR_URL] = useState("");
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [QRImage, setQRImage] = useState({ preview: "", raw: "" });
  const token = localStorage.getItem("Token");
  const ServerPath = config.rapidServerPath;
  var [QRText, setQRText] = useState(config.rapidServerPath);
  var [QRImageUrl, setQRImageUrl] = useState("");

  // var QR_URL = "";
  const createNotificationHandler = (type) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "Details changed successfully",
          "Success",
          3000
        );
        break;
      case "error":
        NotificationManager.error("Something went wrong", "Error", 3000);
        break;
      case "Enter Image":
        NotificationManager.info("Please choose a book image", "Info", 3000);
        break;
      //case "Enter QRCode":
      // NotificationManager.info(
      //   "Please enter URL for QRCode generation",
      //   "Info",
      //   3000
      // );
      // break;
      case "ColorPageCount":
        NotificationManager.info(
          "Number of Color Pages should be less than or equal to the Page Count",
          "Info",
          3000
        );
        break;
      default:
        console.log("default");
    }
  };

  const validateColorPageCount = () => {
    if (Number(ColorPageCount) <= Number(PageCount)) {
      return true;
    } else {
      return false;
    }
  };

  async function saveImage(e) {
    e.preventDefault();
    if (!image.raw) {
      createNotificationHandler("Enter Image");
    }
    //else if (!QRImage.raw) {
    //  createNotificationHandler("Enter QRCode");
    //}
    else if (!validateColorPageCount()) {
      createNotificationHandler("ColorPageCount");
    } else {
      let ImagePath = "";
      let QRCode = "";
      if (image.raw || QRImage.raw) {
        const form = new FormData();
        if (image.raw) {
          form.append("file", image.raw);
          form.append("imageName", "COVERFRONT.jpg");
          form.append("folderName", "book-photos");
          //form.append("subFolderName", bookName);
          const response = await axios.post(
            ServerPath + "/api/file/saveImage",
            form,
            {
              headers: { Authorization: token, Apikey: "XYZ" },
            }
          );
          ImagePath = response.data.uid;
        }
        if (QRImage.raw) {
          form.append("file", QRImage.raw);
          // form.append("folderName", "book-QRcode");
          form.append("imageName", "QRCode.jpg");
          form.append("folderName", "book-photos");
          // form.append("subFolderName", "QRCode");
          form.append("uid", ImagePath + "/QRCode");
          const response = await axios.post(
            ServerPath + "/api/file/saveImage",
            form,
            {
              headers: { Authorization: token, Apikey: "XYZ" },
            }
          );
          console.log(response);
          //Use the same folder as above Book Image to save QR Code image
          QRCode = ImagePath + "/QRCode/QRCode.jpg";
        }
      }
      handleCreate(ImagePath, QRCode);
    }
  }

  async function handleCreate(ImagePath, QRCode) {
    try {
      const response = await axios.post(
        ServerPath + "/api/books/createBook",
        {
          ImagePath: ImagePath,
          QRCode: QRText,
          Name: bookName,
          PageCount: PageCount,
          Category: Category,
          AgeGroup: AgeGroup,
          Tags: Tags,
          StoryLine: StoryLine,
          DemoPageID: DemoPageID,
          isPublished: Number(isPublished),
          Language: Language,
          ColorPageCount: ColorPageCount,
        },
        {
          headers: {
            Authorization: token,
            Apikey: "XYZ",
          },
        }
      );
      props.sendUpdate(true);
      if (response.data.success === true) {
        createNotificationHandler("success");
      } else {
        createNotificationHandler("error");
      }
      props.closeModel();
    } catch (error) {
      if (error.response) {
        NotificationManager.error(error.response.data.error, "Error", 3000);
        console.log(error);
      }
    }
  }

  const setBookImage = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  // QR function
  const generateQRImage = (val) => {
    if (val.length === 0) {
      setQRText("");
      setQRImageUrl(0);
    } else {
      //console.log("BOX VAL IS - " +val);
      QRCode.toDataURL(val).then((data) => {
        setQRImageUrl(data);
        //  console.log("THIS IS THE QR CODE GENERATED -" + JSON.stringify(data) );
        setQRImage({
          preview: data, //URL.createObjectURL(data),
          raw: data,
        });
        setQRText(val);
        ///console.log("QRTextVAL is - " + QRText);
      });
    }
    return;
  };
  useEffect(() => {
    generateQRImage(QRText);
  }, []);

  return (
    <div className="book-container">
      <div className="content">
        <form onSubmit={(e) => saveImage(e)}>
          <div className="input-group">
            <input
              id="name"
              placeholder="Name"
              value={bookName}
              onChange={(e) => setBookName(e.target.value)}
              required
            />
            <label htmlFor="name">Book Name</label>
          </div>
          <label htmlFor="upload-book-image">
            {image.preview ? (
              <img
                src={image.preview}
                alt="Book Image Here"
                className="form-image"
              />
            ) : (
              <BsImage size={"50%"} />
            )}
          </label>

          <input
            id="upload-book-image"
            style={{ display: "none" }}
            type="file"
            onChange={(e) => setBookImage(e)}
          />

          <div className="two-input-group">
            <div className="input-group">
              <input
                placeholder="Page Count"
                id="pagecount"
                value={PageCount}
                onChange={(e) => setPageCount(e.target.value)}
                required
              />
              <label htmlFor="pagecount">Page Count</label>
            </div>

            <div className="input-group">
              <input
                placeholder="Category"
                id="category"
                value={Category}
                onChange={(e) => setCategory(e.target.value)}
                required
              />
              <label htmlFor="category">Category</label>
            </div>
          </div>
          <div className="two-input-group">
            <div className="input-group">
              <input
                id="tags"
                placeholder="Tags"
                value={Tags}
                onChange={(e) => setTags(e.target.value)}
              />
              <label htmlFor="tags">Tags</label>
            </div>

            <div className="input-group">
              <input
                id="agegroup"
                placeholder="Age Group"
                value={AgeGroup}
                onChange={(e) => setAgeGroup(e.target.value)}
              />
              <label htmlFor="agegroup">Age Group</label>
            </div>
          </div>
          <div className="textarea-group">
            <textarea
              id="storyline"
              placeholder="Story Line"
              value={StoryLine}
              onChange={(e) => setStoryLine(e.target.value)}
            />
            <label htmlFor="storyline">Story Line</label>
          </div>
          <div className="two-input-group">
            <div className="input-group">
              <input
                id="language"
                placeholder="Language"
                value={Language}
                onChange={(e) => setLanguage(e.target.value)}
                required
              />
              <label htmlFor="language">Language</label>
            </div>
            <div className="input-group">
              <input
                id="pageid"
                placeholder="DemoPageID"
                value={DemoPageID}
                onChange={(e) => setDemoPageID(e.target.value)}
              />
              <label htmlFor="pageid">Demo Pages ID</label>
            </div>
          </div>
          <div className="input-group">
            <input
              id="colorpagecount"
              placeholder="Color Page Count"
              value={ColorPageCount}
              onChange={(e) => setColorPageCount(e.target.value)}
            />
            <label htmlFor="colorpagecount">Color Page Count</label>
          </div>
          {/* QR input and display*/}
          <div className="input-group">
            <input
              placeholder="Enter QR Url"
              value={QRText}
              id="upload-QR-image QRURL"
              onChange={(e) => generateQRImage(e.target.value)}
            />
            <label htmlFor="QRURL">Enter QR URL</label>
          </div>
          <label htmlFor="upload-QR-image">
            {QRImageUrl === 0 ? (
              <img alt="QR Image Here"></img>
            ) : (
              <img src={QRImageUrl} alt="img" />
            )}
          </label>
          {/* <label htmlFor="QR-CODE-URL"> Enter QR URL</label> */}
          {/* <input
            id="QR-CODE-URL"
            placeholder="QR Code URL"
            //value={QR_URL}
            onChange={(e) => generateQR(e.target.value)}
          /> */}
          {/* <QRCode
            value={{ QR_URL }}
            id="BOOK_QR_CODE"
            renderAs="svg"
            style={{
              width: "50vmin",
              height: "50vmin",
            }}
          /> */}
          {/* <label htmlFor="upload-QR-image">
            {QRImage.preview ? (
              <img src={QRImage.preview} className="form-image" />
            ) : (
              <RiQrCodeLine size={"50%"} />
            )}
          </label> */}
          {/* <input
            type="file"
            id="upload-QR-image"
            style={{ display: "none" }}
            onChange={(e) => generateQRImage(e)}
          /> */}
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={isPublished}
                  onChange={(e) => setIsPublished(!Boolean(isPublished))}
                />
              }
              label="isPublished?"
              labelPlacement="start"
            />
          </FormGroup>
          <button className="button" type="submit">
            Create Book
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateBookForm;
