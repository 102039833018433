import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
// import echarts from "echarts";
import { Card } from "react-bootstrap";

export default class RadarChart extends Component {
  state = {
    option: {
      color: ["#ff708d", "#ffc94d"],
      legend: {
        data: ["Allocated Budget", "Actual Spending"],
        textStyle: {
          color: "#8f9bb3",
        },
      },
      radar: {
        splitArea: {
          areaStyle: {
            color: "transparent",
          },
        },
        name: {
          textStyle: {
            color: "#8f9bb3",
          },
        },
        indicator: [
          { name: "Sales", max: 6500 },
          { name: "Advertising", max: 16000 },
          { name: "IT", max: 30000 },
          { name: "Customer Service", max: 38000 },
          { name: "Development", max: 52000 },
          { name: "Marketing", max: 25000 },
        ],
      },
      series: [
        {
          name: "Budget vs spending",
          type: "radar",

          data: [
            {
              value: [4300, 10000, 28000, 35000, 50000, 19000],
              name: "Allocated Budget",
            },
            {
              value: [5000, 14000, 28000, 31000, 42000, 21000],
              name: "Actual Spending",
            },
          ],
        },
      ],
    },
  };
  render() {
    return (
      <Card className="echart-card">
        <h5
          class="heading"
          style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
        >
          Area Stack
        </h5>
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <ReactEcharts className="echarts" option={this.state.option} />
      </Card>
    );
  }
}
