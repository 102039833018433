import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import LogAxis from "./LogAxis";
import MultipleXAxis from "./MultipleXAxis";
import StackedAreaChart from "./StackedAreaChart";
import BarAnimationChart from "./BarAnimationChart";
import RadarChart from "./RadarChart";
import TreeChart from "./TreeChart";
import SankeyChart from "./SankeyChart";
import BubbleChart from "./BubbleChart";

export default function Echarts() {
  return (
    <Container fluid>
      <Row style={{ marginBottom: 35 }}>
        <Col lg={6}>
          <PieChart />
        </Col>
        <Col lg={6}>
          <BarChart />
        </Col>
      </Row>
      <Row style={{ marginBottom: 35 }}>
        <Col lg={6}>
          <LogAxis />
        </Col>
        <Col lg={6}>
          <MultipleXAxis />
        </Col>
      </Row>
      <Row style={{ marginBottom: 35 }}>
        <Col lg={6}>
          <StackedAreaChart />
        </Col>
        <Col lg={6}>
          <BarAnimationChart />
        </Col>
      </Row>
      <Row style={{ marginBottom: 35 }}>
        <Col lg={6}>
          <RadarChart />
        </Col>
        <Col lg={6}>
          <TreeChart />
        </Col>
      </Row>
      <Row style={{ marginBottom: 35 }}>
        <Col lg={6}>
          <SankeyChart />
        </Col>
        <Col lg={6}>
          <BubbleChart />
        </Col>
      </Row>
    </Container>
  );
}
