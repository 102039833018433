import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import axios from "axios";

import "./BookForm.css";
import config from "../../config.json";

const DetailsForm = (props) => {
  const BookID = props.book.BookID;

  const [marginTop, setmarginTop] = useState(props.book.marginTop);
  const [marginLeft, setmarginLeft] = useState(props.book.marginLeft);
  const [marginBottom, setmarginBottom] = useState(props.book.marginBottom);
  const [marginRight, setmarginRight] = useState(props.book.marginRight);
  const [width, setwidth] = useState(props.book.width);
  const [height, setheight] = useState(props.book.height);
  const [fontFamily, setfontFamily] = useState(props.book.fontFamily);
  const [fontSize, setfontSize] = useState(props.book.fontSize);
  const [borderRadius, setborderRadius] = useState(props.book.borderRadius);
  const [color, setcolor] = useState(props.book.color);
  const [backgroundColor, setbackgroundColor] = useState(
    props.book.backgroundColor
  );
  const [textShadowColor, settextShadowColor] = useState(
    props.book.textShadowColor
  );
  const [borderColor, setborderColor] = useState(props.book.borderColor);
  const [paddingTop, setpaddingTop] = useState(props.book.paddingTop);
  const [paddingLeft, setpaddingLeft] = useState(props.book.paddingLeft);
  const [paddingBottom, setpaddingBottom] = useState(props.book.paddingBottom);
  const [paddingRight, setpaddingRight] = useState(props.book.paddingRight);
  const [opacity, setopacity] = useState(props.book.opacity);
  // const [image, setImage] = useState({ preview: "", raw: "" });
  // const [QRImage, setQRImage] = useState({ preview: "", raw: "" });

  const token = localStorage.getItem("Token");
  const ServerPath = config.rapidServerPath;

  const createNotificationHandler = (type) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "Details changed successfully",
          "Success",
          3000
        );
        break;
      case "error":
        NotificationManager.error("Something went wrong", "error", 3000);
        break;

      default:
        console.log("default");
    }
  };

  async function updateBook(e) {
    e.preventDefault();

    let ImagePath = props.book.ImagePath;
    let QRCode = props.book.QRCode;

    handleUpdate(ImagePath, QRCode);
  }

  async function handleUpdate(ImagePath, QRCode) {
    try {
      const response = await axios.put(
        ServerPath + "/api/books/updateBook/  " + BookID,
        {
          ImagePath: ImagePath,
          QRCode: QRCode,
          marginTop: marginTop,
          marginBottom: marginBottom,
          marginRight: marginRight,
          marginLeft: marginLeft,
          width: width,
          height: height,
          fontFamily: fontFamily,
          fontSize: fontSize,
          borderColor: borderColor,
          borderRadius: borderRadius,
          color: color,
          backgroundColor: backgroundColor,
          textShadowColor: textShadowColor,
          paddingLeft: paddingLeft,
          paddingRight: paddingRight,
          paddingTop: paddingTop,
          paddingBottom: paddingBottom,
          opacity: opacity,
        },
        {
          headers: {
            Authorization: token,
            Apikey: "XYZ",
          },
        }
      );
      props.sendUpdate(true);
      if (response.data.success === true) {
        createNotificationHandler("success");
      } else {
        createNotificationHandler("error");
      }
      props.closeModel();
    } catch (error) {
      if (error.response) {
        NotificationManager.error(error.response.data.error, "Error", 3000);
        console.log(error);
      }
    }
  }

  const buttonstyle = {
    marginTop: "1rem",
  };

  return (
    <div className="book-container">
      <div className="content">
        <form onSubmit={(e) => updateBook(e)}>
          <div className="two-input-group">
            <div className="input-group">
              <input
                type="number"
                placeholder="marginTop"
                id="marginTop"
                value={marginTop}
                onChange={(e) => setmarginTop(e.target.value)}
              />
              <label htmlFor="marginTop">Margin Top</label>
            </div>
            <div className="input-group">
              <input
                type="number"
                placeholder="marginLeft"
                id="marginLeft"
                value={marginLeft}
                onChange={(e) => setmarginLeft(e.target.value)}
              />
              <label htmlFor="marginLeft">Margin Left</label>
            </div>
          </div>
          <div className="two-input-group">
            <div className="input-group">
              <input
                id="marginBottom"
                type="number"
                placeholder="marginBottom"
                value={marginBottom}
                onChange={(e) => setmarginBottom(e.target.value)}
              />
              <label htmlFor="marginBottom">Margin Bottom</label>
            </div>
            <div className="input-group">
              <input
                id="marginRight"
                type="number"
                placeholder="marginRight"
                value={marginRight}
                onChange={(e) => setmarginRight(e.target.value)}
              />
              <label htmlFor="marginRight">Margin Right</label>
            </div>
          </div>
          <div className="two-input-group">
            <div className="input-group">
              <input
                type="number"
                id="width"
                placeholder="Width"
                value={width}
                onChange={(e) => setwidth(e.target.value)}
              />
              <label htmlFor="width">Width</label>
            </div>
            <div className="input-group">
              <input
                id="height"
                type="number"
                placeholder="Height"
                value={height}
                onChange={(e) => setheight(e.target.value)}
              />
              <label htmlFor="height">Height</label>
            </div>
          </div>
          <div className="two-input-group">
            <div className="input-group">
              <input
                type="text"
                id="fontfamily"
                placeholder="Font Family"
                value={fontFamily}
                onChange={(e) => setfontFamily(e.target.value)}
              />
              <label htmlFor="fontfamily">Font Family</label>
            </div>
            <div className="input-group">
              <input
                id="fontsize"
                type="number"
                placeholder="Font size"
                value={fontSize}
                onChange={(e) => setfontSize(e.target.value)}
              />
              <label htmlFor="fontsize">Font Size</label>
            </div>
          </div>
          <div className="two-input-group">
            <div className="input-group">
              <input
                id="borderradius"
                type="number"
                placeholder="border radius"
                value={borderRadius}
                onChange={(e) => setborderRadius(e.target.value)}
              />
              <label htmlFor="borderradius">Border Radius</label>
            </div>
            <div className="input-group">
              <input
                id="color"
                type="text"
                placeholder="Color"
                value={color}
                onChange={(e) => setcolor(e.target.value)}
              />
              <label htmlFor="color">Color</label>
            </div>
          </div>
          <div className="two-input-group">
            <div className="input-group">
              <input
                id="textshadowcolor"
                type="text"
                placeholder="textShadowColor"
                value={textShadowColor}
                onChange={(e) => settextShadowColor(e.target.value)}
              />
              <label htmlFor="textshadowcolor">Text Shadow Color</label>
            </div>
            <div className="input-group">
              <input
                id="backgroundcolor"
                type="text"
                placeholder="backgroundColor"
                value={backgroundColor}
                onChange={(e) => setbackgroundColor(e.target.value)}
              />
              <label htmlFor="backgroundcolor">Background Color</label>
            </div>
          </div>
          <div className="two-input-group">
            <div className="input-group">
              <input
                id="bordercolor"
                type="text"
                placeholder="borderColor"
                value={borderColor}
                onChange={(e) => setborderColor(e.target.value)}
              />
              <label htmlFor="bordercolor">Border Color</label>
            </div>
            <div className="input-group">
              <input
                id="paddingtop"
                type="number"
                placeholder="paddingTop"
                value={paddingTop}
                onChange={(e) => setpaddingTop(e.target.value)}
              />
              <label htmlFor="paddingtop">Padding Top</label>
            </div>
          </div>
          <div className="two-input-group">
            <div className="input-group">
              <input
                id="paddingleft"
                type="number"
                placeholder="PaddingLeft"
                value={paddingLeft}
                onChange={(e) => setpaddingLeft(e.target.value)}
              />
              <label htmlFor="paddingleft">Padding Left</label>
            </div>
            <div className="input-group">
              <input
                id="paddingbottom"
                type="number"
                placeholder="PaddingBottom"
                value={paddingBottom}
                onChange={(e) => setpaddingBottom(e.target.value)}
              />
              <label htmlFor="paddingbottom">Padding Bottom</label>
            </div>
          </div>
          <div className="two-input-group">
            <div className="input-group">
              <input
                id="paddingright"
                type="number"
                placeholder="paddingRight"
                value={paddingRight}
                onChange={(e) => setpaddingRight(e.target.value)}
              />
              <label htmlFor="paddingright">Padding Right</label>
            </div>
            <div className="input-group">
              <input
                id="opacity"
                type="number"
                min="0"
                step="0.01"
                placeholder="opacity"
                value={opacity}
                onChange={(e) => setopacity(e.target.value)}
              />
              <label htmlFor="opacity">Opacity</label>
            </div>
          </div>
          <div style={buttonstyle}>
            <button className="button" type="submit">
              Save Book
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DetailsForm;
