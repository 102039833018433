import React from "react";
import { Redirect, Route } from "react-router-dom";

class ProtectedRoute extends React.Component {
  render() {
    const Component = this.props.component;
    let isAuthenticated = false;
    const token = localStorage.getItem("Token");
    if (!token) {
      isAuthenticated = false;
    } else {
      isAuthenticated = true;
    }
    return isAuthenticated ? (
      <Route path="/ngx" component={Component} />
    ) : (
      <Redirect to={{ pathname: "/login" }} />
    );
  }
}

export default ProtectedRoute;
