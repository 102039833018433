export const data = {
  nodes: [
    {
      id: "1",
      radius: 8,
      depth: 1,
      color: "#007bff",
    },
    {
      id: "2",
      radius: 8,
      depth: 1,
      color: "#007bff",
    },
    {
      id: "3",
      radius: 8,
      depth: 1,
      color: "#007bff",
    },
    {
      id: "4",
      radius: 8,
      depth: 1,
      color: "#007bff",
    },
    {
      id: "5",
      radius: 8,
      depth: 1,
      color: "#007bff",
    },
    {
      id: "6",
      radius: 8,
      depth: 1,
      color: "#007bff",
    },
    {
      id: "7",
      radius: 8,
      depth: 1,
      color: "#007bff",
    },
    {
      id: "8",
      radius: 8,
      depth: 1,
      color: "#007bff",
    },
    {
      id: "9",
      radius: 8,
      depth: 1,
      color: "#007bff",
    },
    {
      id: "10",
      radius: 8,
      depth: 1,
      color: "#007bff",
    },
    {
      id: "11",
      radius: 8,
      depth: 1,
      color: "#007bff",
    },
    {
      id: "12",
      radius: 8,
      depth: 1,
      color: "#007bff",
    },
    {
      id: "13",
      radius: 8,
      depth: 1,
      color: "#007bff",
    },
    {
      id: "0",
      radius: 12,
      depth: 0,
      color: "#dc3545",
    },
    {
      id: "1.0",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "1.1",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "1.2",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "1.3",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "2.0",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "2.1",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "2.2",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "2.3",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "2.4",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "2.5",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "2.6",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "2.7",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "2.8",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "3.0",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "3.1",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "3.2",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "3.3",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "4.0",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "4.1",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "4.2",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "4.3",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "4.4",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "4.5",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "4.6",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "4.7",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "4.8",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "4.9",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "4.10",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "4.11",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "4.12",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "4.13",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "4.14",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "4.15",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "5.0",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "5.1",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "5.2",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "5.3",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "6.0",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "6.1",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "6.2",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "6.3",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "6.4",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "6.5",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "6.6",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "6.7",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "6.8",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "6.9",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "6.10",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "6.11",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "6.12",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "7.0",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "7.1",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "7.2",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "7.3",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "7.4",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "7.5",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "7.6",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "7.7",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "7.8",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "8.0",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "8.1",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "8.2",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "8.3",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "8.4",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "8.5",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "8.6",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "8.7",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "8.8",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "8.9",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "8.10",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "8.11",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "8.12",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "8.13",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "8.14",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "9.0",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "9.1",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "9.2",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "9.3",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "9.4",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "9.5",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "9.6",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "9.7",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "9.8",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "9.9",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "9.10",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "9.11",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "9.12",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "10.0",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "10.1",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "10.2",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "10.3",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "10.4",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "10.5",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "11.0",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "11.1",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "11.2",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "11.3",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "11.4",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "11.5",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "11.6",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "11.7",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "11.8",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "11.9",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "11.10",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "11.11",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "11.12",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "11.13",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "11.14",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "12.0",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "12.1",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "12.2",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "12.3",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "12.4",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "12.5",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "12.6",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "12.7",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "12.8",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "12.9",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "12.10",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "13.0",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "13.1",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "13.2",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
    {
      id: "13.3",
      radius: 4,
      depth: 2,
      color: "rgb(44, 230, 155)",
    },
  ],
  links: [
    {
      source: "0",
      target: "1",
      distance: 50,
    },
    {
      source: "1",
      target: "1.0",
      distance: 30,
    },
    {
      source: "1",
      target: "1.1",
      distance: 30,
    },
    {
      source: "1",
      target: "1.2",
      distance: 30,
    },
    {
      source: "1",
      target: "1.3",
      distance: 30,
    },
    {
      source: "0",
      target: "2",
      distance: 50,
    },
    {
      source: "2",
      target: "13",
      distance: 70,
    },
    {
      source: "2",
      target: "2.0",
      distance: 30,
    },
    {
      source: "2",
      target: "2.1",
      distance: 30,
    },
    {
      source: "2",
      target: "2.2",
      distance: 30,
    },
    {
      source: "2",
      target: "2.3",
      distance: 30,
    },
    {
      source: "2",
      target: "2.4",
      distance: 30,
    },
    {
      source: "2",
      target: "2.5",
      distance: 30,
    },
    {
      source: "2",
      target: "2.6",
      distance: 30,
    },
    {
      source: "2",
      target: "2.7",
      distance: 30,
    },
    {
      source: "2",
      target: "2.8",
      distance: 30,
    },
    {
      source: "0",
      target: "3",
      distance: 50,
    },
    {
      source: "3",
      target: "12",
      distance: 70,
    },
    {
      source: "3",
      target: "3.0",
      distance: 30,
    },
    {
      source: "3",
      target: "3.1",
      distance: 30,
    },
    {
      source: "3",
      target: "3.2",
      distance: 30,
    },
    {
      source: "3",
      target: "3.3",
      distance: 30,
    },
    {
      source: "0",
      target: "4",
      distance: 50,
    },
    {
      source: "4",
      target: "4.0",
      distance: 30,
    },
    {
      source: "4",
      target: "4.1",
      distance: 30,
    },
    {
      source: "4",
      target: "4.2",
      distance: 30,
    },
    {
      source: "4",
      target: "4.3",
      distance: 30,
    },
    {
      source: "4",
      target: "4.4",
      distance: 30,
    },
    {
      source: "4",
      target: "4.5",
      distance: 30,
    },
    {
      source: "4",
      target: "4.6",
      distance: 30,
    },
    {
      source: "4",
      target: "4.7",
      distance: 30,
    },
    {
      source: "4",
      target: "4.8",
      distance: 30,
    },
    {
      source: "4",
      target: "4.9",
      distance: 30,
    },
    {
      source: "4",
      target: "4.10",
      distance: 30,
    },
    {
      source: "4",
      target: "4.11",
      distance: 30,
    },
    {
      source: "4",
      target: "4.12",
      distance: 30,
    },
    {
      source: "4",
      target: "4.13",
      distance: 30,
    },
    {
      source: "4",
      target: "4.14",
      distance: 30,
    },
    {
      source: "4",
      target: "4.15",
      distance: 30,
    },
    {
      source: "0",
      target: "5",
      distance: 50,
    },
    {
      source: "5",
      target: "3",
      distance: 70,
    },
    {
      source: "5",
      target: "9",
      distance: 70,
    },
    {
      source: "5",
      target: "5.0",
      distance: 30,
    },
    {
      source: "5",
      target: "5.1",
      distance: 30,
    },
    {
      source: "5",
      target: "5.2",
      distance: 30,
    },
    {
      source: "5",
      target: "5.3",
      distance: 30,
    },
    {
      source: "0",
      target: "6",
      distance: 50,
    },
    {
      source: "6",
      target: "6.0",
      distance: 30,
    },
    {
      source: "6",
      target: "6.1",
      distance: 30,
    },
    {
      source: "6",
      target: "6.2",
      distance: 30,
    },
    {
      source: "6",
      target: "6.3",
      distance: 30,
    },
    {
      source: "6",
      target: "6.4",
      distance: 30,
    },
    {
      source: "6",
      target: "6.5",
      distance: 30,
    },
    {
      source: "6",
      target: "6.6",
      distance: 30,
    },
    {
      source: "6",
      target: "6.7",
      distance: 30,
    },
    {
      source: "6",
      target: "6.8",
      distance: 30,
    },
    {
      source: "6",
      target: "6.9",
      distance: 30,
    },
    {
      source: "6",
      target: "6.10",
      distance: 30,
    },
    {
      source: "6",
      target: "6.11",
      distance: 30,
    },
    {
      source: "6",
      target: "6.12",
      distance: 30,
    },
    {
      source: "0",
      target: "7",
      distance: 50,
    },
    {
      source: "7",
      target: "7.0",
      distance: 30,
    },
    {
      source: "7",
      target: "7.1",
      distance: 30,
    },
    {
      source: "7",
      target: "7.2",
      distance: 30,
    },
    {
      source: "7",
      target: "7.3",
      distance: 30,
    },
    {
      source: "7",
      target: "7.4",
      distance: 30,
    },
    {
      source: "7",
      target: "7.5",
      distance: 30,
    },
    {
      source: "7",
      target: "7.6",
      distance: 30,
    },
    {
      source: "7",
      target: "7.7",
      distance: 30,
    },
    {
      source: "7",
      target: "7.8",
      distance: 30,
    },
    {
      source: "0",
      target: "8",
      distance: 50,
    },
    {
      source: "8",
      target: "9",
      distance: 70,
    },
    {
      source: "8",
      target: "8.0",
      distance: 30,
    },
    {
      source: "8",
      target: "8.1",
      distance: 30,
    },
    {
      source: "8",
      target: "8.2",
      distance: 30,
    },
    {
      source: "8",
      target: "8.3",
      distance: 30,
    },
    {
      source: "8",
      target: "8.4",
      distance: 30,
    },
    {
      source: "8",
      target: "8.5",
      distance: 30,
    },
    {
      source: "8",
      target: "8.6",
      distance: 30,
    },
    {
      source: "8",
      target: "8.7",
      distance: 30,
    },
    {
      source: "8",
      target: "8.8",
      distance: 30,
    },
    {
      source: "8",
      target: "8.9",
      distance: 30,
    },
    {
      source: "8",
      target: "8.10",
      distance: 30,
    },
    {
      source: "8",
      target: "8.11",
      distance: 30,
    },
    {
      source: "8",
      target: "8.12",
      distance: 30,
    },
    {
      source: "8",
      target: "8.13",
      distance: 30,
    },
    {
      source: "8",
      target: "8.14",
      distance: 30,
    },
    {
      source: "0",
      target: "9",
      distance: 50,
    },
    {
      source: "9",
      target: "3",
      distance: 70,
    },
    {
      source: "9",
      target: "9.0",
      distance: 30,
    },
    {
      source: "9",
      target: "9.1",
      distance: 30,
    },
    {
      source: "9",
      target: "9.2",
      distance: 30,
    },
    {
      source: "9",
      target: "9.3",
      distance: 30,
    },
    {
      source: "9",
      target: "9.4",
      distance: 30,
    },
    {
      source: "9",
      target: "9.5",
      distance: 30,
    },
    {
      source: "9",
      target: "9.6",
      distance: 30,
    },
    {
      source: "9",
      target: "9.7",
      distance: 30,
    },
    {
      source: "9",
      target: "9.8",
      distance: 30,
    },
    {
      source: "9",
      target: "9.9",
      distance: 30,
    },
    {
      source: "9",
      target: "9.10",
      distance: 30,
    },
    {
      source: "9",
      target: "9.11",
      distance: 30,
    },
    {
      source: "9",
      target: "9.12",
      distance: 30,
    },
    {
      source: "0",
      target: "10",
      distance: 50,
    },
    {
      source: "10",
      target: "2",
      distance: 70,
    },
    {
      source: "10",
      target: "10.0",
      distance: 30,
    },
    {
      source: "10",
      target: "10.1",
      distance: 30,
    },
    {
      source: "10",
      target: "10.2",
      distance: 30,
    },
    {
      source: "10",
      target: "10.3",
      distance: 30,
    },
    {
      source: "10",
      target: "10.4",
      distance: 30,
    },
    {
      source: "10",
      target: "10.5",
      distance: 30,
    },
    {
      source: "0",
      target: "11",
      distance: 50,
    },
    {
      source: "11",
      target: "11.0",
      distance: 30,
    },
    {
      source: "11",
      target: "11.1",
      distance: 30,
    },
    {
      source: "11",
      target: "11.2",
      distance: 30,
    },
    {
      source: "11",
      target: "11.3",
      distance: 30,
    },
    {
      source: "11",
      target: "11.4",
      distance: 30,
    },
    {
      source: "11",
      target: "11.5",
      distance: 30,
    },
    {
      source: "11",
      target: "11.6",
      distance: 30,
    },
    {
      source: "11",
      target: "11.7",
      distance: 30,
    },
    {
      source: "11",
      target: "11.8",
      distance: 30,
    },
    {
      source: "11",
      target: "11.9",
      distance: 30,
    },
    {
      source: "11",
      target: "11.10",
      distance: 30,
    },
    {
      source: "11",
      target: "11.11",
      distance: 30,
    },
    {
      source: "11",
      target: "11.12",
      distance: 30,
    },
    {
      source: "11",
      target: "11.13",
      distance: 30,
    },
    {
      source: "11",
      target: "11.14",
      distance: 30,
    },
    {
      source: "0",
      target: "12",
      distance: 50,
    },
    {
      source: "12",
      target: "12.0",
      distance: 30,
    },
    {
      source: "12",
      target: "12.1",
      distance: 30,
    },
    {
      source: "12",
      target: "12.2",
      distance: 30,
    },
    {
      source: "12",
      target: "12.3",
      distance: 30,
    },
    {
      source: "12",
      target: "12.4",
      distance: 30,
    },
    {
      source: "12",
      target: "12.5",
      distance: 30,
    },
    {
      source: "12",
      target: "12.6",
      distance: 30,
    },
    {
      source: "12",
      target: "12.7",
      distance: 30,
    },
    {
      source: "12",
      target: "12.8",
      distance: 30,
    },
    {
      source: "12",
      target: "12.9",
      distance: 30,
    },
    {
      source: "12",
      target: "12.10",
      distance: 30,
    },
    {
      source: "0",
      target: "13",
      distance: 50,
    },
    {
      source: "13",
      target: "13.0",
      distance: 30,
    },
    {
      source: "13",
      target: "13.1",
      distance: 30,
    },
    {
      source: "13",
      target: "13.2",
      distance: 30,
    },
    {
      source: "13",
      target: "13.3",
      distance: 30,
    },
  ],
};
