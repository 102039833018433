import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import { BsFillCaretUpFill } from "react-icons/bs";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Select from "react-select";
import GroupedBarChart from "../ApexChart/GroupedBarChart";
import { Card } from "react-bootstrap";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 407,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("xyz", 455, 7.0, 55, 6.0),
  createData("xyz", 455, 7.0, 55, 6.0),
  createData("xyz", 455, 7.0, 55, 6.0),
  createData("xyz", 455, 7.0, 55, 6.0),
  createData("xyz", 455, 7.0, 55, 6.0),
  createData("xyz", 455, 7.0, 55, 6.0),
  createData("xyz", 455, 7.0, 55, 6.0),
];

export default function VerticalFlipCard() {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState({
    value: 1,
    label: "Week",
  });
  const [isFlipped, setIsFlipped] = useState(false);
  const [selectedOptionChart, setSelectedOptionChart] = useState({
    value: 4,
    label: "Week",
  });

  const selected = selectedOption;
  const handleSelectChange = (selected) => {
    setSelectedOption(selected);
  };

  const selectedChart = selectedOptionChart;
  const handleSelectChangeChart = (selectedChart) => {
    setSelectedOptionChart(selectedChart);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsFlipped(!isFlipped);
  };

  const options = [
    { value: 4, label: "Week" },
    { value: 5, label: "Month" },
    { value: 6, label: "Year" },
  ];

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
      <div
        style={{
          width: "100%",
          height: "auto",
          backgroundColor: "white",
          marginBottom: "35px",
          borderRadius: ".25rem",
        }}
      >
        <Card>
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <h6 className="user-activity-header">Traffic</h6>
            <div
              style={{ width: "100px", marginLeft: "auto", marginRight: "5%" }}
            >
              <Select
                isSearchable={false}
                options={options}
                value={selectedOption}
                autosize={true}
                onChange={handleSelectChange}
                components={{
                  IndicatorSeparator: () => null,
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: ".25rem",
                  colors: {
                    ...theme.colors,
                    primary25: "#adb5bd",
                    primary: "#007bff",
                  },
                })}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>
            <BsFillCaretUpFill
              style={{
                marginTop: "15px",
                marginRight: "2%",
              }}
              onClick={handleClick}
            />
          </div>
          <Paper className="user-table-ecom">
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        className="user-table-ecom-cell"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="right" className="user-table-ecom-cell">
                        {row.calories}
                      </TableCell>
                      <TableCell align="right" className="user-table-ecom-cell">
                        {row.fat}
                      </TableCell>
                      <TableCell align="right" className="user-table-ecom-cell">
                        {row.carbs}
                      </TableCell>
                      <TableCell align="right" className="user-table-ecom-cell">
                        {row.protein}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Card>
      </div>

      <div
        style={{
          width: "100%",
          height: "auto",
          backgroundColor: "white",
          marginBottom: "35px",
          borderRadius: ".25rem",
        }}
      >
        <Card>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "100px",
                marginLeft: "auto",
                marginTop: "2px",
                marginRight: "5%",
              }}
            >
              <Select
                isSearchable={false}
                options={options}
                value={selectedChart}
                onChange={handleSelectChangeChart}
                components={{
                  IndicatorSeparator: () => null,
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: ".25rem",
                  colors: {
                    ...theme.colors,
                    primary25: "#adb5bd",
                    primary: "#007bff",
                  },
                })}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <BsFillCaretUpFill
              onClick={handleClick}
              style={{
                marginTop: "15px",
                marginRight: "2%",
              }}
            />
          </div>
          <hr />
          {selectedChart.value === 4 ? (
            <GroupedBarChart value={selectedChart.value} />
          ) : selectedChart.value === 5 ? (
            <GroupedBarChart value={selectedChart.value} />
          ) : (
            <GroupedBarChart value={selectedChart.value} />
          )}
        </Card>
      </div>
    </ReactCardFlip>
  );
}
