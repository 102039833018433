import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Form, Col, Button, Row } from "react-bootstrap";
// import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 750,
    maxHeight: 500,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function BlockForm() {
  const classes = useStyles();
  // const [dense, setDense] = React.useState(false);
  // const [secondary, setSecondary] = React.useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.demo}>
        <Card className="cards">
          <Form>
            <h6
              class="heading"
              style={{
                paddingTop: "1rem",
                marginLeft: "1.5rem",
                marginBottom: 15,
              }}
            >
              Block Form
            </h6>
            <hr style={{ marginTop: 0, marginBottom: 0 }} />
            <Row>
              <Col style={{ marginLeft: 16, marginTop: 20, width: 300 }}>
                <Form.Label className="form-text">First Name</Form.Label>
                <Form.Control
                  placeholder="First name"
                  className="form-layout"
                />
              </Col>
              <Col style={{ marginRight: 20, marginTop: 20 }}>
                <Form.Label className="form-text">Last Name</Form.Label>
                <Form.Control placeholder="Last name" className="form-layout" />
              </Col>
            </Row>
            <Row>
              <Col style={{ marginLeft: 16, width: 300, marginTop: 20 }}>
                <Form.Label className="form-text">Email</Form.Label>
                <Form.Control placeholder="Email" className="form-layout" />
              </Col>
              <Col style={{ marginRight: 20, marginTop: 20 }}>
                <Form.Label className="form-text">Website</Form.Label>
                <Form.Control placeholder="Website" className="form-layout" />
              </Col>
            </Row>
            <Form.Group>
              <Button variant="light" style={{ marginLeft: 16, marginTop: 20 }}>
                SUBMIT
              </Button>{" "}
            </Form.Group>
          </Form>
        </Card>
      </div>
    </div>
  );
}
