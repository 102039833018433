import React, { Component } from "react";
import { ResponsiveCalendar } from "@nivo/calendar";
import { data } from "./CalendarData";
import { Card } from "react-bootstrap";

export default class CalendarChart extends Component {
  render() {
    return (
      <Card className="d3-charts" style={{ height: "462px" }}>
        <h5
          className="heading"
          style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
        >
          Calendar (Github)
        </h5>
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <ResponsiveCalendar
          data={data}
          from="2015-03-01"
          to="2016-07-12"
          emptyColor="#eeeeee"
          colors={["#9be9a8", "#40c463", "#30a14e", "#216e39"]}
          margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
          yearSpacing={40}
          monthBorderColor="#ffffff"
          dayBorderWidth={2}
          dayBorderColor="#ffffff"
          legends={[
            {
              anchor: "bottom-right",
              direction: "row",
              translateY: 36,
              itemCount: 4,
              itemWidth: 42,
              itemHeight: 36,
              itemsSpacing: 14,
              itemDirection: "right-to-left",
            },
          ]}
        />
      </Card>
    );
  }
}
