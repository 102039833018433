import React from "react";
import { Progress } from "reactstrap";
import { Card } from "react-bootstrap";

const ValueProgressBar = (props) => {
  return (
    <Card>
      <h5 class="heading" style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}>
        Progress Bar Value
      </h5>
      <hr style={{ marginTop: 0, marginBottom: 0 }} />
      <div className="color">
        <Progress value="40">40%</Progress>
        <Progress value="60">Some Custom Text Here</Progress>
      </div>
    </Card>
  );
};

export default ValueProgressBar;
