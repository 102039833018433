import { Card } from "react-bootstrap";
import React from "react";
import ReactCardFlip from "react-card-flip";
import ReactApexChart from "react-apexcharts";
import BarChart from "../ApexChart/BarChart";
import { BsFillCaretRightFill } from "react-icons/bs";
class Card1 extends React.Component {
  constructor() {
    super();
    this.state = {
      isFlipped: false,
      seriesSpark2: [
        {
          data: [15, 25, 87, 42, 76, 53, 64, 78, 34, 9, 49],
        },
      ],
      optionsSpark2: {
        chart: {
          type: "area",
          height: 160,
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: "smooth",
        },
        fill: {
          opacity: 0.3,
        },
        yaxis: {
          min: 0,
        },
        colors: ["#00B1F2"],
        title: {
          text: "$235,312",
          offsetX: 0,
          style: {
            fontSize: "24px",
          },
        },
        subtitle: {
          text: "Expenses",
          offsetX: 0,
          style: {
            fontSize: "14px",
          },
        },
      },
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.setState((prevState) => ({ isFlipped: !prevState.isFlipped }));
  }

  render() {
    return (
      <ReactCardFlip
        isFlipped={this.state.isFlipped}
        flipDirection="horizontal"
      >
        <div className="Ecom-flip-card">
          <BsFillCaretRightFill
            className="Flip-arrow"
            onClick={this.handleClick}
            style={{
              marginTop: "10px",
              marginLeft: "92%",
              marginBottom: "15px",
            }}
          />
          <div id="chart-spark2">
            <ReactApexChart
              options={this.state.optionsSpark2}
              series={this.state.seriesSpark2}
              type="area"
              height={160}
            />
          </div>
        </div>

        <div className="Ecom-flip-card">
          <BsFillCaretRightFill
            className="Flip-arrow"
            onClick={this.handleClick}
            style={{
              marginTop: "10px",
              marginLeft: "92%",
              marginBottom: "15px",
            }}
          />
          <BarChart />
        </div>
      </ReactCardFlip>
    );
  }
}

export default Card1;
