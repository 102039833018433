import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import FilledAlert from "./FilledAlert";
import OutlinedAlert from "./OutlinedAlert";
import StandardAlert from "./StandardAlerts";

export default function Alerts() {
  return (
    <Container fluid>
      <Row>
        <Col lg={4}>
          <FilledAlert />
        </Col>
        <Col lg={4}>
          <OutlinedAlert />
        </Col>
        <Col lg={4}>
          <StandardAlert />
        </Col>
      </Row>
    </Container>
  );
}
