import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import axios from "axios";
import config from "../../config.json";
import "./Image.css";
import FileSaver from "file-saver";
import { GrNext, GrPrevious } from "react-icons/gr";
import Select from "react-select";
import { em, hm, W, H, WPX, HPX } from "../common";

const HM_BookPageImages = () => {
  const [imageNumber, setImageNumber] = useState(0);
  const [Books, setBooks] = useState({ options: [] });
  const [selectedBook, setSelectedBook] = useState({
    label: "Select Book",
    value: "",
  });
  const ServerPath = config.rapidServerPath;
  const [resolution, setResolution] = useState("1920X1080");
  const [src, setSRC] = useState("");
  const [Book, setBook] = useState({ Object });
  const [selectedBookPages, setSelectedBookPages] = useState({ data: [] });
  const [pageContent, setPageContent] = useState({ data: [] });
  const [imageArray, setImageArray] = useState([]);
  const token = localStorage.getItem("Token");

  async function getBookDetails(BookName) {
    console.log(BookName);
    const response = await axios.get(
      ServerPath + "/api/books/getBookByName/" + BookName,
      {
        headers: {
          apikey: "XYZ",
        },
      }
    );
    setImageArray(populateImageArray(response.data.PageCount));
    giveImage(BookName, response.data.ImagePath, "COVERFRONT");
    getBookPages(response.data);
    setBook(response.data);
  }

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(ServerPath + "/api/books/getBooks", {
        headers: {
          Apikey: "XYZ",
        },
      });
      const options = [];
      response.data.map((book) => {
        options.push({ label: book.Name, value: book.BookID });
      });
      setBooks({ options: options });
      //console.log(W, H, em, hm, WPX, HPX);
    }
    fetchData();
  }, []);

  const getBookPages = async (BookData) => {
    console.log(BookData);
    const response = await axios.get(
      ServerPath + "/api/bookpage/getBookPagesOnBookID/" + BookData.BookID,
      {
        headers: {
          Authorization: token,
          Apikey: "XYZ",
        },
      }
    );

    setSelectedBookPages({ data: response.data });
    console.log(selectedBookPages);
  };

  const giveImage = (BookName, ImagePath, imageName) => {
    console.log(ImagePath);
    console.log(BookName);
    console.log(imageName);
    setSRC(
      ServerPath +
        `/api/file/assets/book-photos/${ImagePath}/${BookName}/${resolution}/${imageName}/JPG`
    );
  };

  const selected = selectedBook;
  const handleSelectChange = (selected) => {
    setSelectedBook(selected);
    getBookDetails(selected.label);
    setImageNumber(0);
  };

  const populateImageArray = (pageCount) => {
    const temp = [];
    temp.push("COVERFRONT");
    temp.push("BG");
    temp.push("PROMO");
    temp.push("TITLE1");
    temp.push("TITLE2");
    for (let i = 1; i <= pageCount - 6; i++) {
      temp.push("P" + i);
    }
    temp.push("COPYRIGHT");
    temp.push("COVERBACK");
    //temp.push("QR");
    return temp;
  };

  const getPageContent = async (number) => {
    console.log(number);
    console.log(selectedBookPages.data[number].BookID);
    const response = await axios.get(
      ServerPath +
        "/api/bookpage/getBookPagesOnBookId/" +
        selectedBookPages.data[number].BookID,
      {
        headers: {
          Authorization: token,
          Apikey: "XYZ",
        },
      }
    );
    const options = [];
    response.data.map((selectedBook) => {
      options.push({
        label: selectedBook.PageName,
        value: selectedBook.BookText,
      });
    });
    setPageContent({ data: options });
    console.log(pageContent);
  };

  const handleNext = () => {
    //console.log(imageNumber);
    setImageNumber(imageNumber + 1);
    giveImage(selectedBook.label, Book.ImagePath, imageArray[imageNumber + 1]);
    if (imageArray[imageNumber + 1].length === 2) {
      getPageContent(imageNumber + 1);
    }
  };

  const handlePrevious = () => {
    setImageNumber(imageNumber - 1);
    giveImage(selectedBook.label, Book.ImagePath, imageArray[imageNumber - 1]);
    if (imageArray[imageNumber - 1].length === 2) {
      getPageContent(imageNumber - 1);
    }
  };
  console.log(pageContent.data);
  return (
    <div>
      <div className="toggle-image">
        <h5 className="heading">Browse Book Pages</h5>
        <div
          style={{
            width: "200px",
            marginLeft: "auto",
            marginRight: "3%",
            marginBottom: 4,
          }}
        >
          <Select
            isSearchable={false}
            options={Books.options}
            value={selectedBook}
            onChange={handleSelectChange}
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={styles}
            theme={(theme) => ({
              ...theme,
              borderRadius: ".25rem",
              colors: {
                ...theme.colors,
                primary25: "#adb5bd",
                primary: "#007bff",
              },
            })}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
      </div>
      <Card>
        {src === "" ? null : (
          <>
            <div style={{ position: "relative" }}>
              <div className="head-image">
                <img src={src} className="book-image" />
              </div>
              {pageContent.data.length > 0 ? (
                <div
                  style={{
                    position: "absolute",
                    color: Book.color,
                    borderColor: "white",
                    textShadowOffset: { width: -4, height: 2 },
                    display: "flex", //TODO : revisit this
                    width: (100 * Book.width) / 720 + "%",
                    textShadowRadius: 30,
                    textShadowColor: "gray",
                    height: "auto", //TODO : revisit this
                    maxHeight: (100 * Book.height) / 720 + "%", //TODO : revisit this
                    paddingTop: (100 * Book.paddingTop) / 720 + "%",
                    paddingLeft: (100 * Book.paddingLeft) / 720 + "%",
                    paddingBottom: (100 * Book.paddingBottom) / 720 + "%",
                    paddingRight: (100 * Book.paddingRight) / 720 + "%",
                    backgroundColor: Book.backgroundColor,
                    //opacity:Book.opacity,
                    borderRadius: (100 * Book.borderRadius) / 720 + "%",
                    overflow: "hidden",
                    fontSize: Book.fontSize * 1.35,
                    fontFamily: '"Gentium Book Plus", serif;',
                    top: (100 * Book.marginTop) / 720 + "%",
                    left: (100 * Book.marginLeft) / 720 + "%",
                    bottom: (100 * Book.marginBottom) / 720 + "%",
                    right: (100 * Book.marginRight) / 720 + "%",
                  }}
                >
                  <p> {pageContent.data[imageNumber].value} </p>
                </div>
              ) : null}
            </div>
            {imageNumber > 0 ? (
              <button className="previous-button" onClick={handlePrevious}>
                <GrPrevious />
              </button>
            ) : null}
            {imageNumber < imageArray.length - 1 ? (
              <button className="next-button" onClick={handleNext}>
                <GrNext />
              </button>
            ) : null}
          </>
        )}
      </Card>
    </div>
  );
};

export default HM_BookPageImages;

const styles = {
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "15px",
    },
  }),
};
