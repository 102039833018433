import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import config from "../../config.json";
import "./BookForm.css";

const EditAnimationForm = (props) => {
  console.log("Animation DATA" + props.Animation);
  const AnimationID = props.Animation.AnimationID;
  const BookID = props.Animation.BookID;
  const BookPageID = props.Animation.BookPageID;
  //  debugger;
  const [Name, setName] = useState(props.Animation.Name);
  const [Desc, setDesc] = useState(props.Animation.Description);
  const token = localStorage.getItem("Token");
  const ServerPath = config.rapidServerPath;

  const createNotificationHandler = (type) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "Details changed successfully",
          "Success",
          3000
        );
        break;
      case "error":
        NotificationManager.error("Something went wrong", "error", 3000);
        break;
    }
  };

  async function updateAnimation(e) {
    e.preventDefault();
    let AnimId = props.Animation.AnimationID;
    handleUpdate(AnimId);
  }

  const handleUpdate = async (AnimId) => {
    const response = await axios.post(
      ServerPath + "/api/pagecontent/updatePageAnimation/" + AnimId,
      {
        AnimationID: AnimationID,
        BookID: BookID,
        BookPageID: BookPageID,
        Name: Name,
        Description: Desc,
      },
      {
        headers: {
          Authorization: token,
          Apikey: "XYZ",
        },
      }
    );
    //  props.setBook({ label: "Select Book", value: "" });
    props.sendUpdate(true);
    if (response.data.success === true) {
      createNotificationHandler("success");
    } else {
      createNotificationHandler("error");
    }
    props.closeModel();
  };

  return (
    <div className="animation-container">
      <div className="content">
        <form onSubmit={(e) => updateAnimation(e)}>
          <div className="input-group">
            <input
              id="animid"
              placeholder="Animation Id"
              value={AnimationID}
              type="hidden"
              readOnly
            />
            <label htmlFor="animid">Animation ID</label>
          </div>
          <div className="input-group">
            <input
              id="bookid"
              placeholder="Book Id"
              value={BookID}
              type="hidden"
              readOnly
            />
            <label htmlFor="bookid">Book ID</label>
          </div>
          <div className="input-group">
            <input
              id="bookpageid"
              placeholder="Book Page Id"
              value={BookPageID}
              type="hidden"
              readOnly
            />
            <label htmlFor="bookpageid">Book page ID</label>
          </div>
          <div className="input-group">
            <input
              id="name"
              placeholder="Name"
              value={Name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <label htmlFor="name">Name</label>
          </div>
          <div className="input-group">
            <input
              id="description"
              placeholder="Description"
              value={Desc}
              onChange={(e) => setDesc(e.target.value)}
            />
            <label htmlFor="description">Description</label>
          </div>
          <br />
          <button className="button" type="submit">
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditAnimationForm;
