import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import FormWithoutLabel from "./FormWithoutLabel";
import BlockForm from "./BlockForm";
import BasicForm from "./BasicForm";
import UsingTheGrid from "../FormLayout/UsingTheGrid";
import HorizontalForm from "./HorizontalForm";
import InlineForm from "../FormLayout/InlineForm";

export default class FormLayout extends Component {
  render() {
    return (
      <Container fluid>
        <Row style={{ marginBottom: 35 }}>
          <Col>
            <InlineForm />
          </Col>
        </Row>
        <Row>
          <Col style={{ marginLeft: 16, marginRight: 16 }}>
            <Row style={{ marginBottom: 35 }}>
              <HorizontalForm />
            </Row>
            <Row style={{ marginBottom: 35 }}>
              <BasicForm />
            </Row>
            <Row style={{ marginBottom: 80 }}>
              <UsingTheGrid />
            </Row>
          </Col>
          <Col style={{ marginRight: 16, marginLeft: 16, marginBottom: 35 }}>
            <Row style={{ marginBottom: 35 }}>
              <FormWithoutLabel />
            </Row>
            <Row style={{ marginBottom: 35 }}>
              <BlockForm />
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
