import React from "react";
import { Row, Col, Button } from "reactstrap";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import uuid from "react-uuid";
import s from "./Notifications.module.scss";

class Notifications extends React.Component {
  state = {
    options: {
      position: "top-right",
      autoClose: 5000,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
    },
  };

  componentDidMount() {
    toast.success("Thanks for checking out Messenger!", {
      position: "bottom-right",
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
    });
  }

  addSuccessNotification = () =>
    toast.success(
      "Showing success message was successful!",
      this.state.options
    );

  toggleLocation = (location) => {
    this.setState((prevState) => ({
      options: {
        ...prevState.options,
        position: location,
      },
    }));
  };

  addInfoNotification = () => {
    let id = uuid();
    toast.info(
      <div>
        Launching thermonuclear war...
        <Button
          onClick={() => this.launchNotification(id)}
          outline
          size="xs"
          className="width-100 mb-xs mr-xs mt-1"
        >
          Cancel launch
        </Button>
      </div>,
      { ...this.state.options, toastId: id }
    );
  };

  launchNotification = (id) =>
    toast.update(id, {
      ...this.state.options,
      render: "Thermonuclear war averted",
      type: toast.TYPE.SUCCESS,
    });

  addErrorNotification = () => {
    let id = uuid();
    toast.error(
      <div>
        Error destroying alien planet <br />
        <Button
          onClick={() => this.retryNotification(id)}
          outline
          size="xs"
          className="width-100 mb-xs mr-xs mt-1"
        >
          Retry
        </Button>
      </div>,
      { ...this.state.options, toastId: id }
    );
  };

  retryNotification = (id) =>
    toast.update(id, {
      ...this.state.options,
      render: "Alien planet destroyed!",
      type: toast.TYPE.SUCCESS,
    });

  render() {
    return (
      <div className={s.root}>
        <h1 className="page-title" style={{ color: "#17a2b8" }}>
          Messages - <span className="fw-semi-bold">Notifications</span>
        </h1>

        <Row>
          <Col lg="4" xs="12">
            <h5 className="m-t-1" style={{ color: "#dc3545" }}>
              Layout options
            </h5>
            <p style={{ color: "#28a745" }}>
              There are few position options available for notifications. You
              can click any of them to change notifications position:
            </p>
            <div className="location-selector">
              <div
                className="bit top left"
                onClick={() => {
                  this.toggleLocation("top-left");
                }}
              />
              <div
                className="bit top right"
                onClick={() => {
                  this.toggleLocation("top-right");
                }}
              />
              <div
                className="bit top"
                onClick={() => {
                  this.toggleLocation("top-center");
                }}
              />
              <div
                className="bit bottom left"
                onClick={() => {
                  this.toggleLocation("bottom-left");
                }}
              />
              <div
                className="bit bottom right"
                onClick={() => {
                  this.toggleLocation("bottom-right");
                }}
              />
              <div
                className="bit bottom"
                onClick={() => {
                  this.toggleLocation("bottom-center");
                }}
              />
            </div>
          </Col>

          <Col lg="4" xs="12">
            <h5 className="m-t-1" style={{ color: "#dc3545" }}>
              Notification Types
            </h5>
            <p style={{ color: "#28a745" }}>
              Different types of notifications for lost of use cases. Custom
              classes are also supported.
            </p>
            <p style={{ color: "#28a745" }}>
              <Button
                color="info"
                id="show-info-message"
                onClick={this.addInfoNotification}
              >
                Info Message
              </Button>
            </p>
            <p style={{ color: "#28a745" }}>
              <Button
                color="danger"
                id="show-error-message"
                onClick={this.addErrorNotification}
              >
                Error + Retry Message
              </Button>
            </p>
            <p style={{ color: "#28a745" }}>
              <Button
                color="success"
                id="show-success-message"
                onClick={this.addSuccessNotification}
              >
                Success Message
              </Button>
            </p>
          </Col>

          <Col lg="4" xs="12">
            <h5 className="m-t-1" style={{ color: "#dc3545" }}>
              Dead Simple Usage
            </h5>
            <p style={{ color: "#28a745" }}>
              Just few lines of code to instantiate a notifications object. Does
              not require passing any options:
            </p>
            <pre className={s.notificationsCode}>
              <code>{'toast("Thanks for checking out Messenger!");'}</code>
            </pre>
            <p style={{ color: "#28a745" }}>More complex example:</p>
            <pre className={s.notificationsCode}>
              <code>
                {
                  "\ntoast.success( 'There was an explosion while processing your request.', { \n position: location,\n autoClose: 5000, \n hideProgressBar: false, \n closeOnClick: true,\n pauseOnHover: true, \n draggable: true \n});\n\n"
                }
              </code>
            </pre>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Notifications;
