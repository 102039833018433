import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../ngx/ngx.scss";
import MultipleYAxis from "../ApexChart/MultipleYAxis";
import MixedChart from "../ApexChart/MixedChart";
export default class SimpleSlider extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <Slider {...settings} style={{ height: "auto" }}>
        <div>
          <h3 className="Carousal-heading">
            XYZ - Stock Analysis (2009 - 2016)
          </h3>
          <MultipleYAxis />
        </div>
        <div>
          <h3 className="Carousal-heading">
            XYZ - Stock Analysis (2009 - 2016)
          </h3>
          <MixedChart />
        </div>
      </Slider>
    );
  }
}
