import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./global.css";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import HMRLogin from "./components/Auth/HMRLogin";
import HMRRegister from "./components/Auth/HMRRegister";
import Admin from "./components/ngx/ngx";
import ProtectedRoute from "./services/ProtectedRoutes";
import "./services/interceptor";
import HMRVerification from "./components/Auth/HMRVerification";
import "./assets/Fonts/Futura-Book-font.ttf";

export default function App() {
  useEffect(() => {
    return () => {
      loading();
    };
  }, []);

  const loading = (props) => {
    return <div> i am loading</div>;
  };

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={HMRLogin} />
        <Route path="/register/:email/:name/:age" component={HMRRegister} />
        <Route path="/verify/:email" component={HMRVerification} />
        <Route path="/verify" component={HMRVerification} />
        <Route path="/register" component={HMRRegister} />

        <ProtectedRoute path="/ngx" component={Admin} />
        <Redirect from="/" to="/ngx/Home" />
      </Switch>
      <NotificationContainer />
    </BrowserRouter>
  );
}
