import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Form, Row, Col } from "react-bootstrap";
// import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function DefaultInput() {
  const classes = useStyles();
  // const [dense, setDense] = React.useState(false);
  // const [secondary, setSecondary] = React.useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.demo}>
        <Card className="cards">
          <Form>
            <h6
              class="heading"
              style={{
                paddingTop: "1rem",
                marginLeft: "1.5rem",
                marginBottom: 15,
              }}
            >
              Validation States
            </h6>
            <hr style={{ marginTop: 0, marginBottom: 20 }} />
            <Form.Group
              controlId="exampleForm.ControlInput1"
              style={{ marginRight: 20, marginLeft: 20, marginTop: 20 }}
            >
              <Form.Control
                type=" text"
                placeholder="Input with Info"
                style={{ border: "1px solid #428bca" }}
                className="form-input"
              />
            </Form.Group>
            <Form.Group
              controlId="exampleForm.ControlInput1"
              style={{ marginRight: 20, marginLeft: 20, marginTop: 20 }}
            >
              <Form.Control
                type=" text"
                placeholder="Warning Input"
                style={{ border: "1px solid #ffcc00" }}
                className="form-input"
              />
            </Form.Group>
            <Form.Group
              controlId="exampleForm.ControlInput1"
              style={{ marginRight: 20, marginLeft: 20, marginTop: 20 }}
            >
              <Form.Control
                type=" text"
                placeholder="Danger Input"
                style={{ border: "1px solid #c91d12" }}
                className="form-input"
              />
            </Form.Group>
            <Form.Group
              controlId="exampleForm.ControlInput1"
              style={{ marginRight: 20, marginLeft: 20, marginTop: 20 }}
            >
              <Form.Control
                type=" text"
                placeholder="Danger Input"
                style={{ border: "1px solid #c91d12" }}
                className="form-input"
              />
            </Form.Group>
            <Form.Group
              controlId="exampleForm.ControlInput1"
              style={{ marginRight: 20, marginLeft: 20, marginTop: 20 }}
            >
              <Form.Control
                type=" text"
                placeholder="Input with Primary"
                style={{ border: "1px solid #0000FF" }}
                className="form-input"
              />
            </Form.Group>
            <Row style={{ marginRight: 10, marginLeft: 10, marginTop: 20 }}>
              <Col
                xs=""
                className="my-1"
                style={{ marginRight: 5, marginLeft: 5 }}
              >
                <FormControlLabel
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={<Checkbox />}
                  label="Success CheckBox"
                  className="check"
                />
              </Col>
              <Col
                xs=""
                className="my-1"
                style={{ marginRight: 5, marginLeft: 5 }}
              >
                <FormControlLabel
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={<Checkbox />}
                  label="Warning CheckBox"
                  className="check"
                />
              </Col>
              <Col
                xs=""
                className="my-1"
                style={{ marginRight: 5, marginLeft: 5 }}
              >
                <FormControlLabel
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={<Checkbox />}
                  label="Danger CheckBox"
                  className="check"
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
}
