import React, { useState } from "react";
import { Progress } from "reactstrap";
import { Card } from "react-bootstrap";
import { GoPlus } from "react-icons/go";
import { GoDash } from "react-icons/go";

const InteractiveProgressBar = (props) => {
  const [progress, setProgress] = useState(25);

  const progressReduceHandler = () => {
    setProgress(progress - 25);
  };

  const progressIncreaseHandler = () => {
    setProgress(progress + 25);
  };

  let progressBar = (
    <Progress color="danger" value={progress}>
      {progress}%
    </Progress>
  );

  if (progress < 25) {
    progressBar = <Progress color="danger" value={0} />;
  } else if (progress === 25) {
    progressBar = (
      <Progress color="danger" value={progress}>
        {progress}%
      </Progress>
    );
  } else if (progress === 50) {
    progressBar = (
      <Progress color="warning" value={progress}>
        {progress}%
      </Progress>
    );
  } else if (progress === 75) {
    progressBar = (
      <Progress color="info" value={progress}>
        {progress}%
      </Progress>
    );
  } else {
    progressBar = (
      <Progress color="success" value={progress}>
        100%
      </Progress>
    );
  }

  return (
    <Card>
      <h5 class="heading" style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}>
        Progress Bar Interactive
      </h5>
      <hr style={{ marginTop: 0, marginBottom: 0 }} />
      <div style={{ display: "flex" }}>
        <GoDash
          className="progress-icons"
          style={{ marginLeft: "2.5%" }}
          onClick={progressReduceHandler}
        />
        <div className="color" style={{ width: "90%" }}>
          {progressBar}
        </div>
        <GoPlus className="progress-icons" onClick={progressIncreaseHandler} />
      </div>
    </Card>
  );
};

export default InteractiveProgressBar;
