import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Card1 from "../HelperComponents/FlipCard/Card1";
import Card2 from "../HelperComponents/FlipCard/Card2";
import Card3 from "../HelperComponents/FlipCard/Card3";
import SimpleTab from "../HelperComponents/DashboardTabs/SimpleTab";
import VerticalFlipCard from "../HelperComponents/VerticalFlipCard/VerticalFlipCard";
import SimpleTable from "../HelperComponents/DashboardTables/SimpleTable";
import SimpleSlider from "../HelperComponents/DashboardCarousel/DashboardCarousel";
import ComplexGrid from "../HelperComponents/IOTCard/IOTCard1";
import ComplexGrid2 from "../HelperComponents/IOTCard/IOTCard2";
import Room3 from "../HelperComponents/IOTCard/IOTCard3";
import Room4 from "../HelperComponents/IOTCard/IOTCard4";
import Room5 from "../HelperComponents/IOTCard/IOTCard5";
import Room6 from "../HelperComponents/IOTCard/IOTCard6";
export default class EcomerseDashboard extends Component {
  render() {
    return (
      <Container fluid>
        {/* <Row style={{ marginBottom: 35 }}>
          <Col  sm={6} xs={12} >
            <ComplexGrid/>
          </Col>
          <Col  sm={6} xs={12}>
            <ComplexGrid2/>
          </Col>
        </Row>
        <Row style={{ marginBottom: 40 }}>
          <Col  sm={6} xs={12} >
            <Room3/>
          </Col>
          <Col  sm={6} xs={12}>
            <Room4/>
          </Col>
        </Row> */}
        {/* <Row style={{ marginBottom: 40 }}>
          <Col  sm={6} xs={12} >
            <Room5/>
          </Col>
          <Col  sm={6} xs={12}>
            <Room6/>
          </Col>
        </Row> */}
        <Row>
          <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            <Card1 />
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            <Card2 />
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            <Card3 />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={8} xl={8}>
            <Card>
              <SimpleTab />
            </Card>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            <VerticalFlipCard />
          </Col>
        </Row>
        <Row style={{ marginBottom: 35 }}>
          <Col xs={12} sm={12} md={12} lg={8} xl={8}>
            <Card>
              <SimpleSlider />
            </Card>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            <SimpleTable />
          </Col>
        </Row>
        <Row style={{ marginBottom: 35 }}>
          <Col lg={12}>
            <Card></Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
