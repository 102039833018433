import React, { useState, useRef, useEffect, Fragment } from "react";
import { Card, Button } from "react-bootstrap";
import axios from "axios";
import config from "../../config.json";
import "./Image.css";
import Select from "react-select";
import ReactToPrint from "react-to-print";
import { GrNext, GrPrevious } from "react-icons/gr";
import { FiDownload } from "react-icons/fi";
import { AiFillPrinter } from "react-icons/ai";
import FileSaver from "file-saver";

const ColorPages = () => {
  const [imageNumber, setImageNumber] = useState(1);
  const ServerPath = config.rapidServerPath;
  const [src, setSRC] = useState("");
  const [Books, setBooks] = useState({ options: [] });
  const [selectedBook, setSelectedBook] = useState({
    label: "Select Book",
    value: "",
  });
  const [selectedBookData, setSelectedBookData] = useState({ data: [] });
  const componentRef = useRef();

  async function getBookDetails(BookName) {
    const response = await axios.get(
      ServerPath + "/api/books/getBookByName/" + BookName,
      {
        headers: {
          apikey: "XYZ",
        },
      }
    );
    giveImage(BookName, response.data.ImagePath, imageNumber);
    setSelectedBookData({ data: response.data });
  }
  const fetchAndPrintPDF = async () => {
    try {
      const response1 = await axios.get(
        ServerPath + "/api/books/getBookByName/" + selectedBook.label,
        {
          headers: {
            apikey: "XYZ",
          },
        }
      );
      const response = await axios.get(
        ServerPath +
          `/api/file/assets/book-photos/${response1.data.ImagePath}/${selectedBook.label}/COLORING/Coloring/pdf`,
        {
          responseType: "arraybuffer",
        }
      );
      console.log(
        ServerPath +
          `/api/file/assets/book-photos/${response1.data.ImagePath}/${selectedBook.label}/COLORING/Coloring/pdf`,
        "PDF:",
        response.data
      );

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    } catch (error) {
      console.log("Error fetching PDF:", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(ServerPath + "/api/books/getBooks", {
        headers: {
          Apikey: "XYZ",
        },
      });
      const options = [];
      response.data.map((book) => {
        options.push({ label: book.Name, value: book.BookID });
      });
      setBooks({ options: options });
    }
    fetchData();
  }, []);

  const giveImage = (BookName, ImagePath, imageNumber) => {
    setSRC(
      ServerPath +
        `/api/file/assets/book-photos/${ImagePath}/${BookName}/COLORING/C${imageNumber}/JPG`
    );
  };

  const selected = selectedBook;
  const handleSelectChange = (selected) => {
    setSelectedBook(selected);
    getBookDetails(selected.label);
  };

  const handleNext = () => {
    setImageNumber(imageNumber + 1);
    giveImage(
      selectedBook.label,
      selectedBookData.data.ImagePath,
      imageNumber + 1
    );
  };

  const handlePrevious = () => {
    setImageNumber(imageNumber - 1);
    giveImage(
      selectedBook.label,
      selectedBookData.data.ImagePath,
      imageNumber - 1
    );
  };

  const download = () => {
    FileSaver.saveAs(src, "image.png");
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <h5 className="heading">Coloring Pages</h5>
        <div
          style={{
            width: "200px",
            marginLeft: "auto",
            marginRight: "3%",
            marginBottom: 4,
          }}
        >
          {selectedBook.value != "" ? (
            <div>
              <Button onClick={fetchAndPrintPDF}>Print complete pdf</Button>
            </div>
          ) : null}
        </div>
        <div
          style={{
            width: "200px",
            marginLeft: "auto",
            marginRight: "3%",
            marginBottom: 4,
          }}
        >
          <Select
            styles={styles}
            isSearchable={false}
            options={Books.options}
            value={selectedBook}
            onChange={handleSelectChange}
            components={{
              IndicatorSeparator: () => null,
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: ".25rem",
              colors: {
                ...theme.colors,
                primary25: "#adb5bd",
                primary: "#007bff",
              },
            })}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
      </div>
      {src === "" ? null : (
        <Fragment>
          <Card ref={componentRef}>
            <button className="button-download" onClick={(e) => download(e)}>
              <FiDownload size={30} />
            </button>
            <ReactToPrint
              trigger={() => (
                <button className="button-print">
                  <AiFillPrinter size={30} />
                </button>
              )}
              content={() => componentRef.current}
            />
            {imageNumber > 1 ? (
              <button className="previous-button" onClick={handlePrevious}>
                <GrPrevious />
              </button>
            ) : null}
            <img src={src} className="color-image" />
            {imageNumber < selectedBookData.data.ColorPageCount ? (
              <button className="next-button" onClick={handleNext}>
                <GrNext />
              </button>
            ) : null}
          </Card>
        </Fragment>
      )}
    </div>
  );
};

export default ColorPages;
const styles = {
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "15px",
    },
  }),
};
