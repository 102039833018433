import React from "react";
import { getTheme } from "../../common";
import { Card } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../HM_Books/aggrid.css";
import { presentTheme } from "../../content/Topbar";

const HMRGrid = (props) => {
  var gridClass;
  if (presentTheme == "light") {
    gridClass = "ag-theme-alpine";
  } else {
    gridClass = "ag-theme-alpine-dark";
  }
  return (
    <Card className={gridClass} style={{ width: "auto", height: 620 }}>
      <AgGridReact
        rowData={props.rowData}
        gridOptions={props.gridOptions}
      ></AgGridReact>
    </Card>
  );
  //  }
};
export default HMRGrid;
