import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Form,
  Col,
  Button,
  FormControl,
  InputGroup,
} from "react-bootstrap";
// import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function UsingTheGrid() {
  const classes = useStyles();
  // const [dense, setDense] = React.useState(false);
  // const [secondary, setSecondary] = React.useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.demo}>
        <Card lg={12} className="cards">
          <Form>
            <h6
              class="heading"
              style={{
                paddingTop: "1rem",
                marginLeft: "1.5rem",
                marginBottom: 15,
              }}
            >
              Inline Form
            </h6>
            <hr style={{ marginTop: 0, marginBottom: 0 }} />
            <Form.Row
              className="align-items-center"
              style={{
                marginRight: 20,
                marginLeft: 20,
                marginTop: 10,
                marginBottom: 20,
              }}
            >
              <Col lg={4} xs={5} style={{ marginRight: 5 }}>
                <Form.Control
                  id="inlineFormInputName"
                  placeholder="Jane Doe"
                  className="form-layout"
                />
              </Col>
              <Col lg={4} xs={5}>
                <InputGroup>
                  <FormControl
                    id="inlineFormInputGroupUsername"
                    placeholder="Email"
                    className="form-layout"
                  />
                </InputGroup>
              </Col>
              <Col style={{ marginRight: 1, marginLeft: 1 }}>
                <FormControlLabel
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={<Checkbox />}
                  label="Remember Me"
                  className="check"
                />
              </Col>
              <Col className="my-1" style={{ marginLeft: 5 }}>
                <Button
                  variant="primary"
                  style={{ marginLeft: 20, marginTop: 10 }}
                >
                  SUBMIT
                </Button>{" "}
              </Col>
            </Form.Row>
          </Form>
        </Card>
      </div>
    </div>
  );
}
