import React from "react";
import ReactCardFlip from "react-card-flip";
import ReactApexChart from "react-apexcharts";
import LineChart from "../ApexChart/LineChart";
import { BsFillCaretRightFill } from "react-icons/bs";

class Card2 extends React.Component {
  constructor() {
    super();
    this.state = {
      isFlipped: false,
      seriesSpark3: [
        {
          data: [20, 72, 30, 45, 24, 69, 50, 86, 9, 25, 60],
        },
      ],
      optionsSpark3: {
        chart: {
          type: "area",
          height: 160,
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: "smooth",
        },
        fill: {
          opacity: 0.3,
        },
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          min: 0,
        },
        colors: ["#00e396"],
        title: {
          text: "$135,965",
          offsetX: 0,
          style: {
            fontSize: "24px",
          },
        },
        subtitle: {
          text: "Profits",
          offsetX: 0,
          style: {
            fontSize: "14px",
          },
        },
      },
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.setState((prevState) => ({ isFlipped: !prevState.isFlipped }));
  }

  render() {
    return (
      <ReactCardFlip
        isFlipped={this.state.isFlipped}
        flipDirection="horizontal"
      >
        <div className="Ecom-flip-card">
          <BsFillCaretRightFill
            className="Flip-arrow"
            onClick={this.handleClick}
            style={{
              marginTop: "10px",
              marginLeft: "92%",
              marginBottom: "15px",
            }}
          />
          <div id="chart-spark3">
            <ReactApexChart
              options={this.state.optionsSpark3}
              series={this.state.seriesSpark3}
              type="area"
              height={160}
            />
          </div>
        </div>

        <div className="Ecom-flip-card">
          <BsFillCaretRightFill
            className="Flip-arrow"
            onClick={this.handleClick}
            style={{
              marginTop: "10px",
              marginLeft: "92%",
              marginBottom: "15px",
            }}
          />
          <LineChart />
        </div>
      </ReactCardFlip>
    );
  }
}

export default Card2;
