import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Form, Col } from "react-bootstrap";
// import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function DefaultInput() {
  const classes = useStyles();
  // const [dense, setDense] = React.useState(false);
  // const [secondary, setSecondary] = React.useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.demo}>
        <Card className="cards">
          <Form>
            <h6
              class="heading"
              style={{
                paddingTop: "1rem",
                marginLeft: "1.5rem",
                marginBottom: 15,
              }}
            >
              Select
            </h6>
            <hr style={{ marginTop: 0, marginBottom: 20 }} />
            <Form.Group
              as={Col}
              controlId="formGridState"
              style={{ width: 150 }}
            >
              <Form.Control
                as="select"
                defaultValue="Option 1"
                className="form-input"
              >
                <option>Option 1</option>
                <option>Option 2</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Card>
      </div>
    </div>
  );
}
