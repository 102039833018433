import React from "react";
import ReactApexChart from "react-apexcharts";

class GroupedBarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series1: [
        {
          name: "Payment",
          data: [31, 40, 28, 51, 42, 109, 100],
        },
        {
          name: "Canceled",
          data: [11, 32, 45, 32, 34, 52, 41],
        },
        {
          name: "All Orders",
          data: [89, 46, 13, 72, 17, 99, 43],
        },
      ],
      options1: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            vertical: true,
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
      },
      series2: [
        {
          name: "Payment",
          data: [95, 80, 68, 71, 32, 19, 88, 61, 38, 98, 23, 55],
        },
        {
          name: "Canceled",
          data: [45, 14, 33, 56, 72, 66, 9, 33, 44, 17, 61, 28],
        },
        {
          name: "All Orders",
          data: [140, 94, 101, 127, 104, 85, 97, 82, 115, 84, 83, 100],
        },
      ],
      options2: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            vertical: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },
      series3: [
        {
          name: "Payment",
          data: [60, 75, 90, 34, 58, 100, 49],
        },
        {
          name: "Canceled",
          data: [45, 32, 50, 63, 27, 40, 55],
        },
        {
          name: "All Orders",
          data: [105, 110, 140, 97, 85, 140, 104],
        },
      ],
      options3: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            vertical: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: ["2014", "2015", "2016", "2017", "2018", "2019", "2020"],
        },
      },
      series4: [
        {
          name: "Payment",
          data: [31, 40, 28, 51, 42, 109, 100],
        },
      ],
      options4: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            vertical: true,
          },
        },
        colors: ["#feb019"],
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
      },
      series5: [
        {
          name: "Payment",
          data: [95, 80, 68, 71, 32, 19, 88, 61, 38, 98, 23, 55],
        },
      ],
      options5: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            vertical: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: ["#feb019"],
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },
      series6: [
        {
          name: "Payment",
          data: [60, 75, 90, 34, 58, 100, 49, 82, 22],
        },
      ],
      options6: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            vertical: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: ["#feb019"],
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: [
            "2012",
            "2013",
            "2014",
            "2015",
            "2016",
            "2017",
            "2018",
            "2019",
            "2020",
          ],
        },
      },
    };
  }

  render() {
    var chart = (
      <div id="chart">
        <ReactApexChart
          options={this.state.options1}
          series={this.state.series1}
          type="bar"
          height={350}
        />
      </div>
    );
    if (this.props.value === 2) {
      chart = (
        <div id="chart">
          <ReactApexChart
            options={this.state.options2}
            series={this.state.series2}
            type="bar"
            height={350}
          />
        </div>
      );
    } else if (this.props.value === 3) {
      chart = (
        <div id="chart">
          <ReactApexChart
            options={this.state.options3}
            series={this.state.series3}
            type="bar"
            height={350}
          />
        </div>
      );
    } else if (this.props.value === 4) {
      chart = (
        <div id="chart">
          <ReactApexChart
            options={this.state.options4}
            series={this.state.series4}
            type="bar"
            height={369}
          />
        </div>
      );
    } else if (this.props.value === 5) {
      chart = (
        <div id="chart">
          <ReactApexChart
            options={this.state.options5}
            series={this.state.series5}
            type="bar"
            height={350}
          />
        </div>
      );
    } else if (this.props.value === 6) {
      chart = (
        <div id="chart">
          <ReactApexChart
            options={this.state.options6}
            series={this.state.series6}
            type="bar"
            height={350}
          />
        </div>
      );
    }

    return <div id="chart">{chart}</div>;
  }
}

export default GroupedBarChart;
