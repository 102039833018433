import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import IOT_DashboardSimpleTab from "../HelperComponents/DashboardTabs/IOT_DashboardSimpleTab";
// import GradientLineChart from "../HelperComponents/ApexChart/GradientLineChart";
import { Card } from "react-bootstrap";
import SimpleTabs from "../HelperComponents/IOTTab/IOTTab";
import ComplexGrid from "../HelperComponents/IOTCard/IOTCard1";
import ComplexGrid2 from "../HelperComponents/IOTCard/IOTCard2";
import Room3 from "../HelperComponents/IOTCard/IOTCard3";
import Room4 from "../HelperComponents/IOTCard/IOTCard4";
import Room5 from "../HelperComponents/IOTCard/IOTCard5";
import Room6 from "../HelperComponents/IOTCard/IOTCard6";
import LineChart from "../HelperComponents/IOTChart/IOTLineChart";
import ApexChart from "../HelperComponents/IOTChart/IOTAreaChart";
export default class IOT_Dashboard extends Component {
  render() {
    return (
      <Container fluid>
        <Row>
          <Col
            style={{ marginBottom: 40 }}
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
          >
            <Card>
              <ComplexGrid />
            </Card>
          </Col>
          <Col
            style={{ marginBottom: 40 }}
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
          >
            <Card>
              <ComplexGrid2 />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            style={{ marginBottom: 40 }}
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
          >
            <Card>
              <Room3 />
            </Card>
          </Col>
          <Col
            style={{ marginBottom: 40 }}
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
          >
            <Card>
              <Room4 />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            style={{ marginBottom: 40 }}
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
          >
            <Card>
              <Room5 />
            </Card>
          </Col>
          <Col
            style={{ marginBottom: 40 }}
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
          >
            <Card>
              <Room6 />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            style={{ marginBottom: 40 }}
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
          >
            <IOT_DashboardSimpleTab />
          </Col>
          <Col
            style={{ marginBottom: 40 }}
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
          >
            <Card>
              <SimpleTabs />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            style={{ marginBottom: 40 }}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
          >
            <LineChart />
          </Col>
          <Col
            style={{ marginBottom: 40 }}
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
          >
            <Card>
              <ApexChart />
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
