import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import CalendarChart from "./CalendarChart";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import NetworkChart from "./NetworkChart";
import VoronoiChart from "./VoronoiChart";

export default function D3Charts() {
  return (
    <Container fluid>
      <Row style={{ marginBottom: 35 }}>
        <Col lg={6}>
          <BarChart />
        </Col>
        <Col lg={6}>
          <PieChart />
        </Col>
      </Row>
      <Row style={{ marginBottom: 35 }}>
        <Col lg={6}>
          <NetworkChart />
        </Col>
        <Col lg={6}>
          <VoronoiChart />
        </Col>
      </Row>
      <Row style={{ marginBottom: 35 }}>
        <Col lg={12}>
          <CalendarChart />
        </Col>
      </Row>
    </Container>
  );
}
