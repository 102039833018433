import React from "react";
import { Progress } from "reactstrap";
import { Card } from "react-bootstrap";

const ColorProgressBar = (props) => {
  return (
    <Card>
      <h5 class="heading" style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}>
        Progress Bar Size
      </h5>
      <hr style={{ marginTop: 0, marginBottom: 0 }} />
      <div className="size">
        <Progress style={{ marginBottom: "1rem", height: "1rem" }} value="20">
          Tiny
        </Progress>
        <Progress
          style={{ marginBottom: "1rem", height: "1.25rem" }}
          value="40"
        >
          Small
        </Progress>
        <Progress
          style={{ marginBottom: "1rem", height: "1.375rem" }}
          value="60"
        >
          Medium
        </Progress>
        <Progress style={{ marginBottom: "1rem", height: "1.5rem" }} value="80">
          Large
        </Progress>
        <Progress
          style={{ marginBottom: "1rem", height: "1.75rem" }}
          value="100"
        >
          Giant
        </Progress>
      </div>
    </Card>
  );
};

export default ColorProgressBar;
