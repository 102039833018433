import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faHome,
  // faBriefcase,
  // faPaperPlane,
  // faQuestion,
  // faImage,
  faBook,
  // faList,
  // faListAlt,
  faThList,
  // faHouseUser,
  faMarker,
  faClipboardList,
  faStore,
  faUser,
  faMailBulk,
  // faRecordVinyl,
  // faCopy,
  // faCartPlus,
  // faArchive,
  // faArchway,
  // faBiohazard,
} from "@fortawesome/free-solid-svg-icons";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
// import { shadows } from "@material-ui/system";
import SubMenu from "./SubMenu";
import "../../global.css";
const SideBar = ({ toggle, isOpen }) => {
  return (
    <div className={classNames("sidebar", { "is-open": isOpen })}>
      <div className="sidebar-header">
        <span color="info" onClick={toggle} style={{ color: "#fff" }}>
          &times;
        </span>
        <h3>HMR-ADMIN</h3>
      </div>
      <div className="side-menu">
        <Nav vertical className="list-unstyled">
          <SubMenu title="Books" icon={faBook} items={submenus[0]} />
          <hr className="divider" />
          {/* <NavItem>
          <NavLink tag={Link} to={"/ngx/HM_Books"}>
            <FontAwesomeIcon icon={faBook} className="mr-2" />
            Book List
          </NavLink>
        </NavItem>
        <hr className="divider" />
        <NavItem>
          <NavLink tag={Link} to={"/ngx/HM_BookPage"}>
            <FontAwesomeIcon icon={faList} className="mr-2" />
            BookPage
          </NavLink>
        </NavItem>
        <hr className="divider" />
        <NavItem>
          <NavLink tag={Link} to={"/ngx/HM_PageContent"}>
            <FontAwesomeIcon icon={faListAlt} className="mr-2" />
            Page Content
          </NavLink>
        </NavItem>
        <hr className="divider" /> */}
          <NavItem>
            <NavLink tag={Link} to={"/ngx/HM_Users"}>
              <FontAwesomeIcon icon={faUser} className="mr-2" />
              Users
            </NavLink>
          </NavItem>
          <hr className="divider" />
          <NavItem>
            <NavLink tag={Link} to={"/ngx/HM_Books"}>
              <FontAwesomeIcon icon={faMailBulk} className="mr-2" />
              Invitation
            </NavLink>
          </NavItem>
          <hr className="divider" />
          <NavItem>
            <NavLink tag={Link} to={"/ngx/HM_Users"}>
              {/* <FontAwesomeIcon icon={faRecordVinyl} className="mr-2" /> */}
              Recordings
            </NavLink>
          </NavItem>
          <hr className="divider" />
          <NavItem>
            <NavLink tag={Link} to={"/ngx/userprofile"}>
              <FontAwesomeIcon icon={faThList} className="mr-2" />
              Subscription
            </NavLink>
          </NavItem>
          <hr className="divider" />
          <NavItem>
            <NavLink tag={Link} to={"/ngx/HM_Books"}>
              <FontAwesomeIcon icon={faStore} className="mr-2" />
              Store
            </NavLink>
          </NavItem>
          <hr className="divider" />
          <NavItem>
            <NavLink tag={Link} to={"/ngx/HM_Users"}>
              {/* <FontAwesomeIcon icon={faHouseUser} className="mr-2" /> */}
              Family
            </NavLink>
          </NavItem>
          <hr className="divider" />
          <NavItem>
            <NavLink tag={Link} to={"/ngx/userprofile"}>
              <FontAwesomeIcon icon={faMarker} className="mr-2" />
              Application
            </NavLink>
          </NavItem>
          <hr className="divider" />
          <NavItem>
            <NavLink tag={Link} to={"/ngx/HM_Books"}>
              <FontAwesomeIcon icon={faClipboardList} className="mr-2" />
              Wishlist
            </NavLink>
          </NavItem>
          <hr className="divider" />
        </Nav>
      </div>
    </div>
  );
};

const submenus = [
  [
    {
      title: "Book List",
      target: "/ngx/HM_Books",
    },
    {
      title: "Book Pages",
      target: "/ngx/HM_BookPage",
    },
    // {
    //   title: "Page Content",
    //   target: "/ngx/HM_PageContent",
    // },
    {
      title: "Page Animations",
      target: "/ngx/HM_Animations",
    },
    {
      title: "Page Animations Parameters",
      target: "/ngx/Animation_Parameters",
    },
    {
      title: "Browse Pages",
      target: "/ngx/HM_BookPageImages",
    },
    {
      title: "Coloring Pages",
      target: "/ngx/HM_ColorPages",
    },
  ],
];

export default SideBar;
