import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Form, Button } from "react-bootstrap";
// import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function FormWithoutLabel() {
  const classes = useStyles();
  // const [dense, setDense] = React.useState(false);
  // const [secondary, setSecondary] = React.useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.demo}>
        <Card className="cards">
          <Form>
            <h6
              class="heading"
              style={{
                paddingTop: "1rem",
                marginLeft: "1.5rem",
                marginBottom: 15,
              }}
            >
              Form Without Label
            </h6>
            <hr style={{ marginTop: 0, marginBottom: 20 }} />
            <Form.Group
              controlId="exampleForm.ControlInput1"
              style={{ marginRight: 20, marginLeft: 20 }}
            >
              <Form.Control
                type=" text"
                placeholder="Recipients"
                className="form-layout"
              />
            </Form.Group>
            <Form.Group
              controlId="exampleForm.ControlInput1"
              style={{ marginRight: 20, marginLeft: 20 }}
            >
              <Form.Control
                type=" text"
                placeholder="Subject"
                className="form-layout"
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Control
                as="textarea"
                placeholder="Message"
                rows="2"
                className="form-layout"
                style={{ width: 200, marginLeft: 20 }}
              />
            </Form.Group>
            <Form.Group>
              <Button variant="success" style={{ marginLeft: 20 }}>
                SEND
              </Button>{" "}
            </Form.Group>
          </Form>
        </Card>
      </div>
    </div>
  );
}
