import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
// import FormGroup from "@material-ui/core/FormGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import Grid from "@material-ui/core/Grid";
// import Typography from "@material-ui/core/Typography";
// import FolderIcon from "@material-ui/icons/Folder";
// import DeleteIcon from "@material-ui/icons/Delete";
import CallIcon from "@material-ui/icons/Call";
import WorkIcon from "@material-ui/icons/Work";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 750,
    maxHeight: 500,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function InteractiveList() {
  const classes = useStyles();
  const [dense, setDense] = React.useState(false);
  // const [secondary, setSecondary] = React.useState(false);

  return (
    <div className={classes.root} style={{ overflowY: "scroll" }}>
      <div className={classes.demo}>
        <List dense={dense}>
          {
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <WorkIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Nick Jones" secondary="Mobile" />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="LocalPhoneIcon">
                  <CallIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          }
        </List>
        <List dense={dense}>
          {
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <WorkIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Eva Moor" secondary="Home" />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="LocalPhoneIcon">
                  <CallIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          }{" "}
        </List>
        <List dense={dense}>
          {
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <WorkIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Jack Williams" secondary="Mobile" />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  style={{ justifyContent: "space-between" }}
                  aria-label="LocalPhoneIcon"
                >
                  <CallIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          }
        </List>
        <List dense={dense}>
          {
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <WorkIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Lee Wong" secondary="Mobile" />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="LocalPhoneIcon">
                  <CallIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          }
        </List>
        <List dense={dense}>
          {
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <WorkIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Alan Thompson" secondary="Home" />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="LocalPhoneIcon">
                  <CallIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          }
        </List>
        <List dense={dense}>
          {
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <WorkIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Kate Martinez" secondary="Work" />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="LocalPhoneIcon">
                  <CallIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          }
        </List>
        <List dense={dense}>
          {
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <WorkIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Marry Thompson" secondary="Home" />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="LocalPhoneIcon">
                  <CallIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          }{" "}
        </List>
        <List dense={dense}>
          {
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <WorkIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Noor Wong" secondary="Home" />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="LocalPhoneIcon">
                  <CallIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          }{" "}
        </List>
      </div>
    </div>
  );
}
