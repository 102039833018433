import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonPinIcon from "@material-ui/icons/PersonPin";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 600,
  },
});

export default function IconLabelTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper square className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
        className="list"
      >
        <Tab icon={<PhoneIcon />} label="RECENTS" className="list-typo" />
        <Tab icon={<FavoriteIcon />} label="FAVORITES" className="list-typo" />
        <Tab icon={<PersonPinIcon />} label="NEARBY" className="list-typo" />
      </Tabs>
    </Paper>
  );
}
