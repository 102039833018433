import React from "react";
import ReactCardFlip from "react-card-flip";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import DoughnutChart from "../ChartJS/DoughnutChart";
import { BsFillCaretRightFill } from "react-icons/bs";
// import { Container, Row, Col } from "react-bootstrap";

const options = [
  { value: 1, label: "Card 1" },
  { value: 2, label: "Card 2" },
  { value: 3, label: "Card 3" },
];

class Card3 extends React.Component {
  constructor() {
    super();
    this.state = {
      isFlipped: false,
      chart: { value: 1, label: "Card 1" },
      seriesOne: [
        {
          data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
        },
      ],
      options: {
        chart: {
          type: "area",
          height: 160,
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: "smooth",
        },
        fill: {
          opacity: 0.3,
        },
        colors: ["#feb019"],
        title: {
          text: "$424,652",
          offsetX: 0,
          style: {
            fontSize: "24px",
          },
        },
        subtitle: {
          text: "Sales",
          offsetX: 0,
          style: {
            fontSize: "14px",
          },
        },
      },
      seriesSpark2: [
        {
          data: [15, 25, 87, 42, 76, 53, 64, 78, 34, 9, 49],
        },
      ],
      optionsSpark2: {
        chart: {
          type: "area",
          height: 160,
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: "smooth",
        },
        fill: {
          opacity: 0.3,
        },
        colors: ["#00B1F2"],
        title: {
          text: "$235,312",
          offsetX: 0,
          style: {
            fontSize: "24px",
          },
        },
        subtitle: {
          text: "Expenses",
          offsetX: 0,
          style: {
            fontSize: "14px",
          },
        },
      },
      seriesSpark3: [
        {
          data: [20, 72, 30, 45, 24, 69, 50, 86, 9, 25, 60],
        },
      ],
      optionsSpark3: {
        chart: {
          type: "area",
          height: 160,
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: "smooth",
        },
        fill: {
          opacity: 0.3,
        },
        colors: ["#00e396"],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        title: {
          text: "$135,965",
          offsetX: 0,
          style: {
            fontSize: "24px",
          },
        },
        subtitle: {
          text: "Profits",
          offsetX: 0,
          style: {
            fontSize: "14px",
          },
        },
      },
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleSelectChange = (selectedChart) => {
    this.setState({ chart: selectedChart });
  };

  handleClick(e) {
    e.preventDefault();
    this.setState((prevState) => ({ isFlipped: !prevState.isFlipped }));
  }

  render() {
    const selectedChart = this.state.chart;
    var chart = (
      <ReactApexChart
        options={this.state.options}
        series={this.state.seriesOne}
        type="area"
        height={158}
      />
    );

    if (this.state.chart.value === 2) {
      chart = (
        <ReactApexChart
          options={this.state.optionsSpark2}
          series={this.state.seriesSpark2}
          type="area"
          height={158}
        />
      );
    } else if (this.state.chart.value === 3) {
      chart = (
        <ReactApexChart
          options={this.state.optionsSpark3}
          series={this.state.seriesSpark3}
          type="area"
          height={158}
        />
      );
    }

    return (
      <ReactCardFlip
        isFlipped={this.state.isFlipped}
        flipDirection="horizontal"
      >
        <div className="Ecom-flip-card">
          <div style={{ display: "flex" }}>
            <div style={{ width: "100px", marginLeft: "10px" }}>
              <Select
                isSearchable={false}
                options={options}
                value={selectedChart}
                onChange={this.handleSelectChange}
                components={{
                  IndicatorSeparator: () => null,
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: ".25rem",
                  colors: {
                    ...theme.colors,
                    primary25: "#adb5bd",
                    primary: "#007bff",
                  },
                })}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <BsFillCaretRightFill
              className="Flip-arrow"
              onClick={this.handleClick}
              style={{
                marginTop: "10px",
                marginLeft: "auto",
                marginRight: "3%",
                marginBottom: "12px",
              }}
            />
          </div>
          <div id="chart-spark1">{chart}</div>
        </div>

        <div className="Ecom-flip-card">
          <BsFillCaretRightFill
            className="Flip-arrow"
            onClick={this.handleClick}
            style={{
              marginTop: "10px",
              marginLeft: "92%",
              marginBottom: "12px",
            }}
          />
          <DoughnutChart />
        </div>
      </ReactCardFlip>
    );
  }
}

export default Card3;
