import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { NotificationManager } from "react-notifications";
import "./styles.css";
import axios from "axios";
import { Button } from "react-bootstrap";
// import Switch from "@material-ui/core/Switch";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
// import FormControl from "@material-ui/core/FormControl";
import LogoImg from "../../../assets/logo.png";
import { FiArrowLeft } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import config from "../../../config.json";
import Select from "react-select";
import { useParams } from "react-router-dom";
import usePasswordToggle from "../../childcomponents/hooks/usePasswordToggle";
// import { useNavigate } from 'react-router-dom';
import { Redirect } from "react-router-dom";
export default function Register() {
  // const navigate = useNavigate();
  const [passwordInputType, ToggleIcon] = usePasswordToggle();
  const [passwordInputTypeOnConfirm, ToggleIconOnConfirm] = usePasswordToggle();
  const { email } = useParams();
  const { name } = useParams();
  const { age } = useParams();
  const [familyName, setFamilyName] = useState("");
  const [mail, setMail] = useState(email || "");
  const [fullName, setFullName] = useState(name || "");
  const [UserAge, setUserAge] = useState(age || "");
  const [inviteCode, setInviteCode] = useState("");
  const [Password, setPassword] = useState("");
  const [pin, setPIN] = useState("");
  // const navigateToVerify = () => {
  // 	navigate('/verify');
  // };
  const [userData, setUserData] = useState({
    Email: "",
    Password: "",
    FullName: "",
    FamilyID: "",
    isActive: 1,
    isReader: 1,
    isChildren: 0,
    DOB: "",
    Mobile: "",
    UserAge: "",
    AvatarID: "",
    UserSubscriptionID: "",
    UserTypeID: "",
  });
  const [familyData, setFamilyData] = useState({
    InvitationID: "",
    FamilyName: "",
    PIN: "",
  });
  var [mailArray, setMailArray] = useState({
    ReceiverEmail: "",
    subject: "One Time Password for Verification on Hearmeread",
  });
  const [FamilyID, setFamilyID] = useState("");
  const [UserSubscriptionID, setUserSubscriptionID] = useState("");
  const [AvatarID, setAvatarID] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isReader, setIsReader] = useState(true);
  const [isChildren, setIsChildren] = useState(false);
  const [Mobile, setMobile] = useState("");
  const [creatingAsFamilyAdmin, setCreatingAsFamilyAdmin] = useState(false);
  const token = localStorage.getItem("Token");
  const history = useHistory();
  const ServerPath = config.rapidServerPath;

  const validateEmail = (value) => {
    let email = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return email.test(value);
  };

  const validatePass = (value) => {
    let pass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&])[A-Za-z\d@#$!%*?&]{6,}$/;
    return pass.test(value);
  };

  const validateContact = (value) => {
    let no = /^[6-9]\d{9}$/;
    return no.test(value);
  };

  const validateAge = (age) => {
    if (age >= 1 && age <= 100) {
      return true;
    } else {
      return false;
    }
  };
  const validatePIN = (value) => {
    let pin = /^[0-9]\d{5}$/;
    console.log("PIN Valid   ", value.length);
    return pin.test(value);
  };
  const createUserFamily = async (userData, familyData, isVerified) => {
    userData.Email = userData.Email.toLowerCase();
    try {
      // var response = { data: { success: true } };
      const response = await axios.post(
        ServerPath + "/api/users/createUserFamily",
        [
          {
            Email: userData.Email,
            Password: userData.Password,
            FullName: userData.FullName,
            UserTypeID: userData.UserTypeID,
            //  userData.selectedUserType.value === "" ? null : userData.selectedUserType.value,
            FamilyID: userData.FamilyID === "" ? null : userData.FamilyID,
            UserSubscriptionID:
              userData.UserSubscriptionID === ""
                ? null
                : userData.UserSubscriptionID,
            AvatarID: userData.AvatarID === "" ? null : userData.AvatarID,
            isActive: Number(userData.isActive),
            isReader: Number(userData.isReader),
            isChildren: Number(userData.isChildren),
            Mobile: userData.Mobile,
            UserAge: Number(userData.UserAge),
            DOB: userData.DOB === "" ? null : userData.DOB,
            isVerified: isVerified,
          },
          {
            InvitationID: familyData.InvitationID,
            FamilyName: familyData.FamilyName,
            FamilyAvatarID: null,
            PIN: familyData.PIN,
          },
        ],
        {
          headers: {
            Apikey: "XYZ",
          },
        }
      );
      if (response.data.success === true) {
        if (userData.UserTypeID == 2) {
          mailArray.ReceiverEmail = userData.Email;
          try {
            var responseEmailSent = await axios.post(
              ServerPath + "/api/users/sendEmailVerification",
              { mailArray },
              {
                headers: {
                  Apikey: "XYZ",
                },
              }
            );
            createNotificationHandler("RegisterNotVerified");
            setUserData({
              Email: "",
              Password: "",
              FullName: "",
              FamilyID: "",
              isActive: 1,
              isReader: 1,
              isChildren: 0,
              DOB: "",
              Mobile: "",
              UserAge: "",
              AvatarID: "",
              UserSubscriptionID: "",
              UserTypeID: "",
            });
            setFamilyData({
              InvitationID: "",
              FamilyName: "",
              PIN: "",
            });
            setInviteCode("");
            setConfirmPassword("");
            if (responseEmailSent.data.success === true) {
              console.log("came here");
              history.push("/verify");
            } else {
              createNotificationHandler("error");
            }
          } catch (error) {
            createNotificationHandler("error");
          }
        } else {
          createNotificationHandler("success");
          setUserData({
            Email: "",
            Password: "",
            FullName: "",
            FamilyID: "",
            isActive: 1,
            isReader: 1,
            isChildren: 0,
            DOB: "",
            Mobile: "",
            UserAge: "",
            AvatarID: "",
            UserSubscriptionID: "",
            UserTypeID: "",
          });
          setFamilyData({
            InvitationID: "",
            FamilyName: "",
            PIN: "",
          });
          setInviteCode("");
          setConfirmPassword("");
          history.push("/login");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const createNotificationHandler = (type) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "User Created successfully. Please Login to Continue",
          "Success",
          3000
        );
        break;
      case "RegisterNotVerified":
        NotificationManager.success(
          "User Created successfully. A Four digit PIN has been sent to your email for verification",
          "Success",
          3000
        );
        break;
      case "unauthorized":
        NotificationManager.error("You are not authorized", "Sorry", 3000);
        break;
      case "error":
        NotificationManager.error("Something went wrong", "error", 3000);
        break;
      case "userExists":
        NotificationManager.error(
          "User with that Email already exists",
          "error",
          3000
        );
        break;
      case "emailError":
        NotificationManager.info(
          "Please Enter Correct EMail",
          "Wrong Email",
          3000
        );
        break;
      case "passwordError":
        NotificationManager.info(
          "Must contain one number, one uppercase letter, one lowercase letter, one special character and at least 6 characters",
          "Password do not match the requirements",
          7000
        );
        break;
      case "isActive":
        NotificationManager.info("isActive must be 0 or 1", "Info", 3000);
        break;
      case "isReader":
        NotificationManager.info("isReader must be 0 or 1", "Info", 3000);
        break;
      case "isChildren":
        NotificationManager.info("isReader must be 0 or 1", "Info", 3000);
        break;
      case "age":
        NotificationManager.info("Age must be between 1 to 100", "info", 3000);
        break;
      case "contactError":
        NotificationManager.info("Enter Correct Contact Number", "Info", 3000);
        break;
      case "mismatchPassword":
        NotificationManager.info(
          "Please confirm the password in both fields are same",
          "Info",
          3000
        );
        break;
      case "PINError":
        NotificationManager.info(
          "Please Enter 6 Digit Numeric PIN",
          "Info",
          3000
        );
        break;
      case "nameError":
        NotificationManager.info(
          "Please Enter Correct Name. You can use Text, Apostrophe and Space only",
          "warning",
          3000
        );
        break;
      case "inviteCode":
        NotificationManager.info(
          "Please Enter Correct Invite Code",
          "warning",
          3000
        );
        break;
      case "PINError":
        NotificationManager.error(
          "Please Enter 6 Digit Numeric PIN",
          "warning",
          3000
        );
        break;
    }
  };
  var isVerified = 0;
  const [confirmPassword, setConfirmPassword] = useState("");

  var setMemberData = async (inviteCode, isVerified) => {
    try {
      const url = ServerPath + "/api/users/getInviteRecord";
      const response = await axios.get(url, {
        headers: {
          Apikey: "XYZ",
        },
      });
      const filteredFamily = response.data.filter(
        (obj) => obj.InviteCode == inviteCode
      );
      if (filteredFamily.length > 0) {
        if (
          userData.Email.toLowerCase() ==
          filteredFamily[0].ReceiverEmail.toLowerCase()
        ) {
          userData.FamilyID = filteredFamily[0].FamilyID;
          familyData.InvitationID = filteredFamily[0].InvitationID;
          createUserFamily(userData, familyData, isVerified);
        } else {
          console.log("ERROR - Email Mismatch");
          createNotificationHandler("emailMismatchError");
        }
      } else {
        console.log("ERROR - Invalid Invite Code");
        createNotificationHandler("inviteCode");
      }
    } catch (err) {
      console.log("ERROR :" + err);
    }
  };

  async function handleCreate(e) {
    e.preventDefault();
    console.log(familyData, userData);
    if (!validatePass(userData.Password)) {
      createNotificationHandler("passwordError");
    } else if (userData.Password != confirmPassword) {
      createNotificationHandler("mismatchPassword");
    } else if (!validatePass(userData.Password)) {
      createNotificationHandler("mismatchPassword");
    } else if (!validateContact(userData.Mobile)) {
      createNotificationHandler("contactError");
    } else if (!validateAge(userData.UserAge)) {
      createNotificationHandler("age");
    } else {
      if (inviteCode) {
        // Register as Member
        //  setIsVerified(1);             // delay in setState
        userData.UserTypeID = 3;
        isVerified = 1;
        setMemberData(inviteCode, isVerified);
      } else if (familyData.FamilyName) {
        if (!validatePIN(familyData.PIN)) {
          createNotificationHandler("PINError");
        } else {
          // Register as FamilyAdmin
          console.log("Family Admin");
          userData.UserTypeID = 2;
          createUserFamily(userData, familyData, isVerified);
        }
      } else {
        createNotificationHandler(
          "error",
          "Please Enter Invite Code Or Family Name And PIN",
          "warning",
          3000
        );
      }
      // const response = await axios.post(
      // 	ServerPath + '/api/users/signup',
      // 	{
      // 		Email: Email,
      // 		Password: Password,
      // 		FullName: FullName,
      // 		UserTypeID: selectedUserType.value === '' ? null : selectedUserType.value,
      // 		FamilyID: FamilyID === '' ? null : FamilyID,
      // 		UserSubscriptionID: UserSubscriptionID === '' ? null : UserSubscriptionID,
      // 		AvatarID: AvatarID === '' ? null : AvatarID,
      // 		isActive: Number(isActive),
      // 		isReader: Number(isReader),
      // 		isChildren: Number(isChildren),
      // 		Mobile: Mobile,
      // 		UserAge: Number(UserAge)
      // 	},
      // 	{
      // 		headers: {
      // 			authorization: token,
      // 			Apikey: 'XYZ'
      // 		}
      // 	}
      // );
      // if (response.data.success === true) {
      // 	createNotificationHandler('success');
      // 	history.push('/login');
      // }
      // } catch (error) {
      // 	if (error.response) {
      // 		NotificationManager.error(error.response.data.error, 'Error', 3000);
      // 		console.log(error);
      // 	}
      // }
    }
  }

  return (
    <div className="register-container">
      <div className="content">
        <section>
          <img src={LogoImg} height="200" alt="Register now" />

          <h1>Register</h1>
          <p>Browse the books and print coloring pages.</p>

          <Link
            style={{ margin: 0, width: "40%" }}
            className="back-link"
            to="/login"
          >
            <Button className="button" variant="outline-danger" type="button">
              Cancel
            </Button>
          </Link>
        </section>

        <form onSubmit={handleCreate}>
          <div className="input-group">
            <input
              value={userData.FullName}
              onChange={(e) =>
                setUserData({ ...userData, FullName: e.target.value })
              }
              id="fullName"
              type="text"
              placeholder="Full Name"
            />
            <label htmlFor="fullName">Full Name</label>
          </div>
          <div className="input-group">
            <input
              value={userData.Email}
              onChange={(e) =>
                setUserData({ ...userData, Email: e.target.value })
              }
              id="email"
              type="text"
              placeholder="Full Name"
            />
            <label htmlFor="email">Email</label>
          </div>
          <div className="input-group">
            <input
              id="age"
              value={userData.UserAge}
              onChange={(e) =>
                setUserData({ ...userData, UserAge: e.target.value })
              }
              type="text"
              placeholder="age"
            />
            <label htmlFor="age"> Age </label>
          </div>
          <div className="input-group">
            <input
              value={userData.Mobile}
              onChange={(e) =>
                setUserData({ ...userData, Mobile: e.target.value })
              }
              id="mobile"
              type="number"
              placeholder="Mobile"
            />
            <label htmlFor="mobile">Mobile</label>
          </div>

          <div className="input-group">
            <input
              id="password"
              type={passwordInputType}
              placeholder="PASSWORD"
              value={userData.Password}
              onChange={(e) =>
                setUserData({ ...userData, Password: e.target.value })
              }
              required
            />
            <label htmlFor="password">Password</label>
            <i style={{ marginRight: "10px" }} className="password-toggle-icon">
              {ToggleIcon}
            </i>
          </div>
          <div className="input-group">
            <input
              id="passwordConfirm"
              type={passwordInputTypeOnConfirm}
              placeholder="PASSWORD"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <label htmlFor="passwordConfirm">Confirm Password</label>
            <i style={{ marginRight: "10px" }} className="password-toggle-icon">
              {ToggleIconOnConfirm}
            </i>
          </div>

          <Form.Check
            type="switch"
            value={creatingAsFamilyAdmin}
            onChange={() => {
              setCreatingAsFamilyAdmin(!creatingAsFamilyAdmin);
            }}
            id="custom-switch"
            label={
              creatingAsFamilyAdmin
                ? " Register as new Family Admin with us"
                : `I have an Invite Code `
            }
          />
          {creatingAsFamilyAdmin ? (
            <div className="two-input-group">
              <div className="input-group">
                <input
                  value={familyData.FamilyName}
                  onChange={(e) =>
                    setFamilyData({ ...familyData, FamilyName: e.target.value })
                  }
                  id="familyName"
                  type="text"
                  placeholder="Family Name"
                />
                <label htmlFor="familyName"> Family Name </label>
              </div>
              <div className="input-group">
                <input
                  id="pin"
                  value={familyData.PIN}
                  onChange={(e) =>
                    setFamilyData({ ...familyData, PIN: e.target.value })
                  }
                  type="text"
                  placeholder="pin"
                />
                <label htmlFor="pin"> PIN </label>
              </div>
            </div>
          ) : (
            <div className="input-group">
              <input
                id="inviteCode"
                value={inviteCode}
                onChange={(e) => {
                  setInviteCode(e.target.value);
                }}
                type="text"
                placeholder="Invite Code"
              />
              <label htmlFor="inviteCode"> Invite Code </label>
            </div>
          )}

          <button className="button" style={{ width: "97%" }} type="submit">
            Register
          </button>
        </form>
      </div>
    </div>
  );
}
