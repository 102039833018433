import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import FormDatePicker from "./DatePicker";
import RangePicker from "./RangePicker";

const FormDates = () => {
  return (
    <Container fluid>
      <Row>
        <Col lg={4}>
          <FormDatePicker />
        </Col>
        <Col lg={4}>
          <RangePicker />
        </Col>
      </Row>
    </Container>
  );
};

export default FormDates;
