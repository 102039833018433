import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Form, Button, Row } from "react-bootstrap";
// import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function BasicForm() {
  const classes = useStyles();
  // const [dense, setDense] = React.useState(false);
  // const [secondary, setSecondary] = React.useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.demo}>
        <Card className="cards">
          <Form>
            <h6
              class="heading"
              style={{
                paddingTop: "1rem",
                marginLeft: "1.5rem",
                marginBottom: 15,
              }}
            >
              Basic Form
            </h6>
            <hr style={{ marginTop: 0, marginBottom: 20 }} />
            <Form.Group
              controlId="formBasicEmail"
              style={{ marginRight: 20, marginLeft: 20 }}
            >
              <Form.Label className="form-text">Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                className="form-layout"
              />
            </Form.Group>

            <Form.Group
              controlId="formBasicPassword"
              style={{ marginRight: 20, marginLeft: 20, marginBottom: 20 }}
            >
              <Form.Label className="form-text">Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                className="form-layout"
              />
            </Form.Group>
            <Row xs="" className="my-1" style={{ marginLeft: 20 }}>
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox />}
                label="Check Me Out"
                className="check"
              />
            </Row>
            <Form.Group>
              <Button
                variant="danger"
                style={{ marginLeft: 16, marginTop: 20, marginBottom: 20 }}
              >
                SUBMIT
              </Button>{" "}
            </Form.Group>
          </Form>
        </Card>
      </div>
    </div>
  );
}
