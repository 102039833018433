import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Modal,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import EditBookForm from "./EditBookForm";
import DetailsForm from "./DetailsForm";
import CreateBookForm from "./CreateBookForm";
import config from "../../config.json";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "./aggrid.css";
import HMRGrid from "../childcomponents/Grid/HMRGrid";

const App = () => {
  const [isUpdated, setIsUpdated] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedBook, setSelectedBook] = useState({ data: [] });
  const [selectedDetails, setDetails] = useState({ data: [] });
  const [rowData, setRowData] = useState({ data: [] });

  const token = localStorage.getItem("Token");
  const ServerPath = config.rapidServerPath;

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(ServerPath + "/api/books/getBooks", {
        headers: {
          //Authorization: token,
          Apikey: "XYZ",
        },
      });
      setRowData({ data: response.data });
    }
    fetchData();
    setIsUpdated(false);
  }, [isUpdated]);

  const gridOptions = {
    columnDefs: [
      {
        field: "Change field",
        cellRendererFramework: function (params) {
          return (
            <>
              <Button
                style={{
                  width: 50,
                  marginRight: 5,
                }}
                variant="primary"
                size="sm"
                onClick={() => handleUpdateOpen(params.data)}
              >
                Edit
              </Button>
              <i />
              <Button
                style={{
                  width: 80,
                  marginLeft: 5,
                  background: "green",
                }}
                variant="primary"
                size="sm"
                onClick={() => handleDetailsOpen(params.data)}
              >
                Details
              </Button>
            </>
          );
        },
        width: 200,
        resizable: true,
      },
      {
        field: "BookID",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "Name",
        width: 150,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "ImagePath",
        cellRendererFramework: function (params) {
          return (
            <OverlayTrigger
              trigger={["hover", "focus"]}
              key="left"
              placement="left"
              overlay={
                <Popover id={`popover-positioned-`}>
                  <Popover.Title as="h3"></Popover.Title>
                  <Popover.Content>
                    <Card className="text-center">
                      <Card.Body>
                        <img
                          src={
                            ServerPath +
                            "/api/file/assets/book-photos/" +
                            params.data.ImagePath
                          }
                          style={{ height: 250, width: 250 }}
                        ></img>
                      </Card.Body>
                    </Card>
                  </Popover.Content>
                </Popover>
              }
            >
              <div>{params.data.ImagePath}</div>
            </OverlayTrigger>
          );
        },
        width: 150,
        sortable: true,
        filter: true,
        resizable: true,
        editable: true,
      },
      {
        field: "ReadCount",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "Author",
        width: 200,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "PageCount",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "Category",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "AgeGroup",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "Tags",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },

      {
        field: "StoryLine",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "DemoPageID",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "isPublished",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "Language",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "ColorPageCount",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },

      {
        field: "QRCode",
        cellRendererFramework: function (params) {
          return (
            <OverlayTrigger
              trigger={["hover", "focus"]}
              key="left"
              placement="left"
              overlay={
                <Popover id={`popover-positioned-`}>
                  <Popover.Title as="h3"></Popover.Title>
                  <Popover.Content>
                    <Card className="text-center">
                      <Card.Body>
                        <img
                          src={
                            ServerPath +
                            "/api/file/assets/book-QRcode/" +
                            params.data.QRCode
                          }
                          style={{ height: 250, width: 250 }}
                        ></img>
                      </Card.Body>
                    </Card>
                  </Popover.Content>
                </Popover>
              }
            >
              <div>{params.data.QRCode}</div>
            </OverlayTrigger>
          );
        },
        width: 200,
        sortable: true,
        filter: true,
        resizable: true,
      },
    ],
  };

  const handleUpdateOpen = (data) => {
    setSelectedBook({ data: data });
    setShowUpdate(true);
  };
  const handleDetailsOpen = (data) => {
    setDetails({ data: data });
    setShowDetails(true);
  };

  const handleCreateOpen = () => {
    setShowCreate(true);
  };

  const handleStoryLineClose = () => {
    setShowDetails(false);
  };
  const handleUpdateClose = () => setShowUpdate(false);
  const handleCreateClose = () => setShowCreate(false);

  return (
    <Container fluid>
      <Row>
        <Col md={12} lg={12} style={{ marginBottom: 20 }}>
          <Card style={{ height: "auto", width: "auto" }}>
            <div style={{ display: "flex" }}>
              <h5 className="heading">Book Details</h5>
              <>
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "3%",
                    marginTop: 10,
                  }}
                >
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={handleCreateOpen}
                  >
                    Create Book
                  </Button>
                </div>
                <Modal
                  show={showCreate}
                  onHide={handleCreateClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Create Book</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <CreateBookForm
                      sendUpdate={setIsUpdated}
                      closeModel={handleCreateClose}
                    />
                  </Modal.Body>
                </Modal>
              </>
            </div>
            <hr style={{ marginTop: 0, marginBottom: 0 }} />
            <Modal
              show={showUpdate}
              onHide={handleUpdateClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Book</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <EditBookForm
                  book={selectedBook.data}
                  sendUpdate={setIsUpdated}
                  closeModel={handleUpdateClose}
                />
              </Modal.Body>
            </Modal>
            <Modal
              show={showDetails}
              onHide={handleStoryLineClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Details {selectedBook.Name }</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <DetailsForm
                  book={selectedDetails.data}
                  sendUpdate={setIsUpdated}
                  closeModel={handleStoryLineClose}
                />
              </Modal.Body>
            </Modal>
          </Card>
          <HMRGrid rowData={rowData.data} gridOptions={gridOptions} />
        </Col>
      </Row>
    </Container>
  );
};

export default App;
