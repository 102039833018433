import React from "react";
import { Progress } from "reactstrap";
import { Card } from "react-bootstrap";

const ColorProgressBar = (props) => {
  return (
    <Card>
      <h5 class="heading" style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}>
        Progress Bar Status
      </h5>
      <hr style={{ marginTop: 0, marginBottom: 0 }} />
      <div className="color">
        <Progress value="20">Primary</Progress>
        <Progress color="success" value="40">
          Success
        </Progress>
        <Progress color="info" value="60">
          Info
        </Progress>
        <Progress color="warning" value="80">
          warning
        </Progress>
        <Progress color="danger" value="100">
          Danger
        </Progress>
      </div>
    </Card>
  );
};

export default ColorProgressBar;
