import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, Container, Row, Col, Button } from "react-bootstrap";

const ConsumerHomePage = () => {
  const history = useHistory();
  return <div></div>;
};

export default ConsumerHomePage;
