import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import config from "../../config.json";
import "./BookForm.css";

const EditPageContentForm = (props) => {
  const [Name, setName] = useState("");
  const BookPageID = props.selectedBookPage.value;
  const [ContentText, setContentText] = useState("");
  const [ContentLanguage, setContentLanguage] = useState("");
  const [ContentAudio, setContentAudio] = useState("");
  const [TestColumn, setTestColumn] = useState("");
  const token = localStorage.getItem("Token");
  const ServerPath = config.rapidServerPath;

  const createNotificationHandler = (type) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "Details changed successfully",
          "Success",
          3000
        );
        break;
      case "error":
        NotificationManager.error("Something went wrong", "error", 3000);
        break;
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        ServerPath + "/api/pagecontent/createPageContent",
        {
          Name: Name,
          BookPageID: BookPageID,
          ContentText: ContentText,
          ContentLanguage: ContentLanguage,
          ContentAudio: ContentAudio,
          TestColumn: TestColumn,
        },
        {
          headers: {
            Authorization: token,
            Apikey: "XYZ",
          },
        }
      );
      props.setBook({ label: "Select Book", value: "" });
      props.setBookPage({ label: "Select Book Page", value: "" });
      props.sendUpdate(true);
      if (response.data.success === true) {
        createNotificationHandler("success");
      } else {
        createNotificationHandler("error");
      }
      props.closeModel();
    } catch (error) {
      if (error.response) {
        NotificationManager.error(error.response.data.error, "Error", 3000);
        console.log(error);
      }
    }
  };

  return (
    <div className="book-container">
      <div className="content">
        <form onSubmit={(e) => handleCreate(e)}>
          <input
            placeholder="Name"
            value={Name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input placeholder="Book Page ID" value={BookPageID} readOnly />
          <textarea
            placeholder="Content Text"
            value={ContentText}
            onChange={(e) => setContentText(e.target.value)}
          />
          <div className="input-group">
            <input
              placeholder="Content Language"
              value={ContentLanguage}
              onChange={(e) => setContentLanguage(e.target.value)}
              required
            />
            <input
              placeholder="Test Column"
              value={TestColumn}
              onChange={(e) => setTestColumn(e.target.value)}
            />
          </div>
          <input
            placeholder="Content Audio"
            value={ContentAudio}
            onChange={(e) => setContentAudio(e.target.value)}
          />
          <button
            className="button"
            type="submit"
            style={{ marginTop: "16px !important" }}
          >
            Create
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditPageContentForm;
