import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomizedSteppers from "./CustomisedStepper";
export default function Stepper() {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <CustomizedSteppers />
        </Col>
      </Row>
    </Container>
  );
}
