export const data = [
  {
    id: 0,
    x: 3.807791190059673,
    y: 67.02721381437347,
  },
  {
    id: 1,
    x: 62.12843941277868,
    y: 55.36926654828922,
  },
  {
    id: 2,
    x: 1.9226084635607377,
    y: 3.038138830307635,
  },
  {
    id: 3,
    x: 52.83618589922672,
    y: 32.65162497181948,
  },
  {
    id: 4,
    x: 61.86592926755328,
    y: 29.426163743887113,
  },
  {
    id: 5,
    x: 66.80827728484779,
    y: 33.9752520743265,
  },
  {
    id: 6,
    x: 93.16858144010591,
    y: 8.800324005970639,
  },
  {
    id: 7,
    x: 49.942225584488,
    y: 47.157359956414346,
  },
  {
    id: 8,
    x: 64.36099575961674,
    y: 64.36286112094467,
  },
  {
    id: 9,
    x: 57.74648330370158,
    y: 79.42043525409184,
  },
  {
    id: 10,
    x: 55.12988370128517,
    y: 49.15710965409177,
  },
  {
    id: 11,
    x: 8.33624478567887,
    y: 80.0504045759728,
  },
  {
    id: 12,
    x: 40.059420430011315,
    y: 42.43955345700141,
  },
  {
    id: 13,
    x: 32.143096270955084,
    y: 78.82773834880345,
  },
  {
    id: 14,
    x: 78.92870090640545,
    y: 13.12717359273481,
  },
  {
    id: 15,
    x: 82.38665133197807,
    y: 15.638659684081823,
  },
  {
    id: 16,
    x: 95.32067845088407,
    y: 59.77754914384909,
  },
  {
    id: 17,
    x: 72.28187117873375,
    y: 87.69186439182629,
  },
  {
    id: 18,
    x: 20.00493305992739,
    y: 57.89724921272668,
  },
  {
    id: 19,
    x: 1.4153676395186832,
    y: 66.18032747712903,
  },
  {
    id: 20,
    x: 42.19946595619739,
    y: 47.119399802692016,
  },
  {
    id: 21,
    x: 42.43518672986133,
    y: 95.75896119474999,
  },
  {
    id: 22,
    x: 52.678920298991684,
    y: 41.267002856278665,
  },
  {
    id: 23,
    x: 82.4295310526209,
    y: 52.956107127959704,
  },
  {
    id: 24,
    x: 5.013446149293688,
    y: 25.788435770707686,
  },
  {
    id: 25,
    x: 41.460134319343965,
    y: 94.66217581398011,
  },
  {
    id: 26,
    x: 85.7051165340447,
    y: 53.07792047156621,
  },
  {
    id: 27,
    x: 18.59832345983772,
    y: 99.49602292770061,
  },
  {
    id: 28,
    x: 87.88404696733103,
    y: 0.42477992109006824,
  },
  {
    id: 29,
    x: 31.886061295059974,
    y: 48.869874220292274,
  },
  {
    id: 30,
    x: 23.750031761165747,
    y: 81.11170001556296,
  },
  {
    id: 31,
    x: 44.16008602806281,
    y: 3.591987099895433,
  },
  {
    id: 32,
    x: 92.51187541128967,
    y: 58.00661377613381,
  },
  {
    id: 33,
    x: 7.876484678571227,
    y: 43.18015495593086,
  },
  {
    id: 34,
    x: 38.70349050118909,
    y: 91.0829531352952,
  },
  {
    id: 35,
    x: 84.49876692211437,
    y: 90.67929803251099,
  },
  {
    id: 36,
    x: 32.67721402622201,
    y: 19.79954881449806,
  },
  {
    id: 37,
    x: 5.840795486325234,
    y: 97.72788018215721,
  },
  {
    id: 38,
    x: 98.45398705648951,
    y: 4.248369334535096,
  },
  {
    id: 39,
    x: 5.833100900830268,
    y: 21.460523595792957,
  },
  {
    id: 40,
    x: 46.511474953018016,
    y: 59.52287695297047,
  },
  {
    id: 41,
    x: 19.837671992941196,
    y: 91.42064157076916,
  },
  {
    id: 42,
    x: 42.738672609428804,
    y: 11.080999450872353,
  },
  {
    id: 43,
    x: 48.48098816210384,
    y: 43.95252544309272,
  },
  {
    id: 44,
    x: 7.802893192553251,
    y: 89.35342467222445,
  },
  {
    id: 45,
    x: 21.307799303600316,
    y: 72.34935066741832,
  },
  {
    id: 46,
    x: 83.34515518068461,
    y: 83.42145354925567,
  },
  {
    id: 47,
    x: 34.22836471834727,
    y: 52.81286263557574,
  },
  {
    id: 48,
    x: 16.959011358039167,
    y: 86.57897049506802,
  },
  {
    id: 49,
    x: 7.270430229779046,
    y: 26.882011253082982,
  },
  {
    id: 50,
    x: 93.8581424785629,
    y: 32.2777259262522,
  },
  {
    id: 51,
    x: 39.539775619005304,
    y: 40.18410822725058,
  },
  {
    id: 52,
    x: 78.3802842373087,
    y: 11.480923886886774,
  },
  {
    id: 53,
    x: 13.340341667525003,
    y: 39.246849493489975,
  },
  {
    id: 54,
    x: 74.05378798969878,
    y: 28.394658181769273,
  },
  {
    id: 55,
    x: 5.696379938681861,
    y: 38.90652832278614,
  },
  {
    id: 56,
    x: 23.642829877328708,
    y: 97.14510029561163,
  },
  {
    id: 57,
    x: 95.51937806834705,
    y: 1.1858947225171779,
  },
  {
    id: 58,
    x: 38.70861030132784,
    y: 88.0832017135888,
  },
  {
    id: 59,
    x: 4.618853782702237,
    y: 80.58614902578472,
  },
  {
    id: 60,
    x: 32.75366529248951,
    y: 97.74301534872865,
  },
  {
    id: 61,
    x: 72.00218521513978,
    y: 85.07500419535089,
  },
  {
    id: 62,
    x: 69.74037676421167,
    y: 98.67457858502333,
  },
  {
    id: 63,
    x: 68.44588374565636,
    y: 10.309926130765845,
  },
  {
    id: 64,
    x: 94.3519624340961,
    y: 36.532279402951474,
  },
  {
    id: 65,
    x: 70.34843080192684,
    y: 33.45368784903737,
  },
  {
    id: 66,
    x: 42.51781168490012,
    y: 6.6060562423505775,
  },
  {
    id: 67,
    x: 99.97878688209761,
    y: 71.23300252723391,
  },
  {
    id: 68,
    x: 25.839555801810366,
    y: 14.623782833741327,
  },
  {
    id: 69,
    x: 22.397605987439295,
    y: 14.32436968065749,
  },
  {
    id: 70,
    x: 32.74196750356326,
    y: 77.4629317785128,
  },
  {
    id: 71,
    x: 93.25907803755906,
    y: 56.29328158321884,
  },
  {
    id: 72,
    x: 16.085557246687433,
    y: 2.1574434223814665,
  },
  {
    id: 73,
    x: 52.41006285942935,
    y: 62.90370387255293,
  },
  {
    id: 74,
    x: 3.5340569069154615,
    y: 71.58731367810853,
  },
  {
    id: 75,
    x: 0.16182354396510057,
    y: 85.74677019680624,
  },
  {
    id: 76,
    x: 0.7423979259171265,
    y: 47.22086948009909,
  },
  {
    id: 77,
    x: 23.36801601748626,
    y: 23.539739821715,
  },
  {
    id: 78,
    x: 41.42445836234528,
    y: 71.76567176077018,
  },
  {
    id: 79,
    x: 94.81305103026307,
    y: 63.71519445032654,
  },
  {
    id: 80,
    x: 39.664759681607364,
    y: 64.88147231539911,
  },
  {
    id: 81,
    x: 30.808181189803683,
    y: 32.51958766608627,
  },
  {
    id: 82,
    x: 35.04137118193442,
    y: 21.853616694948318,
  },
  {
    id: 83,
    x: 56.04926204415228,
    y: 59.72806366238756,
  },
  {
    id: 84,
    x: 89.14729299368214,
    y: 30.010177940804937,
  },
  {
    id: 85,
    x: 9.035632003572825,
    y: 74.49049825096748,
  },
  {
    id: 86,
    x: 30.168138661458666,
    y: 2.439915655047442,
  },
  {
    id: 87,
    x: 23.53922684114258,
    y: 70.20804331518286,
  },
  {
    id: 88,
    x: 71.76562809640099,
    y: 84.38196014746278,
  },
  {
    id: 89,
    x: 80.54363287773852,
    y: 29.30248217712361,
  },
  {
    id: 90,
    x: 66.85308432609756,
    y: 15.82216958745255,
  },
  {
    id: 91,
    x: 44.590508263256325,
    y: 38.66244817001905,
  },
  {
    id: 92,
    x: 3.309084133010609,
    y: 55.275164870615455,
  },
  {
    id: 93,
    x: 61.2161767347779,
    y: 45.08481483740319,
  },
  {
    id: 94,
    x: 20.5797416833311,
    y: 10.694829963402785,
  },
  {
    id: 95,
    x: 66.74675369011274,
    y: 25.483178371211057,
  },
  {
    id: 96,
    x: 88.73429339348398,
    y: 83.80890435530415,
  },
  {
    id: 97,
    x: 32.4971604431346,
    y: 22.441097945360553,
  },
  {
    id: 98,
    x: 8.714550549614142,
    y: 16.099906249108685,
  },
  {
    id: 99,
    x: 17.496050321323775,
    y: 41.36653449839969,
  },
];
