import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Form } from "react-bootstrap";
// import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function DefaultInput() {
  const classes = useStyles();
  // const [dense, setDense] = React.useState(false);
  // const [secondary, setSecondary] = React.useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.demo}>
        <Card className="cards">
          <Form>
            <h6
              class="heading"
              style={{
                paddingTop: "1rem",
                marginLeft: "1.5rem",
                marginBottom: 15,
              }}
            >
              CheckBoxes & Radios
            </h6>
            <hr style={{ marginTop: 0, marginBottom: 10 }} />
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend"></FormLabel>
              <FormGroup
                className="form-text"
                style={{ marginLeft: 20, marginRight: 20 }}
              >
                <FormControlLabel
                  control={<Checkbox name="gilad" />}
                  label="CheckBox 1"
                />
                <FormControlLabel
                  control={<Checkbox name="jason" />}
                  label="CheckBox 2"
                />
              </FormGroup>
            </FormControl>

            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend"></FormLabel>
              <RadioGroup
                aria-label
                name="quiz"
                className="form-text"
                style={{ marginLeft: 20, marginRight: 20 }}
              >
                <FormControlLabel
                  value="best"
                  control={<Radio />}
                  label="Radio 1"
                />
                <FormControlLabel
                  value="worst"
                  control={<Radio />}
                  label="Radio 2"
                />
                <FormControlLabel
                  value="average"
                  control={<Radio />}
                  label="Radio 3"
                />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend"></FormLabel>
              <FormGroup style={{ marginLeft: 20, marginRight: 20 }}>
                <FormControlLabel
                  disabled
                  control={<Checkbox name="CheckBox" />}
                  label="Disabled Checkbox"
                />
                <FormControlLabel
                  value="disable"
                  disabled
                  control={<Radio />}
                  label="Disabled Radio"
                />
              </FormGroup>
            </FormControl>
          </Form>
        </Card>
      </div>
    </div>
  );
}
