import React, { Component } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

class CalendarRange extends Component {
  state = {
    date: "",
  };

  onChange = (date) => this.setState({ date });

  render() {
    const today = new Date();
    let dateOne = "";
    let dateTwo = "";
    if (this.state.date !== "") {
      const arrayDate = { ...this.state.date };
      dateOne = arrayDate[0];
      dateTwo = arrayDate[1];
      dateOne =
        dateOne.getDate() +
        "/" +
        parseInt(dateOne.getMonth() + 1) +
        "/" +
        dateOne.getFullYear();

      dateTwo =
        dateTwo.getDate() +
        "/" +
        parseInt(dateTwo.getMonth() + 1) +
        "/" +
        dateTwo.getFullYear();
    }

    let value = today;
    if (this.state.date !== "") {
      value = this.state.date;
    }

    return (
      <div>
        <strong className="list-typo">
          Selected Range: {dateOne} - {dateTwo}
        </strong>
        <Calendar
          onChange={this.onChange}
          showWeekNumbers={true}
          selectRange={true}
          // defaultValue={[
          //   new Date(year, month - 1, date),
          //   new Date(year, month - 1, date + 7),
          // ]}
          // defaultValue={[new Date(2017, 1, 1), new Date(2017, 1, 7)]}
          value={value}
          className="list"
        />
      </div>
    );
  }
}

export default CalendarRange;
