import React from "react";
import "./Faq.css";
const Faq = (props) => {
  return (
    <div className="Faq">
      <p className="Question" onClick={props.clicked}>
        {" "}
        {props.question}{" "}
      </p>
      <p className="Answer"> {props.answer} </p>
    </div>
  );
};

export default Faq;
