import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import HelpIcon from "@material-ui/icons/Help";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import ThumbDown from "@material-ui/icons/ThumbDown";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
}));

export default function ScrollableTabsButtonForce() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          className="list"
        >
          <Tab
            label="Item One"
            icon={<PhoneIcon />}
            {...a11yProps(0)}
            className="list-typo"
          />
          <Tab
            label="Item Two"
            icon={<FavoriteIcon />}
            {...a11yProps(1)}
            className="list-typo"
          />
          <Tab
            label="Item Three"
            icon={<PersonPinIcon />}
            {...a11yProps(2)}
            className="list-typo"
          />
          <Tab
            label="Item Four"
            icon={<HelpIcon />}
            {...a11yProps(3)}
            className="list-typo"
          />
          <Tab
            label="Item Five"
            icon={<ShoppingBasket />}
            {...a11yProps(4)}
            className="list-typo"
          />
          <Tab
            label="Item Six"
            icon={<ThumbDown />}
            {...a11yProps(5)}
            className="list-typo"
          />
          <Tab
            label="Item Seven"
            icon={<ThumbUp />}
            {...a11yProps(6)}
            className="list-typo"
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className="list">
        Item One
      </TabPanel>
      <TabPanel value={value} index={1} className="list">
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2} className="list">
        Item Three
      </TabPanel>
      <TabPanel value={value} index={3} className="list">
        Item Four
      </TabPanel>
      <TabPanel value={value} index={4} className="list">
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5} className="list">
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6} className="list">
        Item Seven
      </TabPanel>
    </div>
  );
}
