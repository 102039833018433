import React, { Component } from "react";
import api from "../../../services/api";
import Faq from "./Faq";

class FaqApp extends Component {
  state = {
    faqs: [],
  };

  componentDidMount = () => {
    api
      .get("http://dev.work4skills.com/exapi/api/Core/FAQ")
      .then((response) => {
        this.setState({ faqs: response.data });
      });
    this.state.faqs.map((faq) => ({ ...faq, showAnswer: "false" }));
  };
  toggleAnswerHandler = (event, id) => {
    const faqIndex = this.state.faqs.findIndex((f) => {
      return f.FAQID === id;
    });

    const faq = {
      ...this.state.faqs[faqIndex],
    };

    faq.showAnswer = !faq.showAnswer;

    const faqs = [...this.state.faqs];
    faqs[faqIndex] = faq;

    this.setState({ faqs: faqs });
  };

  render() {
    const faqs = this.state.faqs.map((faq) => {
      return (
        <Faq
          clicked={(event) => this.toggleAnswerHandler(event, faq.FAQID)}
          question={faq.Question}
          answer={faq.showAnswer ? faq.Answer : null}
        />
      );
    });

    return <div>{faqs}</div>;
  }
}

export default FaqApp;
