import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";

import axios from "axios";
// import Switch from "@material-ui/core/Switch";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
// import FormControl from "@material-ui/core/FormControl";
import LogoImg from "../../../assets/logo.png";
import { FiArrowLeft } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import config from "../../../config.json";
import Select from "react-select";
import { useParams } from "react-router-dom";
import usePasswordToggle from "../../childcomponents/hooks/usePasswordToggle";
// import { useNavigate } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import { Container } from "react-bootstrap";
export default function HMRVerification() {
  const history = useHistory();
  const ServerPath = config.rapidServerPath;
  var [mailArray, setMailArray] = useState({
    Email: "",
    PIN: "",
  });
  const createNotificationHandler = (type) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "User Created successfully. Please Login to Continue",
          "Success",
          3000
        );
        break;
      case "RegisterNotVerified":
        NotificationManager.success(
          "User Created successfully. A Four digit PIN has been sent to your email for verification",
          "Success",
          3000
        );
        break;
      case "unauthorized":
        NotificationManager.error("You are not authorized", "Sorry", 3000);
        break;
      case "error":
        NotificationManager.error("Something went wrong", "error", 3000);
        break;
      case "userExists":
        NotificationManager.error(
          "User with that Email already exists",
          "error",
          3000
        );
        break;
      case "emailError":
        NotificationManager.info(
          "Please Enter Correct EMail",
          "Wrong Email",
          3000
        );
        break;
      case "passwordError":
        NotificationManager.info(
          "Must contain one number, one uppercase letter, one lowercase letter, one special character and at least 6 characters",
          "Password do not match the requirements",
          7000
        );
        break;
      case "isActive":
        NotificationManager.info("isActive must be 0 or 1", "Info", 3000);
        break;
      case "isReader":
        NotificationManager.info("isReader must be 0 or 1", "Info", 3000);
        break;
      case "isChildren":
        NotificationManager.info("isReader must be 0 or 1", "Info", 3000);
        break;
      case "age":
        NotificationManager.info("Age must be between 1 to 100", "info", 3000);
        break;
      case "contactError":
        NotificationManager.info("Enter Correct Contact Number", "Info", 3000);
        break;
      case "mismatchPassword":
        NotificationManager.info(
          "Please confirm the password in both fields are same",
          "Info",
          3000
        );
        break;
      case "PINError":
        NotificationManager.info(
          "Please Enter 6 Digit Numeric PIN",
          "Info",
          3000
        );
        break;
      case "nameError":
        NotificationManager.info(
          "Please Enter Correct Name. You can use Text, Apostrophe and Space only",
          "warning",
          3000
        );
        break;
      case "inviteCode":
        NotificationManager.info(
          "Please Enter Correct Invite Code",
          "warning",
          3000
        );
        break;
      case "PINError":
        NotificationManager.error(
          "Please Enter 6 Digit Numeric PIN",
          "warning",
          3000
        );
        break;
    }
  };
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    console.log(mailArray);
    console.log(ServerPath + "/api/users/verifyUserStatus");
    var verified = await axios.post(
      ServerPath + "/api/users/verifyUserStatus",
      { mailArray },
      {
        headers: {
          Apikey: "XYZ",
        },
      }
    );
    if (verified.status === 200) {
      createNotificationHandler("success");
      history.push("/login");
    }
  };
  return (
    <div className="register-container">
      <div className="content">
        <section>
          <img src={LogoImg} height="200" alt="Register now" />
          <h1>Verify</h1>
          <p>Your Profile is created </p>
          <p>
            Enter the 4-digit PIN sent to your email to use Hearmeread services
          </p>
          <Link className="back-link" to="/login">
            <FiArrowLeft size={21} color="#ff001e" />
            <i style={{ color: "#0d5789" }}>Cancel</i>
          </Link>
        </section>
        <Container>
          <form onSubmit={onSubmitHandler}>
            <div className="input-group">
              <input
                value={mailArray.Email}
                onChange={(e) =>
                  setMailArray({ ...mailArray, Email: e.target.value })
                }
                id="email"
                type="text"
                placeholder="Full Name"
              />
              <label htmlFor="email">Email</label>
            </div>
            <div className="input-group">
              <input
                value={mailArray.EnteredPIN}
                onChange={(e) =>
                  setMailArray({ ...mailArray, PIN: e.target.value })
                }
                id="PIN"
                type="text"
                placeholder="Full Name"
              />
              <label htmlFor="PIN">PIN</label>
            </div>
            <button className="button" style={{ width: "97%" }} type="submit">
              Verify
            </button>
          </form>
        </Container>
      </div>
    </div>
  );
}
