import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SimpleTab from "./SimpleTab";
import CenteredTabs from "./CenteredTab";
import ScrollableTabsButtonForce from "./ScrollbarTab";
import VerticalTabs from "./VerticalTab";
import IconLabelTabs from "./IconTab";
export default function Grid() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mt-3">
          <SimpleTab />
        </Col>
        <Col md={6} className="mt-3">
          <ScrollableTabsButtonForce />
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mt-3">
          <IconLabelTabs />
        </Col>
        <Col md={6} className="mt-3">
          <CenteredTabs />
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mt-3">
          <VerticalTabs />
        </Col>
      </Row>
    </Container>
  );
}
