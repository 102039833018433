import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Container, Row, Col, Button, Modal } from "react-bootstrap";
import EditUserForm from "./EditUsersForm";
import CreateUserForm from "./CreateUserForm";
import { NotificationManager } from "react-notifications";
import UserTypeID from "./SelectUserType.js";
import Singleton from "../../services/SingletonService";
import config from "../../config.json";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import HMRGrid from "../childcomponents/Grid/HMRGrid";

const HM_Users = () => {
  const [rowData, setRowData] = useState({ data: [] });
  const [isUpdated, setIsUpdated] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ data: {} });
  const [showUpdate, setShowUpdate] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const token = localStorage.getItem("Token");
  const ServerPath = config.rapidServerPath;

  let commonData = Singleton.getInstance();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          ServerPath + "/api/core/lookup/UserType",
          {
            headers: {
              //authorization: token,
              apikey: "XYZ",
            },
          }
        );
        commonData.setUserTypes(response.data);
      } catch (error) {
        if (error.response) {
          NotificationManager.error(error.response.data.error, "Error", 3000);
          console.log(error);
        }
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(ServerPath + "/api/users/users", {
          headers: {
            authorization: token,
            Apikey: "XYZ",
          },
        });
        setRowData({ data: response.data });
      } catch (error) {
        if (error.response) {
          NotificationManager.error(error.response.data.error, "Error", 3000);
          console.log(error);
        }
      }
    }
    fetchData();
    setIsUpdated(false);
  }, [isUpdated]);

  const customComparator = (valueA, valueB) => {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };
  const gridOptions = {
    columnDefs: [
      {
        field: "Change field",
        cellRendererFramework: function (params) {
          return (
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleUpdateOpen(params.data)}
            >
              Edit
            </Button>
          );
        },
        width: 100,
        resizable: true,
      },
      {
        field: "UserID",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "Email",
        comparator: customComparator,
        width: 200,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        comparator: customComparator,
        field: "FullName",
        width: 150,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "Password",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "Mobile",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "UserAge",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "UserTypeID",
        width: 130,
        cellRendererFramework: function (params) {
          return (
            <UserTypeID
              type={params.data.UserTypeID}
              // sendUpdate={setIsUpdated}
              user={params.data}
            />
          );
        },
        resizable: true,
      },
      {
        field: "FamilyID",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "UserSubscriptionID",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "AvatarID",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "isActive",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "isChildren",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "isReader",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
    ],
  };

  const handleUpdateOpen = (data) => {
    setSelectedUser({ data: data });
    setShowUpdate(true);
  };

  const handleCreateOpen = () => {
    setShowCreate(true);
  };

  const handleUpdateClose = () => setShowUpdate(false);
  const handleCreateClose = () => setShowCreate(false);

  return (
    <Container fluid>
      <Row>
        <Col md={12} lg={12} style={{ marginBottom: 20 }}>
          <Card style={{ height: "auto", width: "auto" }}>
            <div style={{ display: "flex" }}>
              <h5 className="heading">User Details</h5>
              <>
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "3%",
                    marginTop: 10,
                  }}
                >
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={handleCreateOpen}
                  >
                    Create User
                  </Button>
                </div>
                <Modal
                  show={showCreate}
                  onHide={handleCreateClose}
                  backdrop="static"
                  keyboard={false}
                  supportedorientations={["portrait", "landscape"]}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Create User</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <CreateUserForm
                      sendUpdate={setIsUpdated}
                      closeModel={handleCreateClose}
                    />
                  </Modal.Body>
                </Modal>
              </>
            </div>
            <hr style={{ marginTop: 0, marginBottom: 0 }} />
            <Modal
              show={showUpdate}
              onHide={handleUpdateClose}
              backdrop="static"
              keyboard={false}
              supportedorientations={["portrait", "landscape"]}
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <EditUserForm
                  user={selectedUser.data}
                  sendUpdate={setIsUpdated}
                  closeModel={handleUpdateClose}
                />
              </Modal.Body>
            </Modal>
          </Card>
          <HMRGrid rowData={rowData.data} gridOptions={gridOptions} />
        </Col>
      </Row>
    </Container>
  );
};

export default HM_Users;
