import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import "./EditUserForm.css";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import Select from "react-select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
// import FormControl from "@material-ui/core/FormControl";
import config from "../../config.json";

const CreateUserForm = (props) => {
  const [Email, setEmail] = useState("");
  const [FullName, setFullName] = useState("");
  const [Password, setPassword] = useState("");

  const [FamilyID, setFamilyID] = useState("");
  const [UserSubscriptionID, setUserSubscriptionID] = useState("");
  const [AvatarID, setAvatarID] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isReader, setIsReader] = useState(false);
  const [isChildren, setIsChildren] = useState(false);
  const [Mobile, setMobile] = useState("");
  const [UserAge, setUserAge] = useState("");
  const token = localStorage.getItem("Token");
  const ServerPath = config.rapidServerPath;
  const [selectedUserType, setSelectedUserType] = useState({
    label: " --- USER TYPE --- ",
    value: "",
  });
  const [userTypeOptions, setUserTypeOptions] = useState({ options: [] });

  const validateEmail = (value) => {
    let email = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return email.test(value);
  };

  const validatePass = (value) => {
    let pass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&])[A-Za-z\d@#$!%*?&]{6,}$/;
    return pass.test(value);
  };

  const validateContact = (value) => {
    let no = /^[6-9]\d{9}$/;
    return no.test(value);
  };

  const validateAge = (age) => {
    if (age >= 1 && age <= 100) {
      return true;
    } else {
      return false;
    }
  };

  const createNotificationHandler = (type) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "User Created successfully",
          "Success",
          3000
        );
        break;
      case "unauthorized":
        NotificationManager.error("You are not authorized", "Sorry", 3000);
        break;
      case "error":
        NotificationManager.error("Something went wrong", "error", 3000);
        break;
      case "userExists":
        NotificationManager.error(
          "User with that Email already exists",
          "error",
          3000
        );
        break;
      case "emailError":
        NotificationManager.info(
          "Please Enter Correct EMail",
          "Wrong Email",
          3000
        );
        break;
      case "passwordError":
        NotificationManager.info(
          "Must contain one number, one uppercase letter, one lowercase letter, one special character and at least 6 characters",
          "Password do not match the requirements",
          7000
        );
        break;
      case "isActive":
        NotificationManager.info("isActive must be 0 or 1", "Info", 3000);
        break;
      case "isReader":
        NotificationManager.info("isReader must be 0 or 1", "Info", 3000);
        break;
      case "isChildren":
        NotificationManager.info("isReader must be 0 or 1", "Info", 3000);
        break;
      case "age":
        NotificationManager.info("Age must be between 1 to 100", "info", 3000);
        break;
      case "contactError":
        NotificationManager.info("Enter Correct Contact Number", "Info", 3000);
        break;
    }
  };

  async function handleCreate(e) {
    e.preventDefault();
    if (!validateEmail(Email)) {
      createNotificationHandler("emailError");
    } else if (!validatePass(Password)) {
      createNotificationHandler("passwordError");
    } else if (!validateContact(Mobile)) {
      createNotificationHandler("contactError");
    } else if (!validateAge(UserAge)) {
      createNotificationHandler("age");
    } else {
      try {
        const response = await axios.post(
          ServerPath + "/api/users/signup",
          {
            Email: Email,
            Password: Password,
            FullName: FullName,
            UserTypeID:
              selectedUserType.value === "" ? null : selectedUserType.value,
            FamilyID: FamilyID === "" ? null : FamilyID,
            UserSubscriptionID:
              UserSubscriptionID === "" ? null : UserSubscriptionID,
            AvatarID: AvatarID === "" ? null : AvatarID,
            isActive: Number(isActive),
            isReader: Number(isReader),
            isChildren: Number(isChildren),
            Mobile: Mobile,
            UserAge: Number(UserAge),
          },
          {
            headers: {
              authorization: token,
              Apikey: "XYZ",
            },
          }
        );
        props.sendUpdate(true);
        if (response.data.success === true) {
          createNotificationHandler("success");
        }
        props.closeModel();
      } catch (error) {
        if (error.response) {
          //NotificationManager.error(error.response.data.error, "Error", 3000);
          console.log(error);
        }
      }
    }
  }

  const handleUserTypeChange = (selected) => {
    setSelectedUserType(selected);
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          ServerPath + "/api/core/lookup/UserType",
          {
            headers: {
              apikey: "XYZ",
            },
          }
        );
        const options = [];
        response.data.map((usertype) => {
          options.push({ label: usertype.Name, value: usertype.Value });
        });
        setUserTypeOptions({ options: options });
      } catch (error) {
        if (error.response) {
          NotificationManager.error(error.response.data.error, "Error", 3000);
          console.log(error);
        }
      }
    }
    fetchData();
  }, []);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: "3.4375em",
      // minHeight: 60,
      borderRadius: 8,
      fontFamily: "inherit",
      fontSize: "18px",
      backgroundColor: "rgba(0, 0, 0, 0.02) ",
      // lineHeight: "50px",
      marginBottom: "0.7500em",
      width: "96%",
    }),
  };
  return (
    <div className="user-register-container">
      <div className="content">
        <form onSubmit={handleCreate}>
          <div className="input-group">
            <input
              id="email"
              type="email"
              placeholder="EMAIL"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="email">E-mail</label>
          </div>
          <div className="input-group">
            <input
              id="password"
              type="password"
              placeholder="PASSWORD"
              value={Password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="password">Password</label>
          </div>
          <div className="input-group">
            <input
              id="fullname"
              placeholder="FULL NAME"
              value={FullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
            <label htmlFor="fullname">Full Name</label>
          </div>

          {/* <div className="input-group">
              <input
                id="usertypeid"
                placeholder="USER TYPE ID"
                value={UserTypeID}
                onChange={(e) => setUserTypeID(e.target.value)}
              />
              <label htmlFor="usertypeid">User Type ID</label>
            </div> */}
          <Select
            isSearchable={false}
            options={userTypeOptions.options}
            value={selectedUserType}
            onChange={handleUserTypeChange}
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={customStyles}
            theme={(theme) => ({
              ...theme,
              borderRadius: ".25rem",
              colors: {
                ...theme.colors,
                primary25: "#adb5bd",
                primary: "#007bff",
              },
            })}
          />

          <div className="input-group">
            <input
              id="familyID"
              placeholder="FAMILY ID"
              value={FamilyID}
              onChange={(e) => setFamilyID(e.target.value)}
            />
            <label htmlFor="familyID">Family ID</label>
          </div>

          <div className="two-input-group">
            <div className="input-group">
              <input
                id="usersubid"
                placeholder="USER SUB ID"
                value={UserSubscriptionID}
                onChange={(e) => setUserSubscriptionID(e.target.value)}
              />
              <label htmlFor="usersubid">User SUB ID</label>
            </div>
            <div className="input-group">
              <input
                id="avatarid"
                placeholder="AVATAR ID"
                value={AvatarID}
                onChange={(e) => setAvatarID(e.target.value)}
              />
              <label htmlFor="avatarid">Avatar ID</label>
            </div>
          </div>
          <div className="input-group">
            <input
              id="mobile"
              placeholder="MOBILE"
              value={Mobile}
              onChange={(e) => setMobile(e.target.value)}
              required
            />
            <label htmlFor="mobile">Mobile</label>
          </div>
          <div className="input-group">
            <input
              id="userage"
              placeholder="USER AGE"
              value={UserAge}
              onChange={(e) => setUserAge(e.target.value)}
              required
            />
            <label htmlFor="userage">User Age</label>
          </div>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={isActive}
                  onChange={(e) => setIsActive(!Boolean(isActive))}
                />
              }
              label="isActive?"
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={isReader}
                  onChange={(e) => setIsReader(!Boolean(isReader))}
                />
              }
              label="isReader?"
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={isChildren}
                  onChange={(e) => setIsChildren(!Boolean(isChildren))}
                />
              }
              label="isChildren?"
              labelPlacement="start"
            />
          </FormGroup>
          <button className="button" type="submit">
            Create
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateUserForm;
