import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
// import echarts from "echarts";
import { Card } from "react-bootstrap";

export default class StackedAreaChart extends Component {
  state = {
    option: {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {
        data: [
          "Mail marketing",
          "Affiliate advertising",
          "Video ad",
          "Direct interview",
          "Search engine",
        ],
        textStyle: {
          color: "#8f9bb3",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ],
          axisLabel: {
            show: true,
            textStyle: {
              color: "#8f9bb3",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#8f9bb3",
            },
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            show: true,
            textStyle: {
              color: "#8f9bb3",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#8f9bb3",
            },
          },
        },
      ],
      series: [
        {
          name: "Mail marketing",
          type: "line",
          stack: "总量",
          areaStyle: {},
          data: [120, 132, 101, 134, 90, 230, 210],
          color: ["#ffc94d"],
        },
        {
          name: "Affiliate advertising",
          type: "line",
          stack: "总量",
          areaStyle: {},
          data: [220, 182, 191, 234, 290, 330, 310],
          color: ["#48bbff"],
        },
        {
          name: "Video ad",
          type: "line",
          stack: "总量",
          areaStyle: {},
          data: [150, 232, 201, 154, 190, 330, 410],
          color: ["#ff708d"],
        },
        {
          name: "Direct interview",
          type: "line",
          stack: "总量",
          areaStyle: {},
          data: [320, 332, 301, 334, 390, 330, 320],
          color: ["#2ce69b"],
        },
        {
          name: "Search engine",
          type: "line",
          stack: "总量",
          label: {
            normal: {
              show: true,
              position: "top",
            },
          },
          areaStyle: {},
          data: [820, 932, 901, 934, 1290, 1330, 1320],
          color: ["#598bff"],
        },
      ],
    },
  };
  render() {
    return (
      <Card className="echart-card">
        <h5
          class="heading"
          style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
        >
          Area Stack
        </h5>
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <ReactEcharts className="echarts" option={this.state.option} />
      </Card>
    );
  }
}
