import React, { Component } from "react";
import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";
class TimeLineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    
      series: [
        {
          data: [
            {
              x: 'Code',
              y: [
                new Date('2019-03-02').getTime(),
                new Date('2019-03-04').getTime()
              ]
            },
            {
              x: 'Test',
              y: [
                new Date('2019-03-04').getTime(),
                new Date('2019-03-08').getTime()
              ]
            },
            {
              x: 'Validation',
              y: [
                new Date('2019-03-08').getTime(),
                new Date('2019-03-12').getTime()
              ]
            },
            {
              x: 'Deployment',
              y: [
                new Date('2019-03-12').getTime(),
                new Date('2019-03-18').getTime()
              ]
            }
          ]
        }
      ],
      options: {
        chart: {
          height: 350,
          type: 'rangeBar'
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        xaxis: {
          type: 'datetime'
        }
      },
    
    
    };
  }



  render() {
    return (
      <Card>
      <h5
      class="heading"
      style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}>TimeLine Chart 
      </h5>
      <hr style={{ marginTop: 0, marginBottom: 0 }} />  

<div id="chart">
<Chart options={this.state.options}
 series={this.state.series} type="rangeBar" height={450} />
</div>
</Card>

    );
  }
}
export default TimeLineChart;