import React, { Component } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

class CalendarDate extends Component {
  state = {
    date: new Date(),
  };

  onChange = (date) => {
    this.setState({ date });
  };

  render() {
    let today = this.state.date;
    let date =
      today.getDate() +
      "/" +
      parseInt(today.getMonth() + 1) +
      "/" +
      today.getFullYear();
    return (
      <div>
        <strong className="list-typo">Selected Date: {date}</strong>
        <Calendar
          showWeekNumbers={true}
          onChange={this.onChange}
          value={this.state.date}
          className="list"
        />
      </div>
    );
  }
}

export default CalendarDate;
