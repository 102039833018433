import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ColorProgressBar from "./ColorProgressBar";
import SizeProgressBar from "./SizeProgressBar";
import ValueProgressBar from "./ValueProgressBar";
import InteractiveProgressBar from "./InteractiveProgressBar";

class ProgressBar extends Component {
  render() {
    return (
      <Container fluid>
        <Row style={{ marginBottom: 35 }}>
          <Col lg={12}>
            <ColorProgressBar />
          </Col>
        </Row>
        <Row style={{ marginBottom: 35 }}>
          <Col lg={12}>
            <SizeProgressBar />
          </Col>
        </Row>
        <Row style={{ marginBottom: 35 }}>
          <Col lg={12}>
            <InteractiveProgressBar />
          </Col>
        </Row>
        <Row style={{ marginBottom: 35 }}>
          <Col lg={12}>
            <ValueProgressBar />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ProgressBar;
