import React, { Component } from "react";
import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";
class StackedChart extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
      
        series: [{
          name: 'Marine Sprite',
          data: [44, 55, 41, 37, 22]
        }, {
          name: 'Striking Calf',
          data: [53, 32, 33, 52, 13]
        }, {
          name: 'Tank Picture',
          data: [12, 17, 11, 9, 15]
        },  {
          name: 'Reborn Kid',
          data: [25, 12, 19, 32, 25]
        }],
        options: {
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          
          xaxis: {
            categories: [2008, 2009, 2010, 2011, 2012],
            labels: {
              formatter: function (val) {
                return val + "K"
              }
            }
          },
          yaxis: {
            title: {
              text: undefined
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + "K"
              }
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          }
        },
      
      
      };
    }
    render() {
      return (
        <Card>
        <h5
        class="heading"
        style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}>Stacked Chart 
        </h5>
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <div id="chart">
        <Chart options={this.state.options}
         series={this.state.series} type="bar" height={450} />
        </div>
        </Card>  

  

      );
    }
  }
  export default StackedChart;

