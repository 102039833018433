import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// import ButtonBase from "@material-ui/core/ButtonBase";
import { Button } from "react-bootstrap";
// import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import WorkIcon from "@material-ui/icons/Work";
// import Avatar from "@material-ui/core/Avatar";
// import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Hidden from "@material-ui/core/Hidden";
import { Card } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  image: {
    marginTop: 14,
    marginLeft: 12,
    marginBottom: 10,
    width: 80,
    height: 80,
    display: "block",
    padding: theme.spacing(1),
  },
  icon: {
    height: 50,
    width: 50,
  },
  but: {
    width: 108,
    height: 40,
    marginTop: 35,
    marginRight: 5,
    marginLeft: 5,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    padding: theme.spacing(1),
  },
}));

export default function ComplexGrid2() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card style={{ height: 90 }}>
        <Grid container spacing={2}>
          <Hidden mdDown>
            <Button className={classes.image}>
              <WorkIcon className={classes.icon} />
            </Button>
          </Hidden>

          <Grid xs={7}>
            <Typography gutterBottom variant="subtitle1" className="card-text">
              <h5 style={{ marginLeft: 15, marginTop: 15 }}>
                {" "}
                Documentation & Customization Articles{" "}
              </h5>
            </Typography>
          </Grid>
          <Button
            className={classes.but}
            style={{ backgroundColor: "#BD2D56" }}
            display="flex"
            justifyContent="flex-end"
          >
            Learn More
          </Button>
        </Grid>
      </Card>
    </div>
  );
}
