import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Form, Row, Col } from "react-bootstrap";
// import Grid from "@material-ui/core/Grid";
// import { dark } from "@material-ui/core/styles/createPalette";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },

  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function DefaultInput() {
  const classes = useStyles();
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.demo}>
        <Card className="cards">
          <Form>
            <h6
              class="heading"
              style={{
                paddingTop: "1rem",
                marginLeft: "1.5rem",
                marginBottom: 15,
              }}
            >
              Default Inputs
            </h6>
            <hr style={{ marginTop: 0, marginBottom: 20 }} />
            <Form.Group
              controlId="exampleForm.ControlInput1"
              style={{ marginRight: 20, marginLeft: 20 }}
            >
              <Form.Control
                type=" text"
                placeholder="Project"
                className="form-input"
              />
            </Form.Group>

            <Row>
              <Col style={{ marginLeft: 20, width: 300 }}>
                <Form.Control placeholder="First name" className="form-input" />
              </Col>
              <Col style={{ marginRight: 20 }}>
                <Form.Control
                  type=" text"
                  placeholder="Last name"
                  className="form-input"
                />
              </Col>
            </Row>

            <Form.Group
              controlId="exampleForm.ControlInput1"
              style={{ marginRight: 20, marginLeft: 20, marginTop: 20 }}
            >
              <Form.Control
                type="password"
                placeholder="Password"
                className="form-input"
              />
            </Form.Group>
            <Form.Group
              controlId="exampleForm.ControlInput1"
              style={{ marginRight: 20, marginLeft: 20 }}
            >
              <Form.Control
                type=" text"
                placeholder="Rectangular Border"
                className="form-input"
              />
            </Form.Group>
            <Form.Group style={{ marginRight: 20, marginLeft: 20 }}>
              <Form.Control
                placeholder="Disabled input"
                disabled
                className="form-input"
              />
            </Form.Group>
            <Form.Group
              controlId="exampleForm.ControlTextarea1"
              style={{ marginRight: 20, marginLeft: 20 }}
            >
              <Form.Control
                as="textarea"
                placeholder="Text Area"
                rows="1"
                className="form-input"
              />
            </Form.Group>
            <Form.Group
              style={{ marginRight: 20, marginLeft: 20, marginTop: 20 }}
            >
              <Form.Control
                size="sm"
                type="text"
                placeholder="Small Input"
                style={{ marginBottom: 20 }}
                className="form-input"
              />
              <Form.Control
                type="text"
                placeholder="Medium Input"
                style={{ marginBottom: 20 }}
                className="form-input"
              />
              <Form.Control
                size="lg"
                type="text"
                placeholder="Large Input"
                className="form-input"
              />
            </Form.Group>
          </Form>
        </Card>
      </div>
    </div>
  );
}
