export const data = [
  {
    country: "AD",
    "hot dog": 198,
    "hot dogColor": "rgb(89 139 255)",
    burger: 87,
    burgerColor: "hsl(53, 70%, 50%)",
    sandwich: 12,
    sandwichColor: "hsl(287, 70%, 50%)",
    kebab: 98,
    kebabColor: "hsl(60, 70%, 50%)",
    fries: 0,
    friesColor: "hsl(349, 70%, 50%)",
    donut: 98,
    donutColor: "hsl(339, 70%, 50%)",
  },
  {
    country: "AE",
    "hot dog": 182,
    "hot dogColor": "hsl(328, 70%, 50%)",
    burger: 30,
    burgerColor: "hsl(329, 70%, 50%)",
    sandwich: 50,
    sandwichColor: "hsl(76, 70%, 50%)",
    kebab: 91,
    kebabColor: "hsl(143, 70%, 50%)",
    fries: 49,
    friesColor: "hsl(21, 70%, 50%)",
    donut: 185,
    donutColor: "hsl(209, 70%, 50%)",
  },
  {
    country: "AF",
    "hot dog": 35,
    "hot dogColor": "hsl(137, 70%, 50%)",
    burger: 37,
    burgerColor: "hsl(134, 70%, 50%)",
    sandwich: 5,
    sandwichColor: "hsl(189, 70%, 50%)",
    kebab: 71,
    kebabColor: "hsl(328, 70%, 50%)",
    fries: 62,
    friesColor: "hsl(356, 70%, 50%)",
    donut: 154,
    donutColor: "hsl(323, 70%, 50%)",
  },
  {
    country: "AG",
    "hot dog": 84,
    "hot dogColor": "hsl(156, 70%, 50%)",
    burger: 160,
    burgerColor: "hsl(143, 70%, 50%)",
    sandwich: 157,
    sandwichColor: "hsl(174, 70%, 50%)",
    kebab: 55,
    kebabColor: "hsl(56, 70%, 50%)",
    fries: 164,
    friesColor: "hsl(64, 70%, 50%)",
    donut: 130,
    donutColor: "hsl(37, 70%, 50%)",
  },
  {
    country: "AI",
    "hot dog": 195,
    "hot dogColor": "hsl(342, 70%, 50%)",
    burger: 111,
    burgerColor: "hsl(276, 70%, 50%)",
    sandwich: 24,
    sandwichColor: "hsl(235, 70%, 50%)",
    kebab: 8,
    kebabColor: "hsl(69, 70%, 50%)",
    fries: 80,
    friesColor: "hsl(61, 70%, 50%)",
    donut: 25,
    donutColor: "hsl(79, 70%, 50%)",
  },
  {
    country: "AL",
    "hot dog": 110,
    "hot dogColor": "hsl(32, 70%, 50%)",
    burger: 96,
    burgerColor: "hsl(216, 70%, 50%)",
    sandwich: 196,
    sandwichColor: "hsl(180, 70%, 50%)",
    kebab: 19,
    kebabColor: "hsl(339, 70%, 50%)",
    fries: 5,
    friesColor: "hsl(15, 70%, 50%)",
    donut: 69,
    donutColor: "hsl(359, 70%, 50%)",
  },
  {
    country: "AM",
    "hot dog": 129,
    "hot dogColor": "hsl(66, 70%, 50%)",
    burger: 29,
    burgerColor: "hsl(337, 70%, 50%)",
    sandwich: 9,
    sandwichColor: "hsl(89, 70%, 50%)",
    kebab: 150,
    kebabColor: "hsl(219, 70%, 50%)",
    fries: 12,
    friesColor: "hsl(332, 70%, 50%)",
    donut: 61,
    donutColor: "hsl(330, 70%, 50%)",
  },
];
