import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
// import FormControl from "@material-ui/core/FormControl";
// import { BsImage } from "react-icons/bs";
import Select from "react-select";
import config from "../../config.json";
import "./BookForm.css";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

const CreateBookPageForm = (props) => {
  console.log(props);
  //const BookID = props.selectedBook.value;
  const BookData = props.BookData;
  const BookPages = props.BookPages;
  const [isInteractive, setIsInteractive] = useState(0);
  const [fileList, setFileList] = useState({ preview: "", files: "" });
  const [resolution, setResolution] = useState({
    label: "1920X1080",
    value: "1920X1080",
  });

  // const [bookTextArr, setBookText] = useState([]);

  // const setBookText = (e) => {
  //   bookTextArr = textSummary.split('\n');
  // }

  const token = localStorage.getItem("Token");
  const ServerPath = config.rapidServerPath;
  // const resolutionOptions = [
  //   { label: "1136X640", value: "1136X640" },
  //   { label: "1024X768", value: "1024X768" },
  //   { label: "1280X800", value: "1280X800" },
  //   { label: "1334X750", value: "1334X750" },
  //   { label: "1920X768", value: "1920X768" },
  //   { label: "2436X1125", value: "2436X1125" },
  //   { label: "2436X1242", value: "2436X1242" },
  //   { label: "2560X1440", value: "2560X1440" },
  //   { label: "2560X1700", value: "2560X1700" },
  //   { label: "2732X2048", value: "2732X2048" },
  //   { label: "2960X1440", value: "2960X1440" },
  //   { label: "1792X828", value: "1792X828" },
  //   { label: "1920X1080", value: "1920X1080" },
  //   { label: "1920X1200", value: "1920X1200" },
  //   { label: "2048X1536", value: "2048X1536" },
  //   { label: "2160X1080", value: "2160X1080" },
  // ];

  const handleResolutionChange = async (resolution) => {
    setResolution(resolution);
  };

  const createNotificationHandler = (type) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "Details changed successfully",
          "Success",
          3000
        );
        break;
      case "error":
        NotificationManager.error("Something went wrong", "error", 3000);
        break;
      case "Enter Image":
        NotificationManager.info(
          "Please choose book page image(s).",
          "Info",
          3000
        );
        break;
      default:
        NotificationManager.info(type, "Info", 3000);
        break;
    }
  };
  function maxSelectFile(e) {
    let files = e.target.files; // create file object
    if (files.length > 7) {
      const msg = "Batch Upload limits to 7 files";
      e.target.value = null; // discard selected file
      console.log(msg);
      return false;
    }
    return true;
  }

  function checkMimeType(e) {
    //getting file object
    let files = e.target.files;
    //define message container
    let err = "";
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/jpg"];
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is in un-supported format\n";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      e.target.value = null; // discard selected file
      console.log(err);
      return false;
    }
    return true;
  }

  // const uploadImageList = async (e, files, allFiles) => {
  //   e.preventDefault();
  //   debugger;
  //   if (!BookData){
  //     NotificationManager.error("Book not selected", "error", 4000);
  //   }
  //   else if (!fileList.files){
  //     NotificationManager.error("Image(s) missing", "error", 4000);
  //   }else if (!resolution.value) {
  //     NotificationManager.error("Select image resolution", "error", 4000);
  //   }
  //   else if (fileList.files) {
  //       files.map(async (f) => {
  //         debugger;
  //         const form = new FormData();
  //         form.append("file", f.file);
  //         form.append("uid", BookData.ImagePath);
  //         form.append("BookName", BookData.Name);
  //         form.append("resolution", resolution.value);
  //         form.append("folderName", "book-photos");
  //         const response = await axios.post(
  //           ServerPath + "/api/file/BulkUpload",
  //           form
  //         );
  //       });

  //       allFiles.forEach(async (f) => {
  //         await createDBEntry(
  //           f.file.name.toUpperCase().replace(".JPG", "")
  //           //, bookTextArr[counter]
  //         );
  //       });

  //       allFiles.forEach((f) => {
  //         f.remove();
  //       });

  //       props.sendUpdate(true);
  //       props.closeModel();
  //     }

  // }

  const getUploadParams = () => {
    return { url: "https://httpbin.org/post" };
  };

  const handleChangeStatus = ({ meta }, status) => {
    // console.log(status, meta);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
  };
  const handleSubmit = (files, allFiles) => {
    debugger;
    //e.preventDefault();
    if (!BookData.Name) {
      NotificationManager.error("Book not selected", "error", 4000);
      return false;
    } else if (!resolution.value) {
      NotificationManager.error("Select image resolution", "error", 4000);
      return false;
    } else {
      files.map(async (f) => {
        debugger;
        const form = new FormData();
        form.append("file", f.file);
        form.append("uid", BookData.ImagePath);
        form.append("BookName", BookData.Name);
        form.append("resolution", resolution.value);
        form.append("folderName", "book-photos");
        const response = await axios.post(
          ServerPath + "/api/file/BulkUpload",
          form
        );
      });

      allFiles.forEach(async (f) => {
        await createDBEntry(
          f.file.name.toUpperCase().replace(".JPG", "")
          //, bookTextArr[counter]
        );
      });

      allFiles.forEach((f) => {
        f.remove();
      });

      props.sendUpdate(true);
      props.closeModel();
    }
  };

  let titleCount = 1;
  const getPageNum = (pgName, pgCount) => {
    debugger;
    let retVal = 0;
    if (pgName[0] == "P" && Number(pgName.replace("P", "")) > 0)
      retVal = Number(pgName.replace("P", "")) + 2 + titleCount;

    switch (pgName) {
      case "COVERFRONT":
        retVal = 1;
        break;
      case "COPYRIGHT":
        retVal = 5;
        break;
      case "BG":
        retVal = 2;
        break;
      case "TITLE":
        retVal = 3;
        break;
      case "TITLE1":
        retVal = 3;
        titleCount++;
        break;
      case "TITLE2":
        retVal = 4;
        break;
      case "TITLE3":
        titleCount++;
        retVal = 2 + titleCount;
        break;
      case "PROMO":
        retVal = pgCount - 1;
        break;
      case "COVERBACK":
        retVal = pgCount;
        break;
    }
    return retVal;
  };

  const createDBEntry = async (pgName) => {
    //debugger;
    var pgNumber = getPageNum(pgName, BookData.PageCount);
    var existingPage = BookPages.find((page) => page.PageName == pgName);
    if (pgNumber == 0) createNotificationHandler("PageName is not valid");
    else if (existingPage)
      //console.log("PageName is not valid");
      createNotificationHandler(
        "Page - " + pgName + " exists image for selected resolution"
      );
    else {
      //console.log("Page image for selected resolution");
      const response = await axios.post(
        ServerPath + "/api/bookPage/createBookPage",
        {
          BookID: BookData.BookID,
          PageImage:
            BookData.ImagePath +
            "/" +
            BookData.Name +
            "/" +
            resolution.value +
            "/" +
            pgName +
            "/JPG",
          isInteractive: Number(isInteractive),
          PageNumber: pgNumber,
          PageName: pgName,
          BookText: pgName,
        },
        {
          headers: {
            Authorization: token,
            Apikey: "XYZ",
          },
        }
      );
      //props.setBook({ label: "Select Book", value: "" });
      //props.sendUpdate(true);
      if (response.data.success === true) {
        createNotificationHandler("success");
      } else {
        createNotificationHandler("error");
      }
    }
    //props.closeModel();
  };

  const setBookImages = (e) => {
    if (e.target.files.length && maxSelectFile(e) && checkMimeType(e)) {
      setFileList({
        preview: URL.createObjectURL(e.target.files[0]),
        files: e.target.files,
      });
    }
  };

  return (
    <div className="book-container">
      <div className="content">
        <form onSubmit={(e) => handleFormSubmit(e)}>
          <div className="input-group">
            <input
              id="bookname"
              placeholder="Book Name"
              value={BookData.Name}
              readOnly
            />
            <label htmlFor="bookname">Book Name</label>
          </div>
          {/* <label htmlFor="upload-book-image">
            {fileList.preview ? (
              <img src={{uri : config.rapidServerPath + 'api/file/assets/book-photos/' + BookData.ImagePath}} className="form-image" />
            ) : (
              <BsImage size={"50%"} />
            )}
          </label> */}
          <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            onSubmit={handleSubmit}
            styles={{ dropzone: { minHeight: 200, maxHeight: 450 } }}
          />
          {/* <input
            placeholder="Book Name"
            value={BookData.Name}
            multiline
            type="textarea"
            onChange={(e) => setBookText(e.target.value)}
          /> */}
          <Select
            required
            isSearchable={false}
            // options={resolutionOptions}
            value={resolution}
            onChange={handleResolutionChange}
            components={{
              IndicatorSeparator: () => null,
            }}
            style={{
              width: "85%",
              marginRight: "5%",
              marginTop: "5%",
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: ".25rem",
              colors: {
                ...theme.colors,
                primary25: "#adb5bd",
                primary: "#007bff",
              },
            })}
            className="react-select-container"
            classNamePrefix="react-select"
          />

          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={isInteractive}
                  onChange={(e) => setIsInteractive(!Boolean(isInteractive))}
                />
              }
              label="isPublished?"
              labelPlacement="start"
            />
          </FormGroup>
          {/* <button className="button" type="submit">
            Create
          </button> */}
        </form>
      </div>
    </div>
  );
};

export default CreateBookPageForm;
