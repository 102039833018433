import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faMarker } from "@fortawesome/free-solid-svg-icons";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
// import { shadows } from "@material-ui/system";

const SideBar = ({ toggle, isOpen }) => {
  return (
    <div className={classNames("sidebar", { "is-open": isOpen })}>
      <div className="sidebar-header">
        <span color="info" onClick={toggle} style={{ color: "#fff" }}>
          &times;
        </span>
        <h3>HMR-ADMIN</h3>
      </div>
      <div className="side-menu">
        <Nav vertical className="list-unstyled">
          <NavItem>
            <NavLink tag={Link} to={"/ngx/HM_BookPageImages"}>
              <FontAwesomeIcon icon={faBook} className="mr-2" />
              Browse Books
            </NavLink>
          </NavItem>
          <hr className="divider" />
          <NavItem>
            <NavLink tag={Link} to={"/ngx/HM_ColorPages"}>
              <FontAwesomeIcon icon={faMarker} className="mr-2" />
              Coloring Pages
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    </div>
  );
};

export default SideBar;
