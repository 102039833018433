import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { Card, Row, Col, Button, Modal } from "react-bootstrap";
import config from "../../config.json";
import EditAnimationForm from "./EditAnimationForm";
import CreateAnimationForm from "./CreateAnimationForm";
import HMRGrid from "../childcomponents/Grid/HMRGrid";

const Animations = () => {
  const [rowData, setRowData] = useState({ data: [] });
  const [selectedAnimation, setSelectedAnimation] = useState({ data: [] });
  const [Books, setBooks] = useState({ options: [] });
  const [BookPage, setBookPage] = useState({ options: [] });
  const [bookPageCount, setBookPageCount] = useState({
    options: [],
  });
  const [selectedBook, setSelectedBook] = useState({
    label: "Select Book",
    value: "",
  });
  const [selectedBookPage, setSelectedBookPage] = useState({
    label: "Select Book Page",
    value: "",
  });
  const [isUpdated, setIsUpdated] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const token = localStorage.getItem("Token");
  const ServerPath = config.rapidServerPath;

  useEffect(() => {
    console.log(ServerPath);
    async function fetchData() {
      const response = await axios.get(ServerPath + "/api/books/getBooks", {
        headers: {
          Authorization: token,
          Apikey: "XYZ",
        },
      });
      let options = [];
      response.data.map((book) => {
        options.push({ label: book.Name, value: book.BookID });
      });
      setBooks({ options: options });
      options = [];
      response.data.map((book) => {
        options.push({ Name: book.Name, PageCount: book.PageCount });
      });
      setBookPageCount({ options: options });
      setIsUpdated(false);
    }
    fetchData();
  }, [isUpdated]);

  const selected = selectedBook;
  console.log(selected);
  const handleBookSelectChange = async (selected) => {
    setSelectedBook(selected);
    const response = await axios.get(
      ServerPath + "/api/bookpage/getBookPagesOnBookID/" + selected.value,
      {
        headers: {
          Authorization: token,
          Apikey: "XYZ",
        },
      }
    );
    const options = [];

    response.data.map((selectedBook) => {
      options.push({
        label: selectedBook.PageName,
        value: selectedBook.BookPageID,
      });
    });
    setBookPage({ options: options });
    console.log(options);
  };

  const selectedPage = selectedBookPage;

  const handleAnimationChange = async (selectedPage) => {
    setSelectedBookPage(selectedPage);
    loadAnimation(selectedPage.value);
  };
  // fetchData();
  // [isUpdated]);

  const loadAnimation = async (BookPageId) => {
    console.log(BookPageId);
    const response = await axios.get(
      ServerPath + "/api/pagecontent/getPageAnimations/" + BookPageId,
      {
        headers: {
          //  Authorization: token,
          Apikey: "XYZ",
        },
      }
    );
    setRowData({ data: response.data });
  };

  // useEffect(() => {
  //   async function fetchData() {
  //     const response = await axios.get(
  //       ServerPath + "/api/pagecontent/getPageAnimations/" + 1,
  //       {
  //         headers: {
  //         //  Authorization: token,
  //           Apikey: "XYZ",
  //         },
  //       }
  //     );
  //     setRowData({ data: response.data });
  //   }
  //   fetchData();
  // }, [isUpdated]);

  const gridOptions = {
    columnDefs: [
      {
        field: "Change field",
        cellRendererFramework: function (params) {
          return (
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleUpdateOpen(params.data)}
            >
              Edit
            </Button>
          );
        },
        width: 100,
        resizable: true,
      },
      {
        field: "AnimationID",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "BookPageID",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "BookID",
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "Name",
        width: 200,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "Description",
        width: 200,
        sortable: true,
        filter: true,
        resizable: true,
      },
    ],
  };

  const handleUpdateOpen = (data) => {
    console.log("selected Animation--  ", data);
    setSelectedAnimation({ data: data });
    setShowUpdate(true);
  };

  const handleUpdateClose = () => {
    setShowUpdate(false);
    loadAnimation(selectedPage.value);
  };

  const handleCreateOpen = () => setShowCreate(true);
  const handleCreateClose = () => {
    setShowCreate(false);
    loadAnimation(selectedPage.value);
  };

  return (
    <Row>
      <Col md={12} lg={12} style={{ marginBottom: 20 }}>
        <Card style={{ height: "auto", width: "auto" }}>
          <div style={{ display: "flex" }}>
            <h5 className="heading">Animation Details</h5>
            <>
              <div
                style={{
                  width: "200px",
                  marginLeft: "auto",
                  marginRight: "3%",
                  marginTop: 4,
                }}
              >
                <Select
                  styles={styles}
                  isSearchable={false}
                  options={Books.options}
                  value={selectedBook}
                  onChange={handleBookSelectChange}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: ".25rem",
                    colors: {
                      ...theme.colors,
                      primary25: "#adb5bd",
                      primary: "#007bff",
                    },
                  })}
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </div>
              <div
                style={{
                  width: "200px",
                  marginRight: "3%",
                  marginTop: 4,
                }}
              >
                <Select
                  styles={styles}
                  isSearchable={false}
                  options={BookPage.options}
                  value={selectedBookPage}
                  onChange={handleAnimationChange}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: ".25rem",
                    colors: {
                      ...theme.colors,
                      primary25: "#adb5bd",
                      primary: "#007bff",
                    },
                  })}
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </div>
              <div
                style={{
                  marginRight: "3%",
                  marginTop: 10,
                }}
              >
                {selectedBookPage.value === "" ? null : (
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={handleCreateOpen}
                  >
                    Create Page Content
                  </Button>
                )}
              </div>
              <Modal
                show={showCreate}
                onHide={handleCreateClose}
                backdrop="static"
                keyboard={false}
                supportedorientations={["portrait", "landscape"]}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Create Animation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <CreateAnimationForm
                    selectedBook={selectedBook}
                    selectedBookPage={selectedBookPage}
                    sendUpdate={setIsUpdated}
                    closeModel={handleCreateClose}
                    setBook={setSelectedBook}
                    setBookPage={setSelectedBookPage}
                  />
                </Modal.Body>
              </Modal>
            </>
          </div>
          <Modal
            show={showUpdate}
            onHide={handleUpdateClose}
            backdrop="static"
            keyboard={false}
            supportedorientations={["portrait", "landscape"]}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Animation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EditAnimationForm
                Animation={selectedAnimation.data}
                sendUpdate={setIsUpdated}
                closeModel={handleUpdateClose}
                setBook={setSelectedBook}
                setBookPage={setSelectedBookPage}
              />
            </Modal.Body>
          </Modal>
        </Card>
        <HMRGrid rowData={rowData.data} gridOptions={gridOptions} />
      </Col>
    </Row>
  );
};

export default Animations;
const styles = {
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "15px",
    },
  }),
};
