import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
// import echarts from "echarts";
import { Card } from "react-bootstrap";

export default class BarChart extends Component {
  state = {
    option: {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: {
        type: "category",
        data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        axisLabel: {
          show: true,
          textStyle: {
            color: "#8f9bb3",
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#8f9bb3",
          },
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          show: true,
          textStyle: {
            color: "#8f9bb3",
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#8f9bb3",
          },
        },
      },
      series: [
        {
          color: ["#598bff"],
          name: "Score",
          type: "bar",
          barWidth: "60%",
          data: [10, 52, 200, 334, 390, 330, 220],
        },
      ],
    },
  };
  render() {
    return (
      <Card className="echart-card">
        <h5
          class="heading"
          style={{ paddingTop: "1rem", marginLeft: "1.5rem" }}
        >
          Bar
        </h5>
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <ReactEcharts className="echarts" option={this.state.option} />
      </Card>
    );
  }
}
