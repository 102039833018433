import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import DefaultInput from "../FormInput/DefaultInput";
import ValidationState from "../FormInput/ValidationState";
import CheckBoxRadio from "../FormInput/CheckBoxRadio";
import Select from "../FormInput/Select";
export default class FormInput extends Component {
  render() {
    return (
      <Container fluid>
        <Row style={{ marginBottom: 35 }}>
          <Col style={{ marginBottom: 35 }}>
            <DefaultInput />
          </Col>
          <Col>
            <Row style={{ marginBottom: 35 }}>
              <Select />
            </Row>
            <Row style={{ marginBottom: 35 }}>
              <ValidationState />
            </Row>
            <Row style={{ marginBottom: 35 }}>
              <CheckBoxRadio />
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
